'use strict';

module.exports = {
  getCookie: function (name) {
    // eslint-disable-line
    var keyValue = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
  },
  setCookie: function (name, value, days) {
    // eslint-disable-line
    var expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = name + '=' + value + ';path=/;Secure;SameSite=None;expires=' + expires.toUTCString();
  },
  deleteCookie: function (name) {
    var keyValue = getCookie(name); // eslint-disable-line
    setCookie(name, keyValue, '-1'); // eslint-disable-line
  }
};
