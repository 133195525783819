'use strict';

/**
 * looping products to get product id's through data attribute and returns product id's string
 * @return {string} - returns product id's.
 */
function criteoData() {
    let prodListItems = '';
    $('.product-grid .tileproduct-detail').each(function (index, e) {
        prodListItems = prodListItems ? prodListItems + '|' + $(e).data('pid') : $(e).data('pid');
    });
    return prodListItems;
}

/**
 * injecting criteo banner after page load
 * @param  {string} data - banner markup
 * @return {void}
 */
function renderBannerOnPDP(data) {
    const reviews = $('.pdp-reviews')[0];
    const $data = $(data);
    const pageID = $data.find('#criteo-page-id').data('page-id') || '';
    const banner = $data.find('#criteo-' + pageID + '-Footer')[0];
    if (banner) {
        const $banner = $(banner);
        const link = $banner.find('a')[0];
        if (link && link.href) {
          const url = new URL(link.href);
          const paramVal = $(link).data('analytics');
          if (url && paramVal) {
            url.searchParams.set('retail_network', paramVal);
            link.href = url;
          }
        }
        reviews.before(banner);

        if ($banner.data('criteo-onloadbeacon')) {
            navigator.sendBeacon($banner.data('criteo-onloadbeacon'));
        }
        $banner.on('click', function(event) {
            if ($banner.data('criteo-onclickbeacon')) {
                navigator.sendBeacon($banner.data('criteo-onclickbeacon'));
            }
            //add banner analytics events
        });
    }
}

/**
 * injecting criteo banners after page load and after applying filters
 * @param  {string} data - criteo banners details
 * @param  {Object} $parentContainer - parent container
 * @return {void}
 */
function renderBannersInSearchPage (data, $parentContainer) {
    //remove any existing banners
    $('.criteo-banner').remove();
    //render banners
    const bannerPlacements = ['Banner1', 'Banner2', 'Footer'];
    const tiles = $parentContainer.children('.col-6.col-sm-4.col-xl-3, .col-12.col-sm-8.col-xl-6, .one-cross-four.col-12');
    const searchResults = $('.search-results')[0];
    const $data = $(data);
    const pageID = $data.find('#criteo-page-id').data('page-id') || '';
    let gridBannerCount = $parentContainer.children('.col-12.col-sm-8.col-xl-6').length ? $parentContainer.children('.col-12.col-sm-8.col-xl-6').length : $parentContainer.children('.one-cross-four.col-12').length ? $parentContainer.children('.one-cross-four.col-12').length * 3 : 0;
    let bannerOnePosition = 7;
    let bannerTwoPosition = 19;
    if (($("div.col-12.col-sm-8.col-xl-6").index() > 0 && ($("div.col-12.col-sm-8.col-xl-6").index() - 3) <= bannerOnePosition) || ($(".one-cross-four.col-12").index() > 0 && ($(".one-cross-four.col-12").index() - 3) <= bannerOnePosition)) {
        bannerOnePosition = bannerOnePosition - gridBannerCount;
    }
    if (($("div.col-12.col-sm-8.col-xl-6").index() > 0 && ($("div.col-12.col-sm-8.col-xl-6").index() - 3) <= bannerTwoPosition) || ($(".one-cross-four.col-12").index() > 0 && ($(".one-cross-four.col-12").index() - 3) <= bannerTwoPosition)) {
        bannerTwoPosition = bannerTwoPosition - gridBannerCount;
    }
    bannerPlacements.forEach(placement => {
        const banner = $data.find('#criteo-' + pageID + '-' + placement)[0];
        if (banner) {
            const $banner = $(banner);
            const link = $banner.find('a')[0];
            if (link && link.href) {
                const url = new URL(link.href);
                const paramVal = $(link).data('analytics');
                if (url && paramVal) {
                    url.searchParams.set('retail_network', paramVal);
                    link.href = url;
                }
            }

            if (placement === 'Banner1' && tiles && tiles.length > 11) {
                tiles[bannerOnePosition].after(banner);
            } else if (placement === 'Banner2' && tiles && tiles.length > 23) {
                tiles[bannerTwoPosition].after(banner);
            } else if(placement === 'Footer' && searchResults && $('.criteo-footer-banner').length === 0) {
                searchResults.after(banner);
                if($('.show-more.invisible button').length > 0) {
                    $('.criteo-banner.criteo-footer-banner').addClass('d-none');
                }
            }

            if ($banner.data('criteo-onloadbeacon')) {
                navigator.sendBeacon($banner.data('criteo-onloadbeacon'));
            }
            $banner.on('click', function(event) {
                if ($banner.data('criteo-onclickbeacon')) {
                    navigator.sendBeacon($banner.data('criteo-onclickbeacon'));
                }
                //add banner analytics events
            });
        }
    });
}

/**
 * injecting criteo products after page load and after applying filters
 * @param  {string} data - sponsored products details
 * @return {void}
 */
function renderProductsAndBannersInSearchPage(data, addingRemainingSponsoredProducts) {
    let $parentContainer = $('.product-grid');
    let injectProductsCount = 0;
    if (data && $parentContainer.length) { // checking criteo response having data and the page contains Product tiles
        Object.values($(data)).map((e) => { // looping criteo response data since multiple product tiles contains in the response
            if (e.classList && e.classList.contains('criteo-sponsored-product')) { // checking condition for product tiles elements
                let criteoTilePosition = e.getAttribute('data-criteo-tilepostion');
                if ((criteoTilePosition <= document.querySelectorAll('.product-grid .col-6.col-sm-4.col-xl-3, .product-grid .col-12.col-sm-8.col-xl-6, .product-grid .one-cross-four.col-12').length)) { // checking the criteo tile position is less than the product tiles count
                    let $productTileCount = $parentContainer.children('.col-6.col-sm-4.col-xl-3, .col-12.col-sm-8.col-xl-6, .one-cross-four.col-12');
                    if ($productTileCount.eq(criteoTilePosition - 1).children('.ingrid-content').length > 0) {
                        $productTileCount.eq(criteoTilePosition).before(e); // if both grid slot banner and sponsored products configured at same position, injecting the sponsored product after the grid banner
                    } else {
                        $productTileCount.eq(criteoTilePosition - 1).before(e); // injecting product to the page as per the tile position
                    }
                    // Sending SKU onload beacons
                    if ($(e).data('criteo-sku-onloadbeacon')) {
                        navigator.sendBeacon($(e).data('criteo-sku-onloadbeacon'));
                    }
                    //Filtering out the rendered sponsored products from the remaining sponsored products to be rendered
                    window.remainingSponsoredProductsToBeRendered = Object.values($(window.remainingSponsoredProductsToBeRendered)).filter((remainingSponsoredProductToBeRendered) =>
                        remainingSponsoredProductToBeRendered.classList && remainingSponsoredProductToBeRendered.classList.contains('criteo-sponsored-product') && remainingSponsoredProductToBeRendered.getAttribute('data-criteo-tilepostion') && remainingSponsoredProductToBeRendered.getAttribute('data-criteo-tilepostion') !== criteoTilePosition
                    );
                    injectProductsCount +=1;
                }
            }
        });

        //Skip this when just adding remaining sponsored products in current page state
        if(!addingRemainingSponsoredProducts) {
            renderBannersInSearchPage(data, $parentContainer);
        }

        // Sending placement onload beacons
        let $criteoProdElement = $('.criteo-sponsored-product');
        if ($criteoProdElement.length) {
            if ($criteoProdElement.data('criteo-placement-onloadbeacon')) {
                navigator.sendBeacon($criteoProdElement.data('criteo-placement-onloadbeacon'));
            }
            // Sending onClick beacons
            $criteoProdElement.on('click', function (event) {
                if ($(this).data('criteo-sku-onclickbeacon')) {
                    navigator.sendBeacon($(this).data('criteo-sku-onclickbeacon'));
                    //Track click that trigger PDP events for products on Tealium
                    if (event.target.closest('a') &&
                    (event.target.closest('a').classList.contains('thumb-link') ||
                    event.target.closest('a').classList.contains(('product-tile-url')))) {
                        $('body').trigger('adobeTagManager:productClick', $(this));
                    }
                }
            });
        }
        window.lazyload.lazyLoadImages();

        if (injectProductsCount) {
            var originalSearchCount = getOriginalSearchCount();
            //Update search count for Desktop
            addSponsoredProductsToElementCount($('.search-count'), originalSearchCount, injectProductsCount);
            $('.search-count').data('search-count', (originalSearchCount + injectProductsCount).toString());
            //Update search count for mobile/app
            addSponsoredProductsToElementCount($('.search-results-count'), originalSearchCount, injectProductsCount);
            //Update search count for applied refinments
            let refinmentsEl = $('.refinements');
            if (refinmentsEl.length) {
                let appliedRefinments = refinmentsEl.find('[aria-checked="true"]');
                if (appliedRefinments.length) {
                    let refinementsCountEl = appliedRefinments.find('.filter-hit-count');
                    addSponsoredProductsToElementCount(refinementsCountEl, originalSearchCount, injectProductsCount);
                }
            }
            let selectedFilters = $('.selected-filter');
            if (selectedFilters.length) {
                let filterHitCount = selectedFilters.find('.filter-hit-count');
                addSponsoredProductsToElementCount(filterHitCount, originalSearchCount, injectProductsCount);
            }
            //Update search count for Show Items Button inside filters for mobile
            addSponsoredProductsToElementCount($('.shop-item'), originalSearchCount, injectProductsCount);

            //Call the function recurssively to render the remaining sponsored products while the count of injectProductsCount is more than 0
            renderProductsAndBannersInSearchPage(window.remainingSponsoredProductsToBeRendered, true);
        }
    }
    if(data && $('#footercontent .criteo-banner-backup-data').length === 0 && !addingRemainingSponsoredProducts) {
        $('#footercontent').append(`<div class="d-none criteo-banner-backup-data"></div>`);
        $('#footercontent .criteo-banner-backup-data').attr('data-banner', JSON.stringify(data));
    }
}

/**
 * ajax call to get criteo sponsored products
 * @param  {Object} url - url contains parameters
 * @return {void}
 */
function sponsoredProductRequest(url) {
    var pageType = url.searchParams.get('pageType');
    $.ajax({
        url: url.toString(),
        type: 'get',
        ajax: true,
        dataType: 'html',
        success: function (data) {
            if (pageType == 'search') {
                //Store the the sponsored products data in window object
                window.remainingSponsoredProductsToBeRendered = data;
                renderProductsAndBannersInSearchPage(data);
            } else if (pageType == 'productDetail') {
                renderBannerOnPDP(data);
            }
        },
        error: function (err) {
            console.log(err);
        }
    });
}

/**
* collecting product array page details to make criteo ajax call
* @param  {string} params - AJAX call querystring
* @return {void}
*/
function getCriteoSponsoredProducts(params) {
    let url = new URL(window.Urls.getSponsoredProductsUrl);
    if (params) {
        url = new URL(url.toString().replace(url.search, ''));
        let hrefSearchParams = new URLSearchParams(params);
        hrefSearchParams.forEach(function (value, key) {
            url.searchParams.set(key, value);
        });
    }

    let productTilesCount = document.querySelectorAll('.product-grid .col-6.col-sm-4.col-xl-3').length;
    if (productTilesCount > 0) {
        let $paginationPageNum = Number($('.grid-header').data('pagination-pagenumber'));
        let $pageNumber = ($('.grid-header').length > 0 && $paginationPageNum !== 0) ? $paginationPageNum : 1;
        url.searchParams.set('item', criteoData());
        url.searchParams.set('pagenumber', $pageNumber);
        url.searchParams.set('listsize', productTilesCount);
    }
    url.searchParams.set('pageType', 'search');
    sponsoredProductRequest(url);
}

/**
 * collecting page details of product details page to make criteo ajax call
 * @return {void}
 */
function sendDataToCriteo() {
    let url = new URL(window.Urls.sendDataToCriteoUrl);
    let pId = document.querySelector('.product-detail.product-wrapper').getAttribute('data-pid');
    let price = document.querySelector('.product-detail .product-secondary-section .js-final-sale-price').getAttribute('data-unformatted-price');
    url.searchParams.set('pid', pId);
    if (document.querySelector('#instore') && document.querySelector('#instore').checked) {
        let storeId = document.querySelector('#instore').getAttribute('data-store-id');
        url.searchParams.set('storeid', storeId);
    }
    url.searchParams.set('pageType', 'productDetail');
    url.searchParams.set('price', price);
    sponsoredProductRequest(url);
}

/**
* get current product search count for mobile and desktop
* @return {Integer}
*/
function getOriginalSearchCount () {
    let searchCountEl = $('.search-count');
    let searchCountInt;
    //Check if count is in desktop element
    if (searchCountEl.length) {
        searchCountInt = parseInt(searchCountEl.data('search-count'));
        //Check if count is in mobile element
    } else {
        let searchCountAppEl = $('.search-results-count');
        if (searchCountAppEl.length) {
            let searchCountAppStr = searchCountAppEl.text().split(' ')[0];
            if (searchCountAppStr) {
                //Remove commas before doing the parse
                searchCountInt = parseInt(searchCountAppStr.replace(/,/g, ''));
            }
        }
    }
    return searchCountInt;
}

/**
* Update an element with new search count by adding the amount of inject sponsored products
* @param  {JQuery} element - JQuery element to be updated
* @param  {Integer} originalSearchCount - current search count
* @param  {Integer} injectProductsCount - amount of injected sponsored products into the page
* @return {void}
*/
function addSponsoredProductsToElementCount (element, originalSearchCount, injectProductsCount) {
    if (element && element.length && originalSearchCount && injectProductsCount) {
        let searchCountReg = new RegExp(originalSearchCount, 'g');
        let newSearchCountInt = originalSearchCount + injectProductsCount;
        let newSearchCountStr = newSearchCountInt.toString();
        // Add comma for thousands separators
        if (newSearchCountInt >= 1000) {
            newSearchCountStr = newSearchCountStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        element.each(function() {
            $(this).text($(this).text().replace(searchCountReg, newSearchCountStr))
        });
    }
}

module.exports = {
    getCriteoSponsoredProducts: getCriteoSponsoredProducts,
    sendDataToCriteo: sendDataToCriteo,
    renderBannersInSearchPage: renderBannersInSearchPage,
    renderProductsAndBannersInSearchPage: renderProductsAndBannersInSearchPage
};
