'use strict';

function fillStoreInput($storeRefinementBlock, defaultStore) {
  $storeRefinementBlock.find('#current-store-button').text(defaultStore.name.toLowerCase());

  const $storeInput = $storeRefinementBlock.find('#bopisCheck');
  const oldUrl = $storeInput.attr('data-href');

  if (!oldUrl.includes('storeid')) {
    const joint = oldUrl.includes('?') ? '&' : '?';
    const newUrl = oldUrl + joint + 'storeid=' + defaultStore.ID;
    $storeInput.attr('data-href', newUrl);
  }

  $storeInput.removeAttr('disabled');
}

module.exports = {
  storeFilterEvents: function () {
    $('.refinement-wrapper').on('click', '.refinement-get-it-fast:not(.active)', function (event) {
      let $storeRefinementBlock = $(this).find('.delivery-refinements');
      let isStoreUpdated = $storeRefinementBlock.data('isStoreUpdated');

      if (!isStoreUpdated) {
        let url = $storeRefinementBlock.data('storeUrl');

        $storeRefinementBlock.spinner().start();

        $.ajax({
          url: url,
          method: 'GET',
          success: function (data) {
            $storeRefinementBlock.spinner().stop();

            if (data?.defaultStore) {
              fillStoreInput($storeRefinementBlock, data.defaultStore);
              $storeRefinementBlock.data('isStoreUpdated', true);
            }
          },
          error: function () {
            $storeRefinementBlock.spinner().stop();
          }
        });
      }
    });
  },
  restoreDefaultStore: function($storeRefinementBlock, defaultStore) {
    if (!$storeRefinementBlock) {
      $storeRefinementBlock = $('.delivery-refinements');
    }

    fillStoreInput($storeRefinementBlock, defaultStore);
  }
};
