/**
 * @name teaserQV.js
 * @description helper script to retrieve reviews and comments and render them on a page
 * @note this script is identical to the core teasers.js script, but it is needed in this particular directory for proper webpack compilation
 */

/**
 * @function
 * @name createTeaserElement
 * @description This is a helper function used by other functions to create an element with a specific tag name and class name. Optionally pass “text” to populate the element with the provided text.
 * @param {string} tag type of DOM element
 * @param {string} className name of the element class
 * @param {string} text value to be added to the element
 * @return {Object} el DOM object
 */
function createTeaserElement(tag, className, text) {
  var el = document.createElement(tag);
  el.setAttribute('class', className);
  if (text) {
    el.innerText = text;
  }
  return el;
}

/**
 * @function
 * @name generateWriteReview
 * @description This function is called by the populateTeaser function to simply create a ‘Write a Review’ link. The click event handler for this element is added in the populateTeaser() function after the teaser has been rendered on the page.
 * @param {string} text value to be added to the element
 * @return {Object} DOM object
 */
function generateWriteReview(text) {
  var el = createTeaserElement('a', 'TTteaser__write-review', text);
  el.setAttribute('href', $('.view-full-product-info').attr('href') + '#tt-reviews-list');
  return el;
}

/**
 * @function
 * @name generateReadReviews
 * @description This function is called by the populateTeaser function to generate the link that will move to the place in the page where the reviews list is located by placing the name of the reviews list div id in the href.
 * @param {number} numReviews - number review
 * @return {Object} el DOM object
 */
function generateReadReviews(numReviews) {
  // Populate the 'Read x Reviews' text with the number of reviews and set
  var text = numReviews + ' Review' + (numReviews > 1 ? 's' : '');
  var el = createTeaserElement('a', 'TTteaser__read-reviews', text);
  el.setAttribute('href', $('.view-full-product-info').attr('href') + '#tt-reviews-list');
  return el;
}
/**
 * @function
 * @name generateTeaserStar
 * @description The generateTeaserStars function creates an SVG element that references one of three possible stars (full, half or empty). The SVGs must be defined at the top of the body of the page.
 * @param {string} starType - star type
 * @return {Object} el DOM object
 */
function generateTeaserStar(starType) {
  var svgEl = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svgEl.setAttribute('class', 'TTteaser__icon--' + starType);
  var useEl = document.createElementNS('http://www.w3.org/2000/svg', 'use');
  useEl.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#tt-teaser-star--' + starType);
  svgEl.appendChild(useEl);
  var el = createTeaserElement('span', 'TTteaser__star');
  el.appendChild(svgEl);
  return el;
}

/**
 * @function
 * @name generateTeaserStars
 * @description The generateTeaserStars function is called by the populateTeaser() function to create the review stars.
 * @param {string} rating - rating
 * @return {Object} el DOM object
 */
function generateTeaserStars(rating) {
  var el = createTeaserElement('div', 'TTteaser__rating');
  var numFullStars = Math.floor(rating);
  for (let i = 0; i < numFullStars; i++) {
    el.appendChild(generateTeaserStar('full'));
  }
  var hasHalfStar = rating - numFullStars >= 0.5;
  if (hasHalfStar) {
    el.appendChild(generateTeaserStar('half'));
  }
  var emptyStarsStartIdx = numFullStars + (hasHalfStar ? 1 : 0);
  for (let i = emptyStarsStartIdx; i < 5; i++) {
    el.appendChild(generateTeaserStar('empty'));
  }
  return el;
}

/**
 * @function
 * @name populateTeaser
 * @description This function calls other functions to build specific parts of your CGC Teaser. It should be modified to account for what content types you want to promote and/or the messaging you want based on the count returned. It ends with the writeReview() function that spawns the TurnTo review form from your CGC Teaser. Note: If the reviews list and/or comments are initially hidden under a tab, see the linking to your Content Underneath a tab section for an example of how to handle this situation.
 * @param {Object} counts response from ugc counts URL
 */
function populateTeaser(counts) {
  var fragment = document.createDocumentFragment();
  if (counts.reviews > 0) {
    // has reviews
    fragment.appendChild(generateTeaserStars(counts.avgRating));
    fragment.appendChild(generateReadReviews(counts.reviews));
  }
  var teaserElem = document.getElementById('tt-teaser');
  if (!teaserElem) {
    return;
  }
  teaserElem.appendChild(fragment);
  // add event listener to handle click to open the write a review screen
  if (document.querySelector('.TTteaser__write-review')) {
    document.querySelector('.TTteaser__write-review').addEventListener('click', function () {
      TurnToCmd('reviewsList.writeReview'); //eslint-disable-line
    });
  }
}

/**
 * @function
 * @description The loadTeaserCounts function first loads the UGC counts maintained by TurnTo and then calls the populateTeaser function.
 * @name loadTeaserCounts
 * @param {string} sku the product ID
 */
function loadTeaserCounts(sku) {
  var xhr = new XMLHttpRequest();
  var turntoUrl = $('span.turntoUrl').text();
  var siteKey = $('span.siteKey').text();

  if (turntoUrl.length === 0 || siteKey.length === 0) {
    return;
  }
  var turnToLocale = document.getElementById('turnto-locale');
  var ugcCountsUrl = 'https://cdn-ws.' + turntoUrl + '/v5/sitedata/' + siteKey + '/' + sku + '/d/ugc/counts/' + (turnToLocale ? turnToLocale.value : ''); //eslint-disable-line
  xhr.open('GET', ugcCountsUrl, true);
  xhr.addEventListener('load', function () {
    /* sample return JSON
			{
				"questions": 0,
				"directQuestions": 0,
				"answers": 0,
				"directAnswers": 0,
				"reviews": 100,
				"avgRating": 2.91,
				"relatedReviews": 0,
				"comments": 0,
				"active": true
			}
		*/
    if (xhr.responseText) {
      populateTeaser(JSON.parse(xhr.responseText));
    }
  });
  xhr.send();
}

var addReviews = function () {
  if ($('.product-detail').length && $('.product-detail').attr('data-master-pid').length) {
    loadTeaserCounts($('.product-detail').attr('data-master-pid'));
  }
};

module.exports = { addReviews: addReviews };
