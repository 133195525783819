'use strict';
var quickview = require('base/product/quickView');
var base = require('./base');
var persistentWishlist = require('./persistentWishlist');

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
  if ($('#quickViewModal').length !== 0) {
    $('#quickViewModal').remove();
  }
  if ($('#consent-tracking').length !== 0) {
    $('#consent-tracking').remove();
  }
  var htmlString =
    '<!-- Modal -->' +
    '<div class="modal fade" id="quickViewModal" role="dialog" aria-modal="true">' +
    '<span class="enter-message sr-only" ></span>' +
    '<div class="modal-dialog quick-view-dialog">' +
    '<!-- Modal content-->' +
    '<div class="modal-content">' +
    '<div class="modal-body"></div>' +
    '</div>' +
    '</div>' +
    '</div>';
  $('body').append(htmlString);
}

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
  var $html = $('<div>').append($.parseHTML(html));

  var body = $html.find('.product-quickview');
  var footer = $html.find('.modal-footer').children();

  return {
    body: body,
    footer: footer
  };
}

/**
 * QV activate primary selected image zoom
 *
 * @param {this} $this - current element
 **/
function activateQVPrimaryImageZoom($this) {
  if ($('.enableQVZoomer').length > 0) {
    var $element = $this.closest('.product-quickview');
    var serverUrl = $element.find('div.pdp-carousel').data('scene7hosturl');
    var asset;
    var image;
    var params = {};
    image = $this.attr('src').split('image/'); // eslint-disable-line
    asset = image[image.length - 1];
    params.asset = asset;
    params.serverurl = serverUrl;
    var containerId = $this.closest('.primary-image').find('.s7-viewer-qv').attr('id');
    var zoomViewer = new s7viewers.FlyoutViewer({
      // eslint-disable-line
      containerId: containerId, // eslint-disable-line
      params: params // eslint-disable-line
    }).init();
    $this.addClass('hide');
  }
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 * @param {Object} pageData - back up page data to be used for adobe tag manager
 */
function fillModalElement(selectedValueUrl, pageData) {
  $.spinner().start();
  $.ajax({
    url: selectedValueUrl,
    method: 'GET',
    dataType: 'json',
    success: async function (data) {
      if (!window.s7viewers) {
        var s7viewersLoad = require('../util/s7viewers').loadS7ViewersScripts;
        var s7APIForPDPVideoPlayer = data.s7APIForPDPVideoPlayer ? data.s7APIForPDPVideoPlayer : '';
        var s7APIForPDPZoomViewer = data.s7APIForPDPZoomViewer ? data.s7APIForPDPZoomViewer : '';
        await s7viewersLoad(s7APIForPDPVideoPlayer, s7APIForPDPZoomViewer);
      }

      var parsedHtml = parseHtml(data.renderedTemplate);

      $('#quickViewModal .modal-body').empty();
      $('#quickViewModal .modal-body').html(parsedHtml.body);
      $('#quickViewModal .modal-footer').html(parsedHtml.footer);
      $('#quickViewModal .size-chart').attr('href', data.productUrl);
      $('#quickViewModal .view-full-product-info').attr('href', data.productUrl);
      $('#quickViewModal .enter-message').text(data.enterDialogMessage);
      $('#quickViewModal').css({
        visibility: 'hidden'
      });
      $('#quickViewModal').modal('show');

      $('body').trigger('adobeTagManager:showQuickView', data.product);

      // add review on quick view
      require('./teasersQV').addReviews();

      $.ajax({
        url: data.tfcQvUrl,
        type: 'get',
        dataType: 'json',
        data: {},
        success: function (response) {
          $('.quick-view-dialog').find('.tf-qv-content').empty().html(response.renderedTemplate);
          if ($('.enableTruefit').length > 0 && $('.enableTruefit').val() === 'true') {
            tfcapi('calculate'); // eslint-disable-line
          }
        },
        error: function () {
          // do nothing
        }
      });

      $('body .quantity-val').on('input', function () {
        var element = $(this);
        var maxValue = parseInt($(this).attr('max'), 10);
        if (element.val() >= maxValue) {
          $(this).val(maxValue);
          $(this).closest('div').find('select[class*="quantity-select"]').val(maxValue).trigger('change');
          element.prev('span').find('button').removeAttr('disabled');
        }
      });
      setTimeout(function () {
        base.activatePrimarySlick();
        base.activateVideoPlayer();
        window.hbcTooltip.tooltipInit();
        window.formFields.updateSelect();
        window.formFields.quantityHoverBorder();
        // $('.TTteaser__read-reviews').attr('href', $('.view-full-product-info').attr('href'));

        $('.quickview-close, .view-full-product-info, .TTteaser__read-reviews').on('click', function (e) {
          try {
            persistentWishlist.makrSingleProductWishlisted(
              $(this).closest('.product-quickview').data('pid'),
              $('#' + $(this).closest('.product-quickview').data('pid'))
            );
            // refresh the page on wishlist upon close of QV. This is to remove the tile on Wishlist landing page
            if (e.currentTarget.className.indexOf('quickview-close') > -1 && $('.page').data('action') == 'Wishlist-Show' && $('input[name="wishlistUpdated"]').val() === 'true') {
              $('body').addClass("position-relative");
              $.spinner().start();
              window.location.reload();
            }
          } catch (ex) {
            //eslint-disable-line
            // do nothing
          }

          $('body').trigger('adobeTagManager:closeWidget', pageData);

          $('#quickViewModal').modal('hide');
        });
        $('.product-quickview').on('click', '.primary-image img', function () {
          activateQVPrimaryImageZoom($(this));
        });
        $.spinner().stop();
        $('#quickViewModal').css({
          visibility: 'visible'
        });
        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1 && navigator.userAgent.indexOf('CriOS/') == -1) {
          if(window.innerWidth >= 1024) {
            var qvImage = $(".search-page  #quickViewModal .quickview_container .primary-image img");
            var qvImageHeight = (qvImage.width() * (4/3));
            qvImage.css('height', qvImageHeight);
          }
        }

        $('#quickViewModal .quickview_container').focus();
        try {
          persistentWishlist.makrSingleProductWishlisted(data.product.id, $('.product-quickview'));
          base.checkProductAvailability(data.product.productType, data.availabilityUrl, $('.product-quickview'));
        } catch (e) {
          //eslint-disable-line
          // do nothing
        }
      }, 300);
    },
    error: function () {
      $.spinner().stop();
    }
  });
}

quickview.showQuickview = function () {
  $('body').on('click', '.quickview', function (e) {
    e.preventDefault();
    // Backup page data for reload after close quick view window
    let pd = {
      type: window.pageData.page.type,
    }
    var selectedValueUrl = $(this).closest('a.quickview').data('quickviewUrl');
    $(e.target).trigger('quickview:show');
    var pid = $(this).closest('.product').data('pid');
    var selectedColorValue = $(this).closest('.product-tile').find('.colorswatch.selected').data('attrValue');
    if (pid && selectedColorValue) {
       selectedValueUrl= selectedValueUrl +'&dwvar_' + pid + '_color=' + selectedColorValue;
    }
    var colorToSelect = $(this).closest('.product-tile').find('input[name="colorToSelect"]').val();
    if (colorToSelect) {
      selectedValueUrl = selectedValueUrl + '&colorToSelect=' + colorToSelect;
    }
    getModalHtmlElement();
    fillModalElement(selectedValueUrl, pd);
  });
};

quickview.beforeUpdateAttribute = function () {
  $('body').on('product:beforeAttributeSelect', function () {
    $('.modal.show .modal-content').spinner().start();
  });
};

quickview.updateAttribute = function () {
  $('body').on('product:afterAttributeSelect', function (e, response) {
    if ($('.modal.show .product-quickview>.bundle-items').length) {
      $('.modal.show').find(response.container).data('pid', response.data.product.id);
      $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
      $('.modal.show').find(response.container).find('.bf-product-id').text(response.data.product.id);
    } else if ($('.set-items').length) {
      response.container.find('.product-id').text(response.data.product.id);
      response.container.find('.bf-product-id').text(response.data.product.id);
    } else {
      $('.modal.show .product-quickview').data('pid', response.data.product.id);
      $('.modal.show .full-pdp-link').attr('href', response.data.product.selectedProductUrl);
    }
  });
};
quickview.updateAddToCart = function () {
  $('body').on('product:updateAddToCart', function (e, response) {
    // update local add to cart (for sets)
    var limitedVarinatQtyUpdated = response.product.limitedVarinatQtyUpdated ? response.product.limitedVarinatQtyUpdated : (!response.product.available && response.product.availability.ats > 0);
    $('button.add-to-cart', response.$productContainer).attr('disabled', !response.product.readyToOrder || !response.product.available);

    // update global add to cart (single products, bundles)
    var dialog = $(response.$productContainer).closest('.quick-view-dialog');
    $('.add-to-cart-global', dialog).data('readytoorder', response.product.readyToOrder && response.product.available);
    $('.add-to-cart-global', dialog).data('readytoordertext', response.product.readyToOrderMsg);
    if (response.product.preOrder && response.product.preOrder.applicable && response.product.preOrder.applicable === true) {
      $('button.add-to-cart-global').text(response.product.preOrder.preorderButtonName);
      if (response.product.preOrder.shipDate) {
        $('div .preorder-ship-date').text(response.product.preOrder.shipDate);
      }
    } else {
      if(!limitedVarinatQtyUpdated) {
        $('button.add-to-cart-global').text(response.product.availability.buttonName);
      }
      $('div .preorder-ship-date').empty();
    }
  });
};
quickview.updateAvailability = function () {
  $('body').on('product:updateAvailability', function (e, response) {
    // bundle individual products
    $('.product-availability', response.$productContainer)
      .data('ready-to-order', response.product.readyToOrder)
      .data('available', response.product.available)
      .find('.availability-msg')
      .empty()
      .html(response.message);

    var dialog = $(response.$productContainer).closest('.quick-view-dialog');

    if ($('.product-availability', dialog).length) {
      // bundle all products
      var allAvailable = $('.product-availability', dialog)
        .toArray()
        .every(function (item) {
          return $(item).data('available');
        });

      var allReady = $('.product-availability', dialog)
        .toArray()
        .every(function (item) {
          return $(item).data('ready-to-order');
        });

      $('.global-availability', dialog).data('ready-to-order', allReady).data('available', allAvailable);

      $('.global-availability .availability-msg', dialog)
        .empty()
        .html(allReady ? response.message : response.resources.info_selectforstock);
    } else {
      // single product
      $('.global-availability', dialog)
        .data('ready-to-order', response.product.readyToOrder)
        .data('available', response.product.available)
        .find('.availability-msg')
        .empty()
        .html(response.message);
    }
  });
};

quickview.addToCart = base.addToCart;
quickview.availability = base.availability;

module.exports = quickview;
