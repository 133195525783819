'use strict';

/**
 * Checks to see if the screen is above a certian width.
 * @return {boolean} True if large screen, otherwise false
 */
function isDesktop() {
  return window.innerWidth > 1023;
}

// TODO: this code is not used
// function initStickyRefinement() {
//   const refinementBar = document.querySelector('.refinement-bar');
//   const filterBar = document.querySelector('.product-search-results');
//   const pageHeader = document.querySelector('.page-header');
//   const headerBanner = document.querySelector('.header-banner');
//
//   if (!refinementBar || !pageHeader) {
//     return;
//   }
//
//   const setRefinementBarDimensions = offset => {
//     const topPadding = 10;
//     refinementBar.style.maxHeight = `calc(100vh - ${offset + topPadding}px)`;
//     refinementBar.style.top = `${offset + topPadding}px`;
//   };
//
//   const setFilterBarDimensions = offset => {
//     var topPadding;
//     if ($('.category-refinement-bar li').length > 0) {
//       topPadding = -120;
//       filterBar.style.top = `${offset + topPadding}px`;
//     } else {
//       topPadding = -66;
//       filterBar.style.top = `${offset + topPadding}px`;
//     }
//   };
//
//   $(document).on('page:scrollDown', () => {
//     setRefinementBarDimensions(0);
//     setFilterBarDimensions(0);
//   });
//   $(document).on('page:scrollUp', () => {
//     setRefinementBarDimensions(pageHeader.offsetHeight - (headerBanner ? headerBanner.offsetHeight : 0));
//     setFilterBarDimensions(pageHeader.offsetHeight - (headerBanner ? headerBanner.offsetHeight : 0));
//   });
// }

// Scrolls the refinement bar to the top of the open category if necessary.
function initRefinementBarScrollTopHandler() {
  const $refinementBar = $('.refinement-bar');
  if (!$refinementBar.data('scrollRefinementIntialized')) {
    $refinementBar.on('click', '.card-header button[aria-controls]', function (evt) {
      // Largescreen only.
      if (!isDesktop()) {
        return;
      }
      // Jank city here. Using a timeout to ensure the accordion functionality happens first
      window.setTimeout(function () {
        const card = evt.target.closest('.card');
        const bar = $refinementBar.get(0);

        if (bar.scrollTop > card.offsetTop) {
          bar.scrollTop = card.offsetTop;
        }
      }, 10);
    });
    $refinementBar.data('scrollRefinementIntialized', true);
  }
}

module.exports = initRefinementBarScrollTopHandler;
