'use strict';
var base = require('base/product/base');
var persistentWishlist = require('./persistentWishlist');

/**
 * to determine if the product has a size dropdown list
 */
 function isSizeDropDown($productContainer){
  var sizeList = $productContainer.find('.js-size-dropdown.show-size-dropdown');
  return sizeList.length > 0;
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
base.getPidValue = function ($el) {
  var pid;
  if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
    pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
  } else if (
    ($('.product-set-detail').length && $($el).closest('.product-detail').length && $($el).closest('.product-detail').data('pid') !== undefined) ||
    $('.product-set').length
  ) {
    pid = $($el).closest('.product-detail').data('pid');
  } else if ($('.product-set').length) {
    pid = $($el).closest('.product-detail').find('.product-id').text();
  } else if ($el && $el.closest('.product-tile').length) {
    pid = $el.closest('.product').data('pid');
  } else {
    pid = $('.product-detail:not(".bundle-item")').data('pid');
  }

  return pid;
};

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
  if (!attributes) {
    return '';
  }

  var html = '';

  attributes.forEach(function (attributeGroup) {
    if (attributeGroup.ID === 'mainAttributes') {
      attributeGroup.attributes.forEach(function (attribute) {
        html += '<div class="attribute-values">' + attribute.label + ': ' + attribute.value + '</div>';
      });
    }
  });

  return html;
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
  return $('.add-to-cart-url').val();
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
  attr.values.forEach(function (attrValue) {
    var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]');
    $attrValue.attr('value', attrValue.url);
    var $swatchButton = $attrValue.parent();

    if (attrValue.selected) {
      $attrValue.addClass('selected');
      $swatchButton.addClass('selected');
      $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
    } else {
      $attrValue.removeClass('selected');
      $swatchButton.removeClass('selected');
      $attrValue.siblings('.selected-assistive-text').empty();
    }
    if ($productContainer.find('.js-saks-newdesign').length > 0) {
      $productContainer
        .find('.attribute .' + attr.id + ' .attr-name')
        .find('.attribute-displayName')
        .html(attr.displayName + ' ');
      $productContainer
        .find('.attribute .' + attr.id + ' .attr-name')
        .find('.attribute-displayValue')
        .html(attr.displayValue);
    } else {
      $productContainer
        .find('.attribute .' + attr.id + ' .attr-name')
        .html($('.edit_quickview_container').length > 0 ? attr.attrEditDisplay : attr.attrDisplay);
    }
    if (attrValue.url) {
      $swatchButton.attr('data-url', attrValue.url);
    } else {
      $swatchButton.removeAttr('data-url');
    }
    // Disable if not selectable
    $attrValue.removeClass('selectable unselectable');

    $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
  });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
  var $attr = '[data-attr="' + attr.id + '"]';
  var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
  $defaultOption.attr('value', attr.resetUrl);

  $productContainer.find($('ul.' + attr.id + '-attribute').find('li[data-attr-value]')).removeAttr('selected');
  var productHasSizeList = isSizeDropDown($productContainer);

  attr.values.forEach(function (attrValue) {
    var $attrValue = $productContainer.find($attr + ' [data-attr-value="' + attrValue.value + '"]');
    $attrValue.find('a').attr('data-href', attrValue.url).removeAttr('disabled');
    $attrValue.find('a').attr('href', attrValue.url).removeAttr('disabled');
    $attrValue.val(attrValue.url).removeAttr('disabled');
    $attrValue.attr('disabled', false);
    if (!attrValue.selectable) {
      if (!productHasSizeList || (productHasSizeList && !attrValue.waitlist) && (productHasSizeList && attrValue.variantAvailabilityStatus !== 'NOT_AVAILABLE')) {
        $attrValue.attr('disabled', true);
      }
    }
    if (attrValue.selected) {
      $productContainer.find($attr + ' [data-attr-value="' + attrValue.value + '"]').attr('selected', 'selected');
    }

    if ($productContainer.find('.js-saks-newdesign').length > 0) {
      $productContainer
        .find('.attribute .' + attr.id + ' .attr-name')
        .find('.attribute-displayName')
        .html(attr.displayName + ' ');
      $productContainer
        .find('.attribute .' + attr.id + ' .attr-name')
        .find('.attribute-displayValue')
        .html(attr.displayValue);
    } else {
      $productContainer
        .find('.attribute .' + attr.id + ' .attr-name')
        .html($('.edit_quickview_container').length > 0 ? attr.attrEditDisplay : attr.attrDisplay);
    }
  });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
  // Currently, the only attribute type that has image swatches is Color.
  var attrsWithSwatches = ['color', 'Design'];

  attrs.forEach(function (attr) {
    if (attrsWithSwatches.indexOf(attr.id) > -1) {
      processSwatchValues(attr, $productContainer, msgs);
    } else {
      processNonSwatchValues(attr, $productContainer);
    }
  });
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
  return $el && $('.set-items').length ? $($el).closest('.product-detail').find('.quantity-select') : $('.quantity-select');
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
  var $html = $('<div>').append($.parseHTML(html));

  var body = $html.find('.choice-of-bonus-product');
  var footer = $html.find('.modal-footer').children();

  return {
    body: body,
    footer: footer
  };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
  $('.modal-body').spinner().start();

  if ($('#chooseBonusProductModal').length !== 0) {
    $('#chooseBonusProductModal').remove();
  }
  var bonusUrl;
  if (data.bonusChoiceRuleBased) {
    bonusUrl = data.showProductsUrlRuleBased;
  } else {
    bonusUrl = data.showProductsUrlListBased;
  }

  var htmlString =
    '<!-- Modal -->' +
    '<div class="modal fade bonus-pdt" id="chooseBonusProductModal" tabindex="-1" role="dialog">' +
    '<span class="enter-message sr-only" ></span>' +
    '<div class="modal-dialog choose-bonus-product-dialog quick-view-dialog" ' +
    'data-total-qty="' +
    data.maxBonusItems +
    '"' +
    'data-UUID="' +
    data.uuid +
    '"' +
    'data-pliUUID="' +
    data.pliUUID +
    '"' +
    'data-addToCartUrl="' +
    data.addToCartUrl +
    '"' +
    'data-pageStart="0"' +
    'data-pageSize="' +
    data.pageSize +
    '"' +
    'data-moreURL="' +
    data.showProductsUrlRuleBased +
    '"' +
    'data-bonusChoiceRuleBased="' +
    data.bonusChoiceRuleBased +
    '">' +
    '<!-- Modal content-->' +
    '<div class="modal-content">' +
    '<div class="modal-header">' +
    '    <span class="gwp_header"> </span>' +
    '    <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close">' +
    '        <span aria-hidden="true" class="previous-icon svg-svg-14-chevron-dims svg-svg-14-chevron"></span>' +
    '    </button>' +
    '</div>' +
    '<div class="modal-body"></div>' +
    '<div class="modal-footer"></div>' +
    '</div>' +
    '</div>' +
    '</div>';
  $('body').append(htmlString);
  $('.modal-body').spinner().start();

  $.ajax({
    url: bonusUrl,
    method: 'GET',
    dataType: 'json',
    success: function (response) {
      var parsedHtml = parseHtml(response.renderedTemplate);
      $('#chooseBonusProductModal .modal-body').empty();
      $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
      $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
      $('#chooseBonusProductModal .gwp_header').text(response.labels.selectprods);
      $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
      $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
      $('#chooseBonusProductModal').modal('show');
      $.each(data.selectedBonusProducts, function (selProdIndex, selProd) {
        $('input[name="chooseBonusproductIds"][data-pid="' + selProd.pid + '"]').prop('checked', true);
      });

      $.spinner().stop();
    },
    error: function () {
      $.spinner().stop();
    }
  });
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
  var childProducts = [];
  $('.bundle-item').each(function () {
    childProducts.push({
      pid: $(this).find('.product-id').text(),
      quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
    });
  });

  return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
  var options = $productContainer
    .find('.product-option')
    .map(function () {
      var $elOption = $(this).find('.options-select');
      var urlValue = $elOption.val();
      var selectedValueId = $elOption.find('option[value="' + urlValue + '"]').data('value-id');
      return {
        optionId: $(this).data('option-id'),
        selectedValueId: selectedValueId
      };
    })
    .toArray();

  return JSON.stringify(options);
}

/**
 * @param {Object} addToCartConfirmationModal popup after product added to cart
 */
function showAddToCartConfirmation(addToCartConfirmationModal) {
  if (!addToCartConfirmationModal) {
    return;
  }
  $('.product-added-to-cart-modal').remove();
  $('.menu-wrapper, .header').append(addToCartConfirmationModal);
  $('.product-added-to-cart-modal').show();
  setTimeout(function () {
    $('.product-added-to-cart-modal').hide();
  }, 3000);

  if ($('.minicart .popover').is(':visible')) {
    $('.product-added-to-cart-modal.modal').hide();
  }
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
  $('.minicart').trigger('count:update', response);
  var messageType = response.error ? 'alert-danger' : 'alert-success';
  if (!response.error && response.addToCartConfirmationModal) {
    if ($('.pg-name').length > 0 && $('.pg-name').val() === 'wishlist') {
      $('body').trigger('adobeTagManager:moveFavToBag', response);
    } else {
      $('body').trigger('adobeTagManager:addToBag', response);
    }
    showAddToCartConfirmation(response.addToCartConfirmationModal);
    return;
  }
  // don't show the error message in PLP (Product array)-SFDEV-7748
  if (response.sourcepage && response.sourcepage == 'pdp') {
    if ($('.add-to-cart-messages').length === 0) {
      $('.attribute.quantity').append('<div class="add-to-cart-messages"></div>');
    }
    if (response.message != '') {
      $('.add-to-cart-messages').html(
        // eslint-disable-next-line no-undef
        '<div class="error" role="alert">' + response.message + '</div>'
      );
    }

    setTimeout(function () {
      $('.add-to-cart-messages .error').remove();
    }, 3000);
  }
}

/**
 * PDP Swipe detect function.
 *
 * @param {el} el - Element
 * @param {callback} callback - Callback
 **/
function swipeDetect(el, callback) {
  var touchsurface = el;
  var swipedir;
  var startX;
  var startY;
  var distX;
  var distY;
  var threshold = 50;
  var restraint = 100;
  var allowedTime = 300;
  var elapsedTime;
  var startTime;
  var handleswipe = callback || function () {};

  /**
   * PDP Event Down
   *
   * @param {event} event - event
   **/
  function eventDown(event) {
    swipedir = 'none';
    startTime = new Date().getTime();
    event.preventDefault();
  }

  /**
   * PDP Event Up
   *
   * @param {distXLoc} distXLoc - distX
   * @param {distYLoc} distYLoc - distY
   * @param {event} event - event
   **/
  function eventUp(distXLoc, distYLoc, event) {
    elapsedTime = new Date().getTime() - startTime;
    if (elapsedTime <= allowedTime) {
      if (Math.abs(distXLoc) >= threshold && Math.abs(distYLoc) <= restraint) {
        swipedir = distXLoc < 0 ? 'left' : 'right';
      } else if (Math.abs(distYLoc) >= threshold && Math.abs(distXLoc) <= restraint) {
        swipedir = distYLoc < 0 ? 'up' : 'down';
      }
    }
    handleswipe(swipedir);
    event.preventDefault();
  }

  touchsurface.addEventListener(
    'mousedown',
    function (e) {
      startX = e.pageX;
      startY = e.pageY;
      eventDown(e);
    },
    false
  );

  touchsurface.addEventListener(
    'mousemove',
    function (e) {
      e.preventDefault();
    },
    false
  );

  touchsurface.addEventListener(
    'mouseup',
    function (e) {
      distX = e.pageX - startX;
      distY = e.pageY - startY;
      eventUp(distX, distY, e);
    },
    false
  );

  touchsurface.addEventListener(
    'touchstart',
    function (e) {
      var touchobj = e.changedTouches[0];
      startX = touchobj.pageX;
      startY = touchobj.pageY;
      eventDown(e);
    },
    false
  );

  touchsurface.addEventListener(
    'touchmove',
    function (e) {
      e.preventDefault();
    },
    false
  );

  touchsurface.addEventListener(
    'touchend',
    function (e) {
      var touchobj = e.changedTouches[0];
      distX = touchobj.pageX - startX;
      distY = touchobj.pageY - startY;
      eventUp(distX, distY, e);
    },
    false
  );
}

/**
 * PDP play select video player
 *
 * @param {pid} pid - Product id
 **/
var playVideoPlayer = function (pid) {
  $('.s7videoplayer .s7iconeffect').trigger('click');
};

base.playVideoPlayer = playVideoPlayer;


function findVideoAsset(image) {
  var asset;
  var imgAsset = image[image.length - 1];
  if (imgAsset && imgAsset.length > 0) {
    var videoPathAsset;
    var videoPath = imgAsset.split('/');
    if (videoPath[1].length > 0) {
      videoPathAsset = videoPath[1].split('?');
      if (videoPathAsset[0].length > 0) {
        asset = videoPath[0] + '/' + videoPathAsset[0];
      } else {
        asset = videoPath[0] + '/' + videoPath[1];
      }
    } else {
      asset = videoPath[0] + '/' + videoPath[1];
    }
  }

  return asset;
}

/**
 * PDP activate video player
 *
 **/
var activateVideoPlayer = function () {
  if ($('.enablePDPVideoPlayer').length > 0) {
    $('.s7-viewer-video').empty();
    var serverurl = $('.product-detail').find('div.pdp-carousel').data('scene7hosturl');
    var videoserverUrl = $('.product-detail').find('div.pdp-carousel').data('scene7videohosturl');
    var asset;
    if ($('.product-detail').find('.thumb-nail.video-player').length > 0) {
      var image = $('.product-detail').find('.thumb-nail.video-player img').attr('src').split('image/');
      asset = findVideoAsset(image);

      const params = {
        asset,
        serverurl,
        videoserverUrl
      };

      console.log('s7 viewer params from core: ', params, $(this).attr('id'));
      var isInProductSet = $('.product-detail').hasClass('set-item');

      $('.s7-viewer-video').each(function () {
        if (isInProductSet) {
          image = $(this).closest('.product-detail').find('.thumb-nail.video-player').find('img').attr('src').split('image/'); // eslint-disable-line
          asset = findVideoAsset(image);
          if (asset) {
            params.asset = asset;
          }
        }
        if ($(this).attr('id')) {
          new s7viewers.VideoViewer({
            containerId: $(this).attr('id'),
            params
          }).init();
        }
      });
    }
  }
};

base.activateVideoPlayer = activateVideoPlayer;

/**
 * PDP primary function slider functionality
 *
 * @param {jquery} slider - slider jquery element
 **/
var activatePrimarySlick = function (slider) {
  var primaryImg = '.primary-images-container .primary-images';
  if ($('.product-detail').hasClass('product-set-detail')) {
    window.hbcSlider.hbcSliderInit('primary-images-set', slider);
  } else if ($('.product-detail').hasClass('product-quickview')) {
    window.hbcSlider.hbcSliderInit('quick-view-images');
  } else {
    window.hbcSlider.hbcSliderInit('primary-images');
  }
  $(primaryImg).on('afterChange', function () {
    $(this).find('.primary-image').removeClass('active');
    $(this).find('.slick-active .primary-image').addClass('active');

    if ($(this).find('.slick-current').find('.video-place-holder').is('.video-place-holder')) {
      $(this).next('.s7-viewer-video').addClass('active');
    } else {
      $(this).next('.s7-viewer-video').removeClass('active');
    }

    if (
      $(this).parents('.primary-images-wrapper').find('.s7playpausebutton[aria-label="Pause"]').length > 0 &&
      (window.innerWidth < 1024 || $('.product-detail').hasClass('product-set-detail') || $('.product-detail').hasClass('product-quickview'))
    ) {
      $(this).parents('.primary-images-wrapper').find('.s7playpausebutton[aria-label="Pause"]').trigger('click');
    }
  });
};

base.activatePrimarySlick = activatePrimarySlick;

/**
 * PDP create carousel functionality
 *
 * @param {imgs} imgs - Object of image details
 * @param {containerDetails} $productContainer - Container Details
 **/
var createCarousel = function (imgs, $productContainer, video) {
  var $carousel = $productContainer.find('.pdp-carousel');
  var isQuickView = $productContainer.is('.product-quickview');
  var $primaryThumb = $carousel.find('.primary-thumbnails');
  var $primaryImage = $carousel.find('.primary-images');
  var $zoomWindowThumb = $productContainer.find('.zoom-thumbnails');
  var primaryThumbData = '';
  var primaryImageData = '';
  var zoomWindowThumbData = '';
  var s7ProductID = '';
  if ($('#s7Modal').length > 0) {
    s7ProductID = $('#s7Modal').data('productid');
  }

  for (var i = 0; i < imgs.length; i++) {
    primaryThumbData =
      primaryThumbData +
      '<li class="primary-thumbnail"><a href="#primary-image-' +
      i +
      '" class="thumb-nail d-lg-block"><img src="' +
      imgs[i].url +
      '" class="d-block img-fluid" alt="' +
      imgs[i].alt +
      ' image number ' +
      parseInt(imgs[i].index, 10) +
      '" title="' +
      imgs[i].title +
      '" itemprop="image" /></a></li>';
    if (isQuickView) {
      primaryImageData =
        primaryImageData +
        '<div id="primary-image-' +
        i +
        '" data-index="' +
        i +
        '" class="primary-image"><img src="' +
        imgs[i].url +
        '" class="d-block img-fluid primary-image-img" alt="' +
        imgs[i].alt +
        ' image number ' +
        parseInt(imgs[i].index, 10) +
        '" title="' +
        imgs[i].title +
        '" itemprop="image" /><div id="s7viewer-qv-' +
        i +
        '" class="s7-viewer-qv"></div></div>';
    } else {
      primaryImageData =
        primaryImageData +
        '<div id="primary-image-' +
        i +
        '" data-index="' +
        i +
        '" class="primary-image"><img src="' +
        imgs[i].url +
        '" class="d-block img-fluid primary-image-img" alt="' +
        imgs[i].alt +
        ' image number ' +
        parseInt(imgs[i].index, 10) +
        '" title="' +
        imgs[i].title +
        '" itemprop="image" /></div>';
    }
    zoomWindowThumbData =
      zoomWindowThumbData +
      '<div class="thumb-nail"><img src="' +
      imgs[i].url +
      '" class="d-block img-fluid" alt="' +
      imgs[i].alt +
      ' image number ' +
      parseInt(imgs[i].index, 10) +
      '" title="' +
      imgs[i].title +
      '" itemprop="image" /></div>';
  }
  if (video) {
    for (var i = 0; i < video.length; i++) {
      if (video[i].url.indexOf('noimage') == -1) {
        primaryThumbData =
          primaryThumbData +
          '<li class="primary-thumbnail"><a href="#s7viewer-video-' +
          s7ProductID +
          '" class="thumb-nail d-lg-block video-player"><img src="' +
          video[i].url +
          '" class="d-block img-fluid" alt="' +
          video[i].alt +
          ' image number ' +
          parseInt(video[i].index, 10) +
          '" title="' +
          video[i].title +
          '" itemprop="image" /><span class="video-player-bg"></span></a>';
        primaryImageData = primaryImageData + '<div id="s7viewer-video-' + s7ProductID + '" class="s7-viewer-video"></div>';
      }
    }
  }

  if ($primaryThumb.hasClass('slick-initialized')) {
    $primaryThumb.slick('unslick');
  }
  $primaryThumb.empty().append(primaryThumbData).find('.thumb-nail').first().addClass('active');
  if (imgs && imgs.length == 1) {
    $primaryThumb.find('.thumb-nail').first().addClass('d-none');
  }

  if ($primaryImage.hasClass('slick-initialized')) {
    $primaryImage.slick('unslick');
    $('.s7-viewer-video').empty();
  }
  $primaryImage.empty().append(primaryImageData).find('.primary-image').first().addClass('active');

  activatePrimarySlick($primaryImage);

  if ($zoomWindowThumb.hasClass('slick-initialized')) {
    $zoomWindowThumb.slick('unslick');
  }
  $zoomWindowThumb.empty().append(zoomWindowThumbData).find('.thumb-nail').first().addClass('active');
};

base.createCarousel = createCarousel;

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer, callback) {
  var availabilityValue = '';
  var availabilityMessages = response.product.availability.messages;
  var availabilityUrl = response.availabilityUrl;
  if (!response.product.readyToOrder) {
    availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    $($productContainer).trigger('product:updateAvailability', {
      product: response.product,
      $productContainer: $productContainer,
      message: availabilityValue,
      resources: response.resources
    });
    callback();
  } else {
    $.ajax({
      url: availabilityUrl,
      method: 'GET',
      success: function (data) {
        response.product.availability = data.product.availability; // eslint-disable-line
        response.product.readyToOrder = data.product.readyToOrder; // eslint-disable-line
        availabilityMessages = data.product.availability.messages;
        availabilityValue = '';
        var qtyValue = $('.quantity-val', $productContainer).data('oldValue');
        availabilityMessages.forEach(function (message) {
          if (data.product.available && data.product.availability.isAboveThresholdLevel) {
            var colorStyle = data.availability.hexColorCode;
            if (colorStyle) {
              colorStyle = 'style=color:' + colorStyle;
            }
            availabilityValue += '<li><div ' + colorStyle + '>' + message + '</div></li>';
          }
          if (!data.product.available || !data.product.availability.isAboveThresholdLevel) {
            if (message != '') {
              if ($('.add-to-cart-messages').length === 0) {
                $('.attribute.quantity').append('<div class="add-to-cart-messages"></div>');
                $('.add-to-cart-messages').html('<div class="error" role="alert">' + message + '</div>');
                setTimeout(function () {
                  $('.add-to-cart-messages .error').remove();
                }, 3000);
              } else {
                $('.add-to-cart-messages').html('<div class="error" role="alert">' + message + '</div>');
                setTimeout(function () {
                  $('.add-to-cart-messages .error').remove();
                }, 3000);
              }
            }
          }
        });
        $('button.add-to-cart, button.add-to-cart-global', $productContainer).data('producttype', data.product.productType);
        $productContainer.find('.product-availability .purchaselimit').empty();

        if (data.product.orderableNotInPurchaselimit && data.product.readyToOrder && !data.product.availability.isInPurchaselimit) {
          $productContainer.find('.product-availability .purchaselimit').html(data.product.availability.isInPurchaselimitMessage);
          $productContainer.find('.product-availability .purchaselimit').attr('aria-describedby', 'purchase-limit');
          var formData = {};
          formData.errorFields = [data.product.availability.isInPurchaselimitMessage];
          formData.formName = 'Add to Cart';
          $('body').trigger('adobeTagManager:formError', formData);
          if (data.product.purchaselimit) {
            qtyValue = data.product.purchaselimit;
          }
          setTimeout(function(){$('.quantity-val', $productContainer).val(qtyValue); }, 3000);
        } else {
          if (!data.product.available) {
            if (data.product.availability.ats > 0) {
              setTimeout(function(){
                $('.quantity-val', $productContainer).val(response.product.availability.ats);
               }, 3000);
              updateQuantities(response.product.quantities, $productContainer);
              $('.quantity-select').val(data.product.availability.ats).trigger('change');
              // eslint-disable-next-line no-param-reassign
              data.product.available = true;
              response.product.limitedVarinatQtyUpdated = true;
              $('button.add-to-cart, button.add-to-cart-global', $productContainer).text(data.resources.addtobag);
            }
            $productContainer.find('.product-availability .purchaselimit').html(data.product.availability.outofstockmessage);
          }
          $('button.add-to-cart, button.add-to-cart-global', $productContainer).attr('disabled', !data.product.available);
        }
        $($productContainer).trigger('product:updateAvailability', {
          product: data.product,
          $productContainer: $productContainer,
          message: availabilityValue,
          resources: data.resources
        });
        callback();
      },
      error: function () {
        $.spinner().stop();
        callback();
      }
    });
  }
}

/**
 * Get Error fields
 */
function getErrorFields() {
  if (!$('.adobelaunch__colorlink.selected').length && $('.adobelaunch__colorlink').length != 1) {
    // return $('.adobelaunch__colorlink').closest('div[data-attr]').data('attr');
    return $('.adobelaunch__colorlink').closest('div[data-attr]').data('attr');
  }
  if (!$('.adobelaunch__sizelink.selected').length && $('.adobelaunch__sizelink').length != 1) {
    // return $('.adobelaunch__sizelink').closest('div[data-attr]').data('attr');
    return $('.adobelaunch__sizelink').closest('div[data-attr]').data('attr');
  }
  return '';
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer, callback) {
  var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
  var isVaraint;
  if ($productContainer.length && response.product.variationAttributes) {
    updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
    isVaraint = response.product.productType === 'variant';
    if (isChoiceOfBonusProducts && isVaraint) {
      $productContainer.parent('.bonus-product-item').data('pid', response.product.id);

      $productContainer.parent('.bonus-product-item').data('ready-to-order', response.product.readyToOrder);
    }
  }

  var dialog = $($productContainer).closest('.quick-view-dialog');
  // update to add to cart buttons
  $('button.add-to-cart').data('readytoorder', response.product.readyToOrder && response.product.available);
  $('button.add-to-cart').data('readytoordertext', response.product.readyToOrderMsg);
  var sDiv = $('<div class="col-12 select-size-div">');
  sDiv.attr('aria-describedby', getErrorFields());
  sDiv.html(response.product.readyToOrderMsg);
  $('.select-size-color').html(sDiv);

  $('button.add-to-cart-global', dialog).attr('data-readytoorder', response.product.readyToOrder && response.product.available);
  $('button.add-to-cart-global', dialog).attr('data-readytoordertext', response.product.readyToOrderMsg);
  $('.select-size-color', dialog).html(sDiv);

  $('button.update-cart-product-global', dialog).data('readytoorder', response.product.readyToOrder && response.product.available);
  $('button.update-cart-product-global', dialog).data('readytoordertext', response.product.readyToOrderMsg);
  $('.select-size-color', dialog).html(sDiv);

  // Update primary images
  var primaryImageUrls = response.product.images.large;
  var videoUrls = response.product.images.video;
  if (videoUrls) {
    createCarousel(primaryImageUrls, $productContainer, videoUrls);
    activateVideoPlayer();
  } else {
    createCarousel(primaryImageUrls, $productContainer);
  }

  // Update pricing
  if (!isChoiceOfBonusProducts) {
    var $priceSelector = $('.prices .price', $productContainer).length ? $('.prices .price', $productContainer) : $('.prices .price');
    $priceSelector.html(response.product.price.html);
  }

  // Update promotions
  if (
    response.product.promotionalPricing &&
    response.product.promotionalPricing.isPromotionalPrice &&
    response.product.promotionalPricing.promoMessage !== ''
  ) {
    $('.promotion-pricing', $productContainer).empty().html(response.product.promotionalPricing.priceHtml);
    $('.promotion-pricing', $productContainer).removeClass('d-none');
    $('.promotions', $productContainer).addClass('d-none');
  } else {
    $('.promotion-pricing', $productContainer).addClass('d-none');
    $('.promotions', $productContainer).removeClass('d-none');
    $('.promotions', $productContainer).empty().html(response.product.promotionsHtml);
  }

  updateAvailability(response, $productContainer, callback);
  persistentWishlist.makrSingleProductWishlisted(response.product.id, $productContainer);

  if (isChoiceOfBonusProducts) {
    var $selectButton = $productContainer.find('.select-bonus-product');
    $selectButton.trigger('bonusproduct:updateSelectButton', {
      product: response.product,
      $productContainer: $productContainer
    });
  } else {
    // Enable "Add to Cart" button if all required attributes have been selected
    // eslint-disable-next-line no-lonely-if
    if ($('.page').data('producttype') === 'set') {
      $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global', $productContainer)
        .trigger('product:updateAddToCart', {
          product: response.product,
          $productContainer: $productContainer
        })
        .trigger('product:statusUpdate', response.product);
    } else {
      $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global')
        .trigger('product:updateAddToCart', {
          product: response.product,
          $productContainer: $productContainer
        })
        .trigger('product:statusUpdate', response.product);
    }
  }

  if (response.product.readyToOrderMsg === '') {
    $('.select-size-color').empty();
  }

  // Update attributes
  $productContainer.find('.main-attributes').empty().html(getAttributesHtml(response.product.attributes));
  if(window.isSoldoutSelected && isSizeDropDown($productContainer)) {
    var isEditModal = $productContainer.closest('#editProductModal');
    var addToBagButton;
    if(dialog && dialog.length > 0 && isEditModal.length < 1) {
      addToBagButton = $productContainer.find('button.add-to-cart-global');
    }else if(isEditModal && isEditModal.length > 0) {
      addToBagButton = $productContainer.find('.update-cart-product-global');
      if(addToBagButton.length < 1) {
        addToBagButton = $productContainer.find('button.add-to-cart-global');
      }
    } else {
      addToBagButton = $productContainer.find('button.add-to-cart');
    }
    addToBagButton.addClass('soldout').addClass('disabled');
    addToBagButton.text(response.resources.soldout);
    addToBagButton.attr('disabled', 'disabled');
  }
}

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} options - Ajax response options from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(options, $productContainer) {
  options.forEach(function (option) {
    var $optionEl = $productContainer.find('.product-option[data-option-id*="' + option.id + '"]');
    option.values.forEach(function (value) {
      var valueEl = $optionEl.find('option[data-value-id*="' + value.id + '"]');
      valueEl.val(value.url);
    });
  });
}

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
  if (!($productContainer.parent('.bonus-product-item').length > 0)) {
    var optionsHtml = quantities
      .map(function (quantity) {
        var selected = quantity.selected ? ' selected ' : '';
        return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' + selected + '>' + quantity.value + '</option>';
      })
      .join('');
    getQuantitySelector($productContainer).empty().html(optionsHtml);
  }
}

/**
 * Show basket limit messaging mmodal
 */
$('body').on('triggerBasketLimitMsgModal', function () {
  if ($('#pliLimitMessagingModal').length > 0) {
    $('#pliLimitMessagingModal').modal('show');
  }
});

/**
 * Updates the product shipping option DOM elements post Ajax call
 * @param {UpdatedQuantity[]} data - response of an Ajax
 * @param {jQuery} $productContainer - DOM container for a given product
 */
base.updateShippingOptions = function (data, $productContainer) {
  var $storeDataElement = $productContainer.find('.shipping-option');
  var product = data.product;
  if (data && data.isBopisEnabled && $storeDataElement.length > 0) {
    // show elements if bopis is enabled
    $storeDataElement.find('.product-pis').removeClass('change-store');
    $storeDataElement.show();
    // update store id in DOM
    if (data.storeInfo) {
      $storeDataElement.find('input.product-pis').attr('data-store-id', data.storeInfo.ID);
    }
    // update store change link
    if ($('a.change-store').length > 0) {
      if (data.storeInfo && $storeDataElement.attr('data-changestore-text')) {
        $('a.change-store').text($storeDataElement.attr('data-changestore-text'));
      } else if ($storeDataElement.attr('data-choosestore-text')) {
        $('a.change-store').text($storeDataElement.attr('data-choosestore-text'));
      }
    }
    // set add to bag button text
    var selectedValue = $storeDataElement.find('input[name=shipto]:checked').val();
    var addToStoreText = $storeDataElement.data('addtostore-text');
    var addToCartButton = $storeDataElement.closest('.product-detail').find('.add-to-cart');
    if (addToStoreText !== undefined && addToStoreText.length && addToStoreText !== '') {
      if (selectedValue === 'instore') {
        addToCartButton.html(addToStoreText);
      }
    }
    if (product.productType !== 'variant') {
      // product is a master
      $storeDataElement.find('input[value=instore]').attr('disabled', true);
      $storeDataElement.find('.store-change-link').hide();
    } else if (data.storeInfo && (data.storeInfo.unitsAtStores === 0 || data.storeInfo.unitsAtStores < product.selectedQuantity)) {
      // product is variant and units are unavailable
      if (data.availabilityMessage) {
        $storeDataElement.find('.store-info').text(data.availabilityMessage);
      }
      if (data.storeInfo.distanceInUnits && data.storeInfo.distanceInUnits !== '0') {
        $storeDataElement.find('.distance-info').text(data.storeInfo.distanceInUnits);
      }
      $storeDataElement.find('input[value=instore]').attr('disabled', true);
      $storeDataElement.find('.store-change-link').show();
      if ($storeDataElement.find('input[name=shipto]:checked').val() === 'instore') {
        $storeDataElement.find('input[value=shipto]').trigger('click');
      }
    } else {
      // product is variant and units are available
      $storeDataElement.find('.product-pis').removeClass('change-store');
      if (data.storeInfo && data.storeInfo.distanceInUnits && data.storeInfo.distanceInUnits !== '0') {
        $storeDataElement.find('.distance-info').text(data.storeInfo.distanceInUnits);
      }

      if (data.storeInfo && data.availabilityMessage) {
        $storeDataElement.find('.store-info').text(data.availabilityMessage);
      } else {
        $storeDataElement.find('.store-info').text(data.availabilityPromptText);
        $storeDataElement.find('.product-pis').addClass('change-store');
      }
      $storeDataElement.find('input[value=instore]').removeAttr('disabled');
      $storeDataElement.find('.store-change-link').show();
    }
  } else {
    // hide elements if bopis is disabled
    $storeDataElement.find('.product-pis').removeClass('change-store');
    $storeDataElement.hide();
  }
};

/**
 *
 * @param {string} selectedValueUrl attribute URL
 * @param {Object} $productContainer product Detail container
 */
function attributeSelect(selectedValueUrl, $productContainer) {
  if ($productContainer.length && selectedValueUrl) {
    $('body').trigger('product:beforeAttributeSelect', {
      url: selectedValueUrl,
      container: $productContainer
    });

    $.ajax({
      url: selectedValueUrl,
      method: 'GET',
      success: function (data) {
        handleVariantResponse(data, $productContainer, function () {
          updateOptions(data.product.options, $productContainer);
          updateQuantities(data.product.quantities, $productContainer);
          // update shipping option elements
          base.updateShippingOptions(data, $productContainer, true);

          if (data.product && data.product.badge && data.product.badge.isFinalSale) {
            $('div.final-sale-message', $productContainer).removeClass('d-none');
          } else {
            $('div.final-sale-message', $productContainer).addClass('d-none');
          }

          // update hudson reward point only for bay site
          if (data.product && data.product.hudsonPoint) {
            $('.product-detail').find('.reward-est-point').html(data.product.hudsonPoint);
          }
          $('body').trigger('product:afterAttributeSelect', {
            data: data,
            container: $productContainer
          });
          $.spinner().stop();
        });
      },
      error: function () {
        $.spinner().stop();
      }
    });
  }
}

base.colorAttribute = function () {
  $(document).on('click', '[data-attr="color"] button , [data-attr="Design"] button', function (e) {
    e.preventDefault();

    if ($(this).attr('disabled')) {
      return;
    }
    var $productContainer = $(this).closest('.set-item');
    if (!$productContainer.length) {
      $productContainer = $(this).closest('.product-detail');
    }

    attributeSelect($(this).attr('data-url'), $productContainer);
  });

  $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
    e.preventDefault();

    var $productContainer = $(this).closest('.set-item');
    if (!$productContainer.length) {
      $productContainer = $(this).closest('.product-detail');
    }
    attributeSelect(e.currentTarget.value, $productContainer);
  });
};

base.selectAttribute = function () {
  $(document).on('click', 'li[data-attr-value] a', function (e) {
    e.preventDefault();
    var $productContainer = $(this).closest('.set-item');
    if (!$productContainer.length) {
      $productContainer = $(this).closest('.product-detail');
    }
    if ($(this).parent('li').length > 0 && $(this).parent('li').attr('selected') === 'selected') {
      return false;
    }
    attributeSelect($(this).attr('href'), $productContainer);
  });
};

base.availability = function () {
  $(document).off('change', '.quantity-select');
  $(document).on('change', '.quantity-select', function (e) {
    e.preventDefault();
    // clear error message
    $('.cart-error').html('');

    var $productContainer = $(this).closest('.product-detail');
    if (!$productContainer.length) {
      $productContainer = $(this).closest('.modal-content').find('.product-quickview');
    }
    if ($('.bundle-items', $productContainer).length === 0) {
      attributeSelect($(e.currentTarget).find('option:selected').data('url'), $productContainer);
    }
  });
};

base.initQuantity = function () {
  $('body').on('click', '.btn-number', function (e) {
    e.preventDefault();
    $('.cart-error').html('');
    var fieldName = $(this).attr('data-field');
    var type = $(this).attr('data-type');
    var input = $(this)
      .closest('div')
      .find('input[name="' + fieldName + '"]');
    var currentVal = parseInt(input.val(), 10);
    if (!isNaN(currentVal)) {
      if (type === 'minus') {
        if (currentVal > input.attr('min')) {
          input.val(currentVal - 1).change();
        }
        if (parseInt(input.val(), 10) === input.attr('min')) {
          $(this).attr('disabled', true);
        }
        if ($(this).attr('disabled') !== 'disabled') {
          $(this).closest('.quantity-field').find('.input-number').addClass('active-border');
        }
      } else if (type === 'plus') {
        input.val(currentVal + 1).change();
        if (parseInt(input.val(), 10) === parseInt(input.attr('max'), 10)) {
          $(this).attr('disabled', true);
        }
        if ($(this).attr('disabled') !== 'disabled') {
          $(this).closest('.quantity-field').find('.input-number').addClass('active-border');
        }
      }
    } else {
      input.val(1);
    }
  });

  $('body').on('blur', '.btn-number', function (e) {
    if ($('.input-number').hasClass('active-border')) {
      $('.input-number').removeClass('active-border');
    }
  });

  $('body').on('mouseenter focusin', '.input-number', function () {
    $(this).data('oldValue', $(this).val());
  });
  $('body').on('change', '.input-number', function () {
    // clear error message
    $('.cart-error').html('');
    var minValue = parseInt($(this).attr('min'), 10);
    var maxValue = parseInt($(this).attr('max'), 10);
    var valueCurrent = parseInt($(this).val(), 10);
    var name = $(this).attr('name');
    if (valueCurrent >= minValue) {
      $(this)
        .closest('div')
        .find(".btn-number[data-type='minus'][data-field='" + name + "']")
        .removeAttr('disabled'); // eslint-disable-line
    } else {
      $(this).val($(this).data('oldValue'));
    }
    if (valueCurrent <= maxValue) {
      $(this)
        .closest('div')
        .find(".btn-number[data-type='plus'][data-field='" + name + "']")
        .removeAttr('disabled'); // eslint-disable-line
    }
    if (valueCurrent === 1) {
      $(this)
        .closest('div')
        .find(".btn-number[data-type='minus'][data-field='" + name + "']")
        .attr('disabled', true); // eslint-disable-line
    } else if (valueCurrent === maxValue) {
      $(this)
        .closest('div')
        .find(".btn-number[data-type='plus'][data-field='" + name + "']")
        .attr('disabled', true); // eslint-disable-line
    }
    // update Drop Down
    var currentQty = parseInt($(this).val(), 10);
    $(this).closest('div').find('select[class*="quantity-select"]').val(currentQty).trigger('change');
  });
  $('body').on('keydown', '.input-number', function (e) {
    // Allow: backspace, delete, tab, escape, enter and .
    if (
      $.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  });
  $('body .quantity-val').on('input', function () {
    var element = $(this);
    var maxValue = parseInt($(this).attr('max'), 10);
    if (element.val() >= maxValue) {
      $('.cart-error').html('');
      $(this).val(maxValue);
      $(this).closest('div').find('select[class*="quantity-select"]').val(maxValue).trigger('change');
    }
  });
};

base.addToCart = function () {
  $(document).on('click', 'button.add-to-cart, button.add-to-cart-global, button.prdt_tile_btn', function () {
    var addToCartUrl;
    var pid;
    var pidsObj;
    var setPids;
    var sourceage = $(this).data('sourcepage');
    var atcOnTile = $(this).data('view');
    var $productTile = $(this).closest('.product-tile');
    var wlRemoveUrl = $productTile.find('.removefromwl').val();

    if (atcOnTile === 'tile') {
      var selectASize = $(this).data('selectsizemsg');
      var addToCarLabel = $(this).data('add-to-cart-label');
      $productTile.find('button.prdt_tile_btn').text(selectASize);
      $productTile.find('button.prdt_tile_btn').addClass('soldout').addClass('disabled');
      setTimeout(function () {
        $productTile.find('button.prdt_tile_btn').text(addToCarLabel); // eslint-disable-line
        $productTile.find('button.prdt_tile_btn').removeClass('soldout').removeClass('disabled'); // eslint-disable-line
      }, 3000);
    }

    var isReadyToOrder = $(this).data('readytoorder');
    $('.select-size-color').empty();
    if (!isReadyToOrder) {
      var formData = {};
      formData.errorFields = ['no variant selected'];
      formData.formName = 'Add to Cart';
      $('body').trigger('adobeTagManager:formError', formData);
      var sDiv = $('<div class="col-12 select-size-div" tabindex="0">');
      sDiv.html($(this).data('readytoordertext'));

      if (window.FABuild && typeof window.FABuild.showCustomColorAndSizeSheet === 'function') {
        window.FABuild.showCustomColorAndSizeSheet();
      }

      if ($('#missedattrSelection-ADA').length) {
        $('#missedattrSelection-ADA').html('Please select ' + getErrorFields() + ' to proceed');
      }
      $('.select-size-color').html(sDiv);
      if ($('.page').data('producttype') !== 'set') {
        $('.select-size-color').closest('.product-detail').find('.select-size-div').focus();
        $('.select-size-color')
          .closest('.product-detail')
          .find('.select-size-div')
          .on('keydown', function (e) {
            if (e.which === 9 && !e.shiftKey) {
              e.preventDefault();
              if (getErrorFields() == 'size') {
                $(this)
                  .closest('.attributes')
                  .find('div[data-attr=' + getErrorFields() + ']')
                  .find('.attribute ul li a')[0]
                  .focus();
              } else if (getErrorFields() == 'color') {
                $(this)
                  .closest('.attributes')
                  .find('div[data-attr=' + getErrorFields() + ']')
                  .find('.attribute ul li button')[0]
                  .focus();
              }
            }
          });
      }

      if ($productTile.length > 0 || !($(this).data('producttype') != 'master')) {
        // eslint-disable-line
        // if the product is not a variant
        // do not attempt ajax call for atc. To reduce unnecessary
        return;
      }
    }

    $('body').trigger('product:beforeAddToCart', this);

    if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
      setPids = [];

      $('.product-detail').each(function () {
        if (!$(this).hasClass('product-set-detail')) {
          setPids.push({
            pid: $(this).find('.product-id').text(),
            qty: $(this).find('.quantity-select').val(),
            options: getOptions($(this))
          });
        }
      });
      pidsObj = JSON.stringify(setPids);
    }
    pid = base.getPidValue($(this));

    if ((wlRemoveUrl === null || wlRemoveUrl === undefined) && $(this).closest('.product-quickview').length > 0) {
      var pidData = '?pid=' + pid;
      wlRemoveUrl =
        $(this).closest('.product-quickview').find('.removefromwlQV').length > 0 ? $(this).closest('.product-quickview').find('.removefromwlQV').val() : '';
      wlRemoveUrl += pidData;
    }
    var $productContainer = $(this).closest('.product-detail');
    if (!$productContainer.length) {
      $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
    }

    addToCartUrl = getAddToCartUrl();
    // fetch the qty value on ATC, trigger change to ensure the purchase limit message is removed on interaction with ATC
    // It fetches the value from the qty input and sets it to qty select option
    var qtyValue = $productContainer.find('.quantity-val').val() || 1;
    $productContainer.find('select[class*="quantity-select"]').val(qtyValue).trigger('change');
    var form = {
      pid: pid,
      pidsObj: pidsObj,
      childProducts: getChildProducts(),
      quantity: !$(this).closest('div').hasClass('hover-content') && base.getQuantitySelected($(this)) ? base.getQuantitySelected($(this)) : 1
    };

    if (!$('.bundle-item').length) {
      form.options = getOptions($productContainer);
    }
    $(this).trigger('updateAddToCartFormData', form);
    if (addToCartUrl && isReadyToOrder) {
      $.ajax({
        url: addToCartUrl,
        method: 'POST',
        data: form,
        success: function (data) {
          // return and trigger a message product addition exceeded the basket preference limit
          if (data.message && data.message === 'LIMIT_EXCEEDED') {
            if ($productContainer.is('.product-quickview') && $productContainer.find('.qv-baskelimit-msg').length > 0) {
              $productContainer.find('.qv-baskelimit-msg').removeClass('d-none');
            } else {
              $('body').trigger('triggerBasketLimitMsgModal');
            }
          } else {
            data.sourcepage = sourceage;
            handlePostCartAdd(data);
            $('body').trigger('product:afterAddToCart', data);
            // changes made to remove the product from wishlist of the same is being added from WL - Start
            if ($('.pg-name').length > 0 && $('.pg-name').val() === 'wishlist') {
              $.ajax({
                url: wlRemoveUrl,
                type: 'get',
                dataType: 'json',
                data: {},
                success: function () {
                  window.location.reload();
                },
                error: function () {
                  // do nothing
                }
              });
            }
          }
          // changes made to remove the product from wishlist of the same is being added from WL - End
          $.spinner().stop();
        },
        error: function () {
          $.spinner().stop();
        }
      });
    }
  });
};
base.showAddToCartConfirmation = showAddToCartConfirmation;
base.methods = {
  editBonusProducts: function (data) {
    chooseBonusProducts(data);
  }
};
base.checkProductAvailability = function (prodType, availUrl, context) {
  var $productContainer = $('body').find('.product-detail') || context;
  if ($('.page').data('producttype') || prodType) {
    var productType = prodType || $('.page').data('producttype').toLowerCase();
    var isVariant = productType === 'variant' || productType === 'product';
    var isMaster = productType === 'master';
    var isSet = productType === 'set';
    var url = $('.page').data('availabilityurl') || availUrl;
    var availabilityValue;
    if (!isSet) {
      $.ajax({
        url: url,
        method: 'GET',
        success: function (data) {
          if (isVariant) {
            var availabilityMessages = data.availability.messages;
            availabilityMessages.forEach(function (message) {
              var colorStyle = data.availability.hexColorCode;
              if (colorStyle) {
                colorStyle = 'style=color:' + colorStyle;
              }
              availabilityValue = '<li><div ' + colorStyle + '>' + message + '</div></li>';
            });
            $productContainer.find('.product-availability .purchaselimit').empty();
            if ($productContainer.hasClass('product-quickview')) {
              $productContainer.find('button.add-to-cart, button.add-to-cart-global').attr('disabled', !data.product.available);
            } else {
              $('button.add-to-cart, button.add-to-cart-global').attr('disabled', !data.product.available);
            }
            if (data.product.readyToOrder && !data.product.availability.isInPurchaselimit) {
              $productContainer.find('.product-availability .purchaselimit').html(data.product.availability.isInPurchaselimitMessage);
              $productContainer.find('.product-availability .purchaselimit').attr('aria-describedby', 'purchase-limit');
              var formData = {};
              formData.errorFields = [data.product.availability.isInPurchaselimitMessage];
              formData.formName = 'Add to Cart';
              $('body').trigger('adobeTagManager:formError', formData);
            }
            if ((!data.product.available || !data.product.readyToOrder) && data.product.productType !== 'master') {
              $productContainer.find('button.atc-button-pdp').addClass('soldout');
              $productContainer.find('button.atc-button-pdp').text(data.resources.soldout);
              if ($productContainer.hasClass('product-quickview')) {
                $productContainer.find('button.add-to-cart-global').addClass('soldout');
                $productContainer.find('button.add-to-cart-global').text(data.resources.soldout);
              }
            }
            $($productContainer).trigger('product:updateAvailability', {
              product: data.product,
              $productContainer: $productContainer,
              message: availabilityValue,
              resources: data.resources
            });
          } else if (isMaster) {
            if ($productContainer.hasClass('product-quickview')) {
              $productContainer.find('button.add-to-cart, button.add-to-cart-global').attr('disabled', !data.product.available);
            } else {
              $('button.add-to-cart, button.add-to-cart-global').attr('disabled', !data.product.available);
            }
            if (!data.product.available) {
              $productContainer.find('button.atc-button-pdp').addClass('soldout');
              $productContainer.find('button.atc-button-pdp').text(data.resources.soldout);
              if ($productContainer.hasClass('product-quickview')) {
                $productContainer.find('button.add-to-cart-global').addClass('soldout');
                $productContainer.find('button.add-to-cart-global').text(data.resources.soldout);
              }
            }
          }
        },
        error: function () {
          $.spinner().stop();
        }
      });
    } else if (isSet) {
      $.ajax({
        url: url,
        method: 'GET',
        success: function (data) {
          data.product.individualProducts.forEach(function (item) {
            $productContainer = $('body').find('.product-detail.set-item.' + item.id);
            if (item.productType === 'variant' || item.productType === 'product') {
              var availabilityMessages = item.availability.messages;
              availabilityMessages.forEach(function (message) {
                var colorStyle = item.availability.hexColorCode;
                if (colorStyle) {
                  colorStyle = 'style=color:' + colorStyle;
                }
                availabilityValue = '<li><div ' + colorStyle + '>' + message + '</div></li>';
              });
              $productContainer.find('.product-availability .purchaselimit').empty();
              $('button.add-to-cart, button.add-to-cart-global', $productContainer).attr('disabled', !item.available);
              if (item.readyToOrder && !item.availability.isInPurchaselimit) {
                $productContainer.find('.product-availability .purchaselimit').html(item.availability.isInPurchaselimitMessage);
                $productContainer.find('.product-availability .purchaselimit').attr('aria-describedby', 'purchase-limit');
                var formData = {};
                formData.errorFields = [item.availability.isInPurchaselimitMessage];
                formData.formName = 'Add to Cart';
                $('body').trigger('adobeTagManager:formError', formData);
              }
              if ((!item.available || !item.readyToOrder) && item.productType !== 'master') {
                $productContainer.find('button.atc-button-pdp').addClass('soldout');
                $productContainer.find('button.atc-button-pdp').text(data.resources.soldout);
                if ($productContainer.hasClass('product-quickview')) {
                  $productContainer.find('button.add-to-cart-global').addClass('soldout');
                  $productContainer.find('button.add-to-cart-global').text(data.resources.soldout);
                }
              }
              $($productContainer).trigger('product:updateAvailability', {
                product: item,
                $productContainer: $productContainer,
                message: availabilityValue,
                resources: data.resources
              });
            } else if (item.productType === 'master') {
              $('button.add-to-cart, button.add-to-cart-global', $productContainer).attr('disabled', !item.available);
              if (!item.available) {
                $productContainer.find('button.atc-button-pdp').addClass('soldout');
                $productContainer.find('button.atc-button-pdp').text(data.resources.soldout);
                if ($productContainer.hasClass('product-quickview')) {
                  $productContainer.find('button.add-to-cart-global').addClass('soldout');
                  $productContainer.find('button.add-to-cart-global').text(data.resources.soldout);
                }
              }
            }
          });
        },
        error: function () {
          $.spinner().stop();
        }
      });
    }
  }
};

base.addBonusProductsToCart = function () {
  $(document).on('click', '.add-bonus-products', function () {
    var $readyToOrderBonusProducts = $('input[name="chooseBonusproductIds"]:checked');
    var data = {};
    var url = $('.choose-bonus-product-dialog').data('addtocarturl');
    var pidsObject = {
      bonusProducts: []
    };

    $.each($readyToOrderBonusProducts, function () {
      var qtyOption = 1;

      var option = null;
      if (qtyOption > 0) {
        if ($(this).data('optionid') && $(this).data('option-selected-value')) {
          option = {};
          option.optionId = $(this).data('optionid');
          option.productId = $(this).data('pid');
          option.selectedValueId = $(this).data('option-selected-value');
        }
        pidsObject.bonusProducts.push({
          pid: $(this).data('pid'),
          qty: qtyOption,
          options: [option]
        });
        pidsObject.totalQty = parseInt($('.pre-cart-products').length > 0 ? $('.pre-cart-products').html() : 1, 10);
      }
    });
    // Adding product from details
    if ($(this).hasClass('from-modal')) {
      pidsObject.bonusProducts = [];
      pidsObject.bonusProducts.push({
        pid: $(this).data('pid'),
        qty: 1,
        options: null
      });
    }

    data = {
      pids: JSON.stringify(pidsObject),
      uuid: $('.choose-bonus-product-dialog').data('uuid'),
      pliuuid: $('.choose-bonus-product-dialog').data('pliuuid')
    };
    $.spinner().start();
    $.ajax({
      url: url,
      data: data,
      method: 'POST',
      success: function (response) {
        $.spinner().stop();
        if (response.error) {
          $('.choice-of-bonus-product .error-div').empty().append('<div class="alert alert-danger add-to-basket-alerts text-center">');
          $('.choice-of-bonus-product .error-div .add-to-basket-alerts').html(response.errorMessage);
        } else {
          $('.configure-bonus-product-attributes').html(response);
          $('.bonus-products-step2').removeClass('hidden-xl-down');
          $('#chooseBonusProductModal').modal('hide');
          $('#editProductModal').modal('hide');
          location.reload();
        }
      },
      error: function () {
        $.spinner().stop();
      }
    });
  });
};
module.exports = base;
