'use strict';

const processInclude = require('base/util');

$(document).ready(function () {
  if (!$('.no-product-search-results').length) {
    const writeOnlyArray = $('.grid-header').data('write-only-array');
    if (!writeOnlyArray) {
      const persistentWishlist = require('core/product/persistentWishlist');
      persistentWishlist.markProductArrWishlisted();
    }
  }
  processInclude(require('./search/search'));
  processInclude(window.productWishListHeart);
  processInclude(window.productWishList); // this is for the quickview heart functionality
  processInclude(require('core/product/quickView'));
  processInclude(require('./product/updateAddToCart'))
  processInclude(require('./components/refinementBar'));
});
