'use strict';

let base = {};
// To invoke store change modal in search
const pdpInstoreInventory = require('../product/pdpInstoreInventory');
const filterStoreRefinement = require('../product/filterStoreRefinement');
const persistentWishlist = require('core/product/persistentWishlist');
const productGridTeasers = require('core/teasersproductgrid');
const cookieUtil = require('core/components/utilhelper');
const login = require('../account/login');
let isLoading = 0;
let isPreviousLoading = 0;
let currentPos = $(window).scrollTop();
const cgid = $('.page .container.search-results').attr('data-cgid');
let criteo;

/**
 * If Criteo is enabled, get sponsored products on first page of search results.
 */
if (window.SitePreferences.criteoEnabled &&
    window.SitePreferences.criteoGetSponsoredProducts && 
    $('body.search-page').length &&
    parseInt($('.grid-header').data('pagination-pagenumber')) === 1) {
    criteo = require('criteo/criteo/criteoProducts');
    criteo.getCriteoSponsoredProducts();
}

var BREAKPOINTS = {
  "mobile"  : 768,
  "tablet"  : 1024,
  "desktop" : 1440
};
const $body = $('body');

base.changeStore = pdpInstoreInventory.changeStore; // Change store modal
base.storeFilterEvents = filterStoreRefinement.storeFilterEvents;
base.selectStoreWithInventory = pdpInstoreInventory.selectStoreWithInventory;
base.triggerClickOnEnter = pdpInstoreInventory.triggerClickOnEnter;

$(window).on('load', function () {
    let getModelView = cookieUtil.getCookie('modelViewOn');
    if (cgid && getModelView && getModelView.indexOf(cgid) > -1) {
        $('body')
            .trigger('category:modelView:toggle:on')
            .trigger('adobeTagManager:toggleModelView', $('.body .model-view-on'));
        const $modelViewOff = $('.container .product-search-results .model-view-off');
        if ($modelViewOff.hasClass('selected')) {
            $modelViewOff.removeClass('selected');
            $('.container .product-search-results .model-view-on').addClass('selected');
        }
    }
});

//Track click that trigger PDP events for products on Tealium
$('.tile-wrapper').on('click', function (event) {
    if (event.target.closest('a') && 
        (event.target.closest('a').classList.contains('thumb-link') || 
        event.target.closest('a').classList.contains(('product-tile-url')))) {
        $('body').trigger('adobeTagManager:productClick', $(this));
    }
});

const debounce = require('lodash/debounce');
const loadMoreDebounce = debounce(scrollUpdateCheck, 200);

$(window).scroll(function () {
  loadMoreDebounce();
});

$('.search-results').off('click').on('click', '.view-more-less', function (e) {
    e.preventDefault();
    const $button = $(this);
    const $refinementCategory = $('.refinement-category');
    const viewMoreText = $button.data('viewmore');
    const viewLessText = $button.data('viewless');
    $button.text(function (i, textupdate) {
      return textupdate === viewMoreText ? viewLessText : viewMoreText;
    });
    $button.blur();
    $refinementCategory.toggleClass('viewmore');

    if ($refinementCategory.hasClass('viewmore')) {
      $('.catgories-values').removeClass('d-none');
    } else {
      viewmoreshow();
    }
});

// Remove the no results bar for location
$(document).on('click', '.no-products-store .close', function () {
  let $this = $('.no-products-store');
  $this.addClass('d-none');
});

// Remove the search selection in Refinement
$body.on('click', '.refinement-search-bar button.close-button', function () {
  const $this = $(this);
  $this.addClass('d-none');
  $this.siblings('button.search-button').removeClass('d-none');
  $this.siblings('input').val('');
  clearSearch($this);
});

$body.on('click', '.price-select button.go-price', function (e) {
  e.preventDefault();
  e.stopPropagation();
  const $form = this.form;
  // eslint-disable-next-line radix
  const min = $form.minPrice.value ? parseInt($form.minPrice.value) : ''; // TODO consitantly return number
  // eslint-disable-next-line radix
  const max = $form.maxPrice.value ? parseInt($form.maxPrice.value) : ''; // TODO consitantly return number
  let search = false;
  const regexTester = new RegExp(/^(\s*|\d+)$/);
  const toggleInputsErrorState = function () {
    const $minInput = $('#min-input');
    const $maxInput = $('#max-input');

    // TODO remplace with classToggle()
    if (regexTester.test(min) === false) {
      $minInput.addClass('error');
    } else {
      $minInput.removeClass('error');
    }
    if (regexTester.test(max) === false) {
      $maxInput.addClass('error');
    } else {
      $maxInput.removeClass('error');
    }
  };
  if (!!min && !!max) {
    if (!regexTester.test(min) || !regexTester.test(max)) {
      search = false;
    } else if (min < max) {
      search = true;
    } else {
      search = false;
    }
  } else if (!min && !!max) {
    search = regexTester.test(max);
  } else if (!max && !!min) {
    search = regexTester.test(min);
  } else {
    search = true;
  }
  if (search) {
    let searchURL = $(this).data('href');
    if (min) {
      searchURL = searchURL + (searchURL.indexOf('?') > -1 ? '&pmin=' : '?pmin=') + min;
    }
    if (max) {
      searchURL = searchURL + (searchURL.indexOf('?') > -1 ? '&pmax=' : '?pmax=') + max;
    }
    $.spinner().start();
    $(this).trigger('search:filter', e);

    const $this = $(this);
    $.ajax({
      url: searchURL,
      data: {
        page: $('.grid-footer').data('page-number'),
        selectedUrl: searchURL,
        filter: true,
        ajax: true
      },
      method: 'GET',
      success: function (response) {
        const result = parseResults(response, e);
        if (result) {
          window.lazyload.lazyLoadImages();

          if ($(response).find('.search-results-total-count').html() !== '0') {
            updateSortOptions(response);
          }
          history.pushState({}, '', searchURL);
          $('.card-header button').removeClass('clicked');
          $('.card-body').find('.selected').parents('.refinement').find('.card-header button').addClass('clicked');
          $('.card-body').parents('.refinement.active').find('.card-header button').addClass('clicked');
          $('.view-more-less').text('View Less');
          $('.refinement-category').toggleClass('viewmore');
          $('.price-error').addClass('d-none');
          $('.price-select input').removeClass('error');
          $('#min-input').removeClass('error');
          $('#max-input').removeClass('error');
          $('.refinement-price .no-results').addClass('d-none');
          $('body').trigger('adobeTagManager:productArrayUpdate', $this);
          $('.search-results .price-no-results').addClass('d-none');
        } else {
          $('.refinement-price .no-results').removeClass('d-none');
          $('.refinement-price .price-error').addClass('d-none');
          $('.search-results .price-no-results').removeClass('d-none');
        }
        $.spinner().stop();
        if (window.SitePreferences.criteoEnabled && window.SitePreferences.criteoGetSponsoredProducts) {
            criteo.getCriteoSponsoredProducts($(response).closest('[data-querystring]').data('querystring'));
        }
      },
      error: function () {
        $('.price-error').removeClass('d-none');
        toggleInputsErrorState();
        $.spinner().stop();
      }
    });
  } else {
    $('.refinement-price .price-error').removeClass('d-none');
    $('.refinement-price .no-results').addClass('d-none');
    toggleInputsErrorState();
  }
});

// eslint-disable-next-line consistent-return
$(document).on('keydown', '#min-input, #max-input', function (e) {
  if (e.ctrlKey === true && (e.which === 118 || e.which === 86)) {
    e.preventDefault();
  }

  const keyVal = e.which ? e.which : e.keyCode;
  if (keyVal === 46 || keyVal === 190 || keyVal === 8 || keyVal === 9) {
    return true;
  } else if (!(keyVal >= 48 && keyVal <= 57) && !(keyVal >= 96 && keyVal <= 105)) {
    return false;
  }
});

$body.on('click', '.product-grid div.product', function () {
  var pid = $(this).attr('data-pid');
  var startPageSize = $(this).parent().attr('data-tile-start');
  var state = {
    pid: pid
  };
  var locationParts = window.location.href.split('?');
  var newLocation = locationParts[0].split('#')[0];
  // eslint-disable-next-line no-nested-ternary
  var newLocParams = window.params ? window.params : locationParts[1] ? locationParts[1].split('#')[0] : '';
  var queryParams = newLocParams ? newLocParams.split('&') : '';
  if (!queryParams && !!startPageSize) {
    newLocation = newLocation + '?start=' + startPageSize;
  } else if (!!queryParams && newLocParams.indexOf('start=') < 0 && !!startPageSize) {
    newLocation = newLocation.concat('?start=' + startPageSize, '&' + newLocParams);
  } else {
    for (let i = 0; i < queryParams.length; i++) {
      if (i !== 0) {
        newLocation += '&';
      } else {
        newLocation += '?';
      }
      if (queryParams[i].indexOf('start') > -1) {
        newLocation = newLocation + 'start=' + startPageSize;
      } else {
        newLocation += queryParams[i];
      }
    }
  }
  cookieUtil.setCookie('itemID', pid, 2);
  history.replaceState(state, null, newLocation);
});

$body.on('change keyup', '.price-select input', function () {
  const $this = $(this);
  if ($this.val().length > 0) {
    $this.addClass('validdata');
  } else {
    $this.removeClass('validdata success-change-icon');
  }
});

$body.on('input', '.price-select input', function () {
  const $input = $(this);
  const inputValue = $input.val();
  $input.val(inputValue.replace(/[^0-9a-zA-Z]/g, ''));
});

function shopItemHandler() {
  if ($(window).width() < BREAKPOINTS.tablet && !!window.filterType) {
    window.location = window.lastFilter;
  } else {
    closeButton();
  }

  $('.product-refinement-no-search').data('href', window.lastFilter);
}

$('.refinement-wrapper').on('click', '.shop-item', shopItemHandler);
$body.on('click', '.my-designers-bar .shop-item', shopItemHandler);
$body.on('click', '.my-sizes-bar .shop-item', shopItemHandler);

$('.container').on('click', '.show-previous button', function (e) {
  e.stopPropagation();
  e.preventDefault();
  isPreviousLoading = 1;
  const showPreviousURL = $(this).data('url');
  const data = {
    selectedUrl: showPreviousURL,
    previous: true
  };
  const $gridPrevious = $('.grid-previous').eq(0);
  $gridPrevious
    .removeClass('invisible')
    .find('.show-previous').addClass('invisible');
  $gridPrevious.spinner().start();

  $.ajax({
    url: showPreviousURL,
    data: data,
    method: 'GET',
    success: function (response) {
      $gridPrevious.replaceWith(response);
      // eslint-disable-next-line newline-per-chained-call
      updateSortOptions(response);
      window.lazyload.lazyLoadImages();
      // update review teasers
      productGridTeasers.addReviews();
      $gridPrevious.spinner().stop(); // TODO link to node could be already lost
      isPreviousLoading = 0;
      $('body').css('overflow', 'auto');
    },
    error: function () {
      $gridPrevious.spinner().stop();
      $('body').css('overflow', 'auto');
    }
  });
});

// Hide the price error in case close button is clicked
$body.on('click', '.search-results .price-no-results button.close-button', function () {
  $('.search-results .price-no-results').addClass('d-none');
});

/* Javascript to load on page load*/
$(document).ready(function () {
  scrollToLastViewed()
    .then(() => {
      $.spinner().stop();
    });

  // make sure product grid doesn't add slick to search results on desktop to avoid image zoom
  if ($(window).width() > 1023.99) {
    const productGridTile = document.querySelector('#product-search-results .image-container');
    if (productGridTile) {
      productGridTile.classList.add('prevent-slick');
    }
  }
  // add review
  productGridTeasers.addReviews();
  if (document.querySelector('.refinement-bar')) {
    window.onpopstate = function () {
      window.location = location.href;
    };
  }
});

function scrollUpdateCheck() {
  const $window = $(window);
  const $showMoreButton = $('.show-more button');
  const $showPrevButton = $('.show-previous button');

  let viewportInAdvance = 1;
  if (window.innerWidth <= BREAKPOINTS.mobile) {
    viewportInAdvance += 1;
  } else if (window.innerWidth <= BREAKPOINTS.tablet) {
    viewportInAdvance += 0.5;
  }

  const heightInAdvance = window.innerHeight * viewportInAdvance;

  if ($showMoreButton.length) {
    const $showMore = $('.show-more');
    const elementTop = $showMore.offset().top;
    const elementBottom = elementTop + $showMore.outerHeight();
    const viewportTop = $window.scrollTop();
    const viewportBottom = viewportTop + $window.height();
    const newViewportBottom = viewportBottom + heightInAdvance;

    // eslint-disable-next-line no-undef
    if (isLoading === 0 && viewportTop < elementTop && elementBottom < newViewportBottom) {
      isLoading = 1;
      $showMoreButton.click();
    }
  }
  if ($showPrevButton.length) {
    const $showPrevious = $('.show-previous');
    const $gridBox = $('.show-less').closest('.product-grid');
    const gridBoxSizes = $gridBox.get(0).getBoundingClientRect();
    const elementTop = $showPrevious.offset().top;
    const elementBottom = elementTop + $showPrevious.outerHeight();
    const viewportTop = $window.scrollTop();
    const viewportBottom = viewportTop + $window.height();
    const newViewportTop = viewportTop - heightInAdvance;

    if (isPreviousLoading === 0
        && ($window.scrollTop() < currentPos && newViewportTop < elementTop && elementBottom < viewportBottom
          || gridBoxSizes.top > 0)) {
      isPreviousLoading = 1;
      $showPrevButton.click();
    }
    currentPos = $window.scrollTop();
  }
  //to prevent product have null price or null image displayed as blank tile in product array
  const productIdElements = document.getElementsByClassName('displayProductTile');
  for (let el of productIdElements) {
    document.querySelector('.wishlist-prod-tile[data-id="' + el.dataset.pid + '"]').classList.add("d-none");
  }
}

/*
 * Change width of the select according to width of the selected option
 */
function commonSortWidthHandler() {
    const $selectSortOrder = $('.sort-selection-block .select-sort-order');
    const text = $selectSortOrder.find('option:selected').text();
    const $aux = $('<select/>').append($('<option/>').text(text));
    $selectSortOrder.after($aux);
    $selectSortOrder.width($aux.width() + 10);
    $aux.remove();
}

function buildPictureTag(data, additionalImageData) {
  let additionalData = '';
  if (additionalImageData) {
    // Must use single-quotes because JSON.stringify uses double-quotes
    additionalData = `data-additional-images='${additionalImageData}'`;
  }

  return `
    <picture class="tile-image" ${additionalData}>
      <source media="(min-width: 426px)" data-srcset="${data.hiresMinURL + ' ,' + data.hiresURL + ' 2x'}">
      <source media="(max-width: 425px)" data-srcset="${data.url}">
      <img width="255" height="340"
        src="${data.url}"
        alt="${data.alt}"
        title="${data.title}"
        itemprop="image"
      />
    </picture>
  `;
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
function getContent($element, $target) {
  const showMoreUrl = $element.data('url');
  $.spinner().start();
  $.ajax({
      url: showMoreUrl,
      method: 'GET',
      success: function (response) {
          $target.append(response);
          $.spinner().stop();
      },
      error: function () {
          $.spinner().stop();
      }
  });
}

/*
 * remove the store selector modal when a store is selected out of results
 */
function removeSelectStoreModal() {
  const $inStoreInventoryModal = $('#inStoreInventoryModal');
  if ($inStoreInventoryModal.length > 0) {
    // $inStoreInventoryModal.modal('hide');
    // $inStoreInventoryModal.attr('aria-modal', 'false');
    $inStoreInventoryModal.remove();
  }
}

/**
 * Removes the specified parameter from url
 *
 * @param {string} url - the url
 * @param {string} parameter - parameter to be removed
 * @returns {string} url - url after removing the parameter
 */
function removeURLParameter(url, parameter) {
  // prefer to use l.search if you have a location/link object
  let urlparts = url.split('?');
  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + '=';
    let pars = urlparts[1].split(/[&;]/g);
    // reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      // idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    // eslint-disable-next-line no-param-reassign
    url = urlparts[0] + '?' + pars.join('&');
    return url;
  }
  return url;
}

/**
 * Updates the store name
 * @param {Object} data - Contains the store info
 */
function updateStoreContent(data) {
  $.spinner().start();
  const setStoreUrl = data.url;
  // form data
  const form = {
    storeId: data.storeID,
    storeDistance: data.storeDistance,
    ajax: true,
    savetosession: true,
    noviewdata: true
  };
  if (setStoreUrl) {
    $.ajax({
      url: setStoreUrl,
      data: form,
      success: function () {
        const $changeStore = $('.change-store');
        //eslint-disable-line
        $changeStore.html(data.name);
        const $storeRefine = $changeStore.parent().siblings('.store-refine');
        const $bopisCheckbox = $storeRefine.find('input[id="bopisCheck"]');
        const dataUrl = $bopisCheckbox.data('href');
        // remove the store filter in the refinement and execute the search
        const storeUrl = removeURLParameter(dataUrl, 'storeid');
        $('.store-refine').attr('data-search-source', 'store');
        $bopisCheckbox
            .attr('data-href', storeUrl)
            .removeAttr('disabled')
            .trigger('click');
        $('.no-products-store').addClass('d-none');
      },
      error: function () {
        $.spinner().stop();
      }
    });
  }
}

base.showContentTa = function () {
  const $container = $('.container');
  const $contentSearchResults = $('#content-search-results');
  // Display content results from the search
  $container.on('click', '.content-search', function () {
      if ($contentSearchResults.html() === '') {
          getContent($(this), $contentSearchResults);
      }
  });

  // Display the next page of content results from the search
  $container.on('click', '.show-more-content button', function () {
      getContent($(this), $contentSearchResults);
      $('.show-more-content').remove();
  });
}

base.modelViewToggle = function () {
    const $body = $('body');

    $body.on('category:set:model:view', function (e, data) {
        const $modelView = $('.model-view');
        if ($modelView.length) {
            const toggleText = $modelView.find('.selected').text();
            const eventName = (toggleText && toggleText.toLowerCase().indexOf('on') > -1) ? 'on' : 'off';
            $('body').trigger('category:modelView:toggle:' + eventName, data);
        }
    });

    // TODO investigate to move into universal handler
    $body.on('category:modelView:toggle:on', function (e, data) {
        let $mvTiles = $('.js-product-model-img');
        if (data && data.productContainer) {
            var productContainer = data.productContainer;
            $mvTiles = $(productContainer).find('.js-product-model-img');
        }

        let oldIndex;
        if (data && data.oldImgs && data.oldImgs.length > 0) {
            oldIndex = data.oldImgs.length;
        }

        if ($mvTiles && $mvTiles.length > 0 && $('.model-view').length > 0) {
            $mvTiles.toArray().forEach(function (mvTile) {
                mvTile = $(mvTile);
                if (mvTile.hasClass('image-swapped')) {
                  return;
                }

                let hiresmodalImage = null;
                const $tileImages = mvTile.find('.tile-image');
                $tileImages.toArray().forEach(function (img, index) {
                    img = $(img);
                    const additionalImages = img.data('additional-images');
                    if (additionalImages && additionalImages.length > 0) {
                        for (var i = 0; i <= additionalImages.length - 1; i++) {
                            if (additionalImages[i]['hiresModelURL'] != null) {
                                [additionalImages[0], additionalImages[1]] = [additionalImages[1], additionalImages[0]];
                                // Loop through object from data-additional-images and assign attributes to newly created image.
                                const el = buildPictureTag(additionalImages[i], JSON.stringify(additionalImages));

                                mvTile.find('.tile-image').eq(0).before(el).remove();
                                mvTile.addClass('image-swapped');

                                break;
                            }
                        }
                    } else if (oldIndex && oldIndex > 0) {
                        if (index === oldIndex + 1) {
                            hiresmodalImage = img.clone();
                            img.remove();
                        }
                    } else {
                        if (index === 1) {
                            hiresmodalImage = img.clone();
                            img.remove();
                        }
                    }
                });

                if (hiresmodalImage) {
                    mvTile.find('.tile-image').eq(0).before(hiresmodalImage);
                    mvTile.addClass('image-swapped');
                }
            });
        }
    });

    $body.on('category:modelView:toggle:off', function (e, data) {
        let $mvTiles = $('.js-product-model-img');
        if (data && data.productContainer) {
            var productContainer = data.productContainer;
            $mvTiles = $(productContainer).find('.js-product-model-img');
        }

        let oldIndex;
        if (data && data.oldImgs && data.oldImgs.length > 0) {
            oldIndex = data.oldImgs.length;
        }

        if ($mvTiles && $mvTiles.length > 0) {
            $mvTiles.toArray().forEach(function (mvTile) {
                mvTile = $(mvTile);
                if (!mvTile.hasClass('image-swapped')) {
                  return;
                }

                let hiresmodalImage = null;
                const $tileImages = mvTile.find('.tile-image');
                $tileImages.toArray().forEach(function (img, index) {
                    img = $(img);
                    const additionalImages = img.data('additional-images');
                    if (additionalImages && additionalImages.length > 0) {
                        for (var i = 0; i <= additionalImages.length - 1; i++) {
                            if (additionalImages[i]['hiresModelURL'] != null) {
                                [additionalImages[0], additionalImages[1]] = [additionalImages[1], additionalImages[0]];
                                // Loop through object from data-additional-images and assign attributes to newly created image.
                                const el = buildPictureTag(additionalImages[i], JSON.stringify(additionalImages));

                                mvTile.find('.tile-image').eq(0).before(el).remove();
                                mvTile.removeClass('image-swapped');

                                break;
                            }
                        }
                    } else if (oldIndex && oldIndex > 0) {
                        if (index === oldIndex) {
                            hiresmodalImage = img.clone();
                            img.remove();
                        }
                    } else {
                        if (index === 0) {
                            hiresmodalImage = img.clone();
                            img.remove();
                        }
                    }
                });

                if (hiresmodalImage) {
                    mvTile.find('.tile-image').eq(0).after(hiresmodalImage);
                    mvTile.removeClass('image-swapped');
                }
            });
        }
    });

    $body.on('click', '.model-view-on', function () {
        let cookie = cookieUtil.getCookie('modelViewOn');
        try {
            if (cookie && cookie.length > 0) {
                if (cookie.indexOf(cgid) === -1) {
                    cookie = cookie.concat('|', cgid);
                }
            } else {
                cookie = '';
                cookie = cookie.concat(cgid);
            }
            cookieUtil.setCookie('modelViewOn', cookie);
        } catch (e) {
            // Do nothing
        }
        $('.model-view-off').removeClass('selected');
        $(this).addClass('selected');
        $('body')
            .trigger('category:modelView:toggle:on')
            .trigger('adobeTagManager:toggleModelView', $(this));
    });

    $body.on('click', '.model-view-off', function () {
        let cookie = cookieUtil.getCookie('modelViewOn');
        try {
            if (cookie != null && cookie.length > 0) {
                if (cookie.indexOf(cgid) > -1) {
                    cookie = cookie.replace(cgid, '');
                    cookie = cookie.replace('||', '|');
                }
            }
            cookieUtil.setCookie('modelViewOn', cookie);
        } catch (e) {
            // Do nothing
        }
        $('body').find('.model-view-on').removeClass('selected');
        $(this).addClass('selected');
        $('body')
            .trigger('category:modelView:toggle:off')
            .trigger('adobeTagManager:toggleModelView', $(this));
    });
};

/**
 * Is the window large-ish?
 * @returns {Boolean} true if large, otherwise false.
 */
function isDesktop() {
    return window.innerWidth >= BREAKPOINTS.tablet;
}

base.cloneCategoryRefinement = function () {
    const $body = $('body');

    $body.on('category:category:refinement:clone', function () {
        let $categoryRefinementBar = $('.category-refinement-bar');
        if (!isDesktop()) {
            let $refinement = $('#refinement-category');
            if (!$refinement) {
                $refinement = $('#refinement-by-category');
            }
            var hasAppendedfirstLi = true;
            var isSearchLanding = $('body').hasClass('search-landing-page');
            if ($refinement && $refinement.length > 0 && $refinement.find('ul').length > 0) {
                $refinement.find('.all-results-link').remove();
                $('.category-refinment-mobile').empty().html('<ul class="category-refinement-bar"></ul>');
                var $this = isSearchLanding ? $refinement.find('ul li').first().parent() : $refinement.find('ul li').last().parent();
                var result = $($this).find('li').clone();
                result.find('ul').remove();
                result.find('.all-results-link').remove();
                $categoryRefinementBar = $('.category-refinement-bar');
                if (result.length > 0) {
                  $categoryRefinementBar.html(result);
                }
                var firstSelected = $refinement.find('.selected-category-search');
                var firstSelectedHtml;
                if (firstSelected && firstSelected.length > 0) {
                    $refinement.find('.selected-category-search').eq(0).find('ul').remove();
                    firstSelectedHtml = $refinement.find('.selected-category-search').eq(0).clone();
                }
                var allLabel = 'All';
                const $parentURLElement = $('div[data-parent-url]');
                var firstLiURL =
                  $parentURLElement.length > 0 && $parentURLElement.data('parent-url')
                        ? $parentURLElement.data('parent-url')
                        : window.location.href;
                if (isSearchLanding) {
                    allLabel = 'All Results';
                    firstLiURL = $('body').data('search-reseturl');
                }
                const $categoryRefineBarItem = $('.category-refinement-bar li');
                if (hasAppendedfirstLi && $categoryRefineBarItem.length > 0) {
                    if (isSearchLanding && firstSelected && firstSelected.length > 0) {
                        const $catPositionElement = $('div[data-cat-position]');
                        $categoryRefineBarItem
                            .eq($catPositionElement.length > 0 ? $catPositionElement.data('data-cat-position') : 0)
                            .before(firstSelectedHtml);
                    }
                    $categoryRefineBarItem
                        .eq(0)
                        .before(
                            `<li class="catgories-values adobelaunch__subnav show-in-mens show-in-womens" aria-label="All">
                                <a href="${firstLiURL}" data-type="category" class="text-left">
                                   <span title="Refine by By Category: All" class="refinement-category" aria-hidden="true">${allLabel}</span>
                                   <span class="sr-only selected-assistive-text">Refine by By Category: All</span>
                                </a>
                            </li>`
                        );

                    hasAppendedfirstLi = false;
                }

                // Remove duplicate category
                try {
                    if (firstSelectedHtml && firstSelectedHtml.length > 0) {
                        var count = 0;
                        if ($(firstSelectedHtml).eq(0).find('span').length > 0) {
                            var selectedFirstItem = $(firstSelectedHtml).eq(0).find('span').html().trim().toLowerCase();

                            $('.category-refinement-bar .refinement-category').each(function () {
                                if ($(this).html().trim().toLowerCase() === selectedFirstItem) {
                                    if (count === 1) {
                                        $(this).closest('li').remove();
                                    }
                                    count = 1;
                                }
                            });
                        }
                    }
                } catch (e) { }

                // Activate the current category
                var selected = false;
                if ($('.search-results-header').length > 0) {
                    $('.category-refinement-bar .refinement-category').each(function () {
                        if ($(this).closest('.text-left').hasClass('disabled')) {
                            selected = true;
                        }
                        if (
                            $(this).html().trim().toLowerCase() === $('.search-results-header').html().trim().toLowerCase() ||
                            (isSearchLanding && $(this).html().trim().toLowerCase() === $('.last-item').text().trim().toLowerCase())
                        ) {
                            if (!selected) {
                                $('.category-refinement-bar .refinement-category').closest('.text-left').removeClass('disabled');
                                $(this).closest('.text-left').addClass('disabled');
                                selected = true;
                            }
                        }
                    });
                }
                if (!selected) {
                    const $refinementCategoryFirstText = $('.category-refinement-bar .refinement-category').first().closest('.text-left');
                    if (!$refinementCategoryFirstText.hasClass('disabled')) {
                      $refinementCategoryFirstText.addClass('disabled');
                    }
                }
            } // Refinement condition
        } // Desktop condition
    });
    $body.trigger('category:category:refinement:clone');
};

base.catRefineBarActiveHandler = function () {
    $('body').on('click', '.category-refinement-bar .catgories-values a', function () {
        if (!isDesktop()) {
            $('.category-refinement-bar .catgories-values a').removeClass('disabled');
            $(this).addClass('disabled');
        }
    });
};

base.handleFilterShowOnScroll = function () {
  const $productSearchResults = $('.search-results .product-search-results');

  if ($productSearchResults.length) {
    const filterSectionTabletHeight = $('.filter-section-tablet').outerHeight();
    const scrollDownHeight = $productSearchResults.height() - filterSectionTabletHeight;

    if (!isDesktop() && !window.mobileApp) {
      $productSearchResults.css({
        top: `-${scrollDownHeight}px`
      });
    }

    $(document)
      .on('page:scrollUp', function () {
        if (!isDesktop() && !window.mobileApp) {
          const $headerMain = $('.header-main');
          const headerMainHeight = $headerMain.outerHeight();
          const filterSectionTabletHeight = $('.filter-section-tablet').outerHeight();
          const halfHeaderPadding = parseInt($headerMain.css('padding-bottom')) / 2;
          const scrollUpHeight = headerMainHeight - $productSearchResults.height() - halfHeaderPadding + filterSectionTabletHeight;

          $productSearchResults.css({
            top: `${scrollUpHeight}px`
          });
        }
        $productSearchResults.addClass('no-top');
      })
      .on('page:scrollDown', function () {
        if (!isDesktop() && !window.mobileApp) {
          const filterSectionTabletHeight = $('.filter-section-tablet').outerHeight();
          const scrollDownHeight = $productSearchResults.height() - filterSectionTabletHeight;

          $productSearchResults.css({
            top: `-${scrollDownHeight}px`
          });
        }
        $productSearchResults.removeClass('no-top');
      });
  }
};

base.filter = function () {
    // Display refinements bar when Menu icon clicked
    $('.container').on('click', 'button.filter-results', function () {
        const $refinementBar = $('.refinement-bar');
        $refinementBar.addClass('d-block');
        $refinementBar.siblings().attr('aria-hidden', true);
        $refinementBar.closest('.row').siblings().attr('aria-hidden', true);
        $refinementBar.closest('.tab-pane.active').siblings().attr('aria-hidden', true);
        $refinementBar.closest('.container.search-results').siblings().attr('aria-hidden', true);
        $('.refinement-bar .close').focus();
        $('body').addClass('filters-visible modal-open');
        $refinementBar.find(':focusable').first().focus();
        const $refinements = $('.refinement-wrapper .refinements');
        if ($(window).width() < 1023 && $refinements.height() > $('.refinement-wrapper').height()) {
            $('.collapsible-xl.refinement .card-body').height($refinements.height());
        }

        if ($(window).width() <= 1023) {
            const $refinementBarMob = $('.refinement-bar-mob');
            if (!$refinementBarMob.find('.refinements .refinement:visible').hasClass('active')) {
                $refinementBarMob.find('.refinements .refinement:eq(1)').addClass('active');
            }
        }

        window.formFields.findInsiders($refinementBar);
    });
};

base.closeRefinements = function () {
    // Refinements close button
    $('.container').on('click', '.refinement-bar button.close', function () {
      closeButton();
    });
};

base.resize = function () {
    // removed the Close refinement bar and hide modal background if user resizes browser to fix android mobile input field issue.
    $(window).resize(function () {
      if (typeof stickyrefinement === 'function') {
        stickyrefinement();
      }
      // customscrollbar();
      viewmoreshow();
      thumbWidth();
    });
};

/**
 * Handle not found product response from Product-Variation. Made in the scope of CX-2142
 *
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} product - Product model
 * @param {Object} resources - Object with messages used in buttons
 */
function handleProductNotFound($productContainer, product, resources) {
    var $product = $productContainer.closest('.product');
    var $button = $productContainer.find('button.prdt_tile_btn');
    var $link = $productContainer.find('a.prdt_tile_btn');

    $product.data('pid', product.pid);
    $product
        .find('.prdt_tile_btn')
        .data('readytoorder', false);
    $productContainer.find('.bf-product-id').empty().text(product.pid);
    $button.addClass('soldout')
        .removeClass('add-to-cart d-none')
        .text(resources.soldout)
        .attr('disabled', true);
    $link.addClass('d-none');

    let initialIndex = 0;
    const $firstSelectableIndex = $productContainer.find('.firstSelectableIndex');
    if ($firstSelectableIndex.length > 0) {
        initialIndex = Number($firstSelectableIndex.eq(0).attr('data-firstSelectableIndex'));
    }

    var $size = $productContainer.find('.size');
    if (!$size.hasClass('slick-initialized')) {
        // Filter the unselectable slides
        $size.slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            autoplay: false
        });

        if ($size.eq(0).find('.slick-slide').length - 1 === initialIndex) {
            $size.slick('slickGoTo', initialIndex - 1, true);
        } else {
            $size.slick('slickGoTo', initialIndex, true);
        }
    }
}
/**
 * Routes the handling of attribute processing depending on whether the attribute has image swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} product - product data?
 */
function updateAttrs(attrs, $productContainer, product) {
    if (product.productTileSwatch) {
        const $tileSizeSection = $(product.productTileSwatch).find('.tile-size-container');
        if ($tileSizeSection.length > 0) {
            $productContainer.find('.swatch-container .tile-size-container').empty().append($tileSizeSection.html());
        }
    }

    attrs.forEach(function (attr) {
        attr.values.forEach(function (attrValue) {
            const $attrValue = $productContainer.find('a[data-attr-value="' + attrValue.value + '"]');
            if (attrValue.valueUrl) {
                $attrValue.data('valueurl', attrValue.valueUrl);
            } else if (attrValue.url) {
                $attrValue.data('valueurl', attrValue.url);
            }
            if (attrValue.selectable || (!attrValue.selectable && attrValue.waitlist)) {
                $attrValue.removeClass('hide-this-size-swatch');
            } else {
                $attrValue.addClass('hide-this-size-swatch');
            }
            $attrValue.removeClass('selectable swatch-not-available size-not-available');
            const notAvailableClass = $attrValue.hasClass('colorswatch') ? 'swatch-not-available' : 'size-not-available';
            if (attrValue.evaluationsColorsForSelectedSize !== undefined && attrValue.evaluationsColorsForSelectedSize.length > 0) {
                attrValue.evaluationsColorsForSelectedSize.forEach(function (colorEvaluation) {
                    let selector = ".colorswatch.tile-swatch[data-attr-value='$colorReplace']";
                    selector = selector.replace('$colorReplace', colorEvaluation.colorValue);
                    const $color = $attrValue.closest('.color-swatches').find(selector);
                    if (!colorEvaluation.selectable) {
                        $color.addClass('swatch-not-available');
                    } else {
                        $color.removeClass('swatch-not-available');
                    }
                });
            }
            $attrValue.addClass(attrValue.selectable ? '' : notAvailableClass);
        });
    });
}

/**
 * Updates the product view when a product attribute is selected or deselected or when changing quantity
 *
 * @param {string} selectedValueUrl - The Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {Array} oldImgs - Old images array
 * @param {Function} callback - Callback function
 */
function attributeSelect(selectedValueUrl, $productContainer, oldImgs, callback) {
    if (selectedValueUrl) {
        $productContainer.spinner().start();
        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                $productContainer.spinner().stop();
                if (data.productNotFound) {
                    handleProductNotFound($productContainer, data.product, data.resources);
                    return;
                }

                updateAttrs(data.product.variationAttributes, $productContainer, data.product);
                persistentWishlist.makrSingleProductWishlisted(data.product.id, $productContainer);
                const $productElement = $productContainer.closest('.product');
                $productElement
                    .data('pid', data.product.id)
                    .attr('data-pid', data.product.id)
                    .data('producttype', data.product.productType);
                $productContainer.find('.bf-product-id')
                    .empty().text(data.product.id);
                $productContainer.closest('.product').find('.prdt_tile_btn')
                    .data('readytoorder', data.product.readyToOrder && data.product.available);
                if (data.product.readyToOrder && data.product.available) {
                  $productElement.find('.prdt_tile_btn').data('view', 'selected');
                }

                // Update the price
                /* var $priceSelector = $('.price', $productContainer);
                   var $priceRange = $('.range', $productContainer);
                   if (data.product.price.type != null && data.product.price.type == 'range') {
                       $priceRange.empty().html(data.product.price.html);
                   } else {
                       $priceSelector.empty().html(data.product.price.html);
                   }
                */
                const $priceSelector = $('.prod-price', $productContainer);
                $priceSelector
                    .html(data.product.price.html)
                    .find('.prod-price').addClass('prod-price-remove-space');

                // Update promotions
                const $promotionPricing = $('.promotion-pricing', $productContainer);
                const $promotions = $('.promotions', $productContainer);
                if (data.product.promotionalPricing && data.product.promotionalPricing.isPromotionalPrice && data.product.promotionalPricing.promoMessage !== '') {
                    $promotionPricing
                        .html(data.product.promotionalPricing.priceHtml)
                        .removeClass('d-none');
                    $promotions.addClass('d-none');
                } else {
                    $promotionPricing.addClass('d-none');
                    $promotions
                        .html(data.product.promotionsHtml)
                        .removeClass('d-none');
                }

                // Update limited inventory message
                if (data.product.availability.isAboveThresholdLevel) {
                    $('.limited-inventory', $productContainer).empty().text(data.resources.limitedInventory);
                } else {
                    $('.limited-inventory', $productContainer).empty();
                }

                const $productTileButtonLink = $productContainer.find('a.prdt_tile_btn');
                const $productTileButton = $productContainer.find('button.prdt_tile_btn');
                if ((!data.product.available || !data.product.readyToOrder) && data.product.waitlistable && data.product.productType !== 'master') {
                    $productTileButton.addClass('d-none');
                    $productTileButtonLink
                        .removeClass('d-none')
                        .attr('href', data.product.pdpURL + '#waitlistenabled');
                } else if ((!data.product.available || !data.product.readyToOrder) && data.product.productType !== 'master') {
                    $productTileButton
                        .addClass('soldout')
                        .removeClass('add-to-cart d-none')
                        .text(data.resources.soldout)
                        .attr('disabled', true);
                    $productTileButtonLink.addClass('d-none');
                } else {
                    $productTileButton
                        .addClass('add-to-cart')
                        .removeClass('soldout d-none')
                        .removeAttr('disabled');
                    $productTileButtonLink.addClass('d-none');
                    if (data.product.preOrder && data.product.preOrder.applicable && data.product.preOrder.applicable === true) {
                        $productTileButton.text(data.resources.preordertocart);
                    } else if ($('body').hasClass('cart-page')) {
                        $productTileButton.text(data.resources.movetobag);
                    } else {
                        $productTileButton.text(data.resources.addtocart);
                    }
                }
                let initialIndex = 0;
                if ($productContainer.find('.firstSelectableIndex').length > 0) {
                    initialIndex = Number($productContainer.find('.firstSelectableIndex').eq(0).attr('data-firstSelectableIndex'));
                }

                if (!$productContainer.find('.size').hasClass('slick-initialized')) {
                    // Filter the unselectable slides

                    $productContainer.find('.size').slick({
                        dots: false,
                        infinite: false,
                        speed: 300,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        arrows: true,
                        autoplay: false
                    });

                    const $sizeElement = $productContainer.find('.size');
                    if ($sizeElement.eq(0).find('.slick-slide').length - 1 === initialIndex) {
                      $sizeElement.slick('slickGoTo', initialIndex - 1, true);
                    } else {
                      $sizeElement.slick('slickGoTo', initialIndex, true);
                    }
                }
                base.plpSwatchesEvents();
                // Append model images if it is enabled
                $('body').trigger('category:set:model:view', {
                    productContainer: $productContainer,
                    oldImgs: oldImgs
                });

                if (typeof oldImgs !== 'undefined') {
                    for (let oi in oldImgs) {
                        oldImgs[oi].remove();
                    }
                }

                if (callback) {
                    callback();
                }
            },
            error: function () {
                if (callback) {
                    callback();
                }
                $productContainer.spinner().stop();
            }
        });
    }
}

base.plpSwatchesEvents = function () {
    const $body = $('body');

    $body.on('click', '.sizeSwatch', function (e) {
        e.preventDefault();
        if ($(this).hasClass('selected')) {
            return;
        }
        const $selectedSwatch = $(this);
        const attrValue = $selectedSwatch.attr('data-attr-value');
        const $productTile = $selectedSwatch.closest('.product-tile');
        const $prevSelectedSize = $selectedSwatch.closest('.size').find('.sizeSwatch.selected');

        $prevSelectedSize.removeClass('selected');
        $selectedSwatch.addClass('selected');
        $productTile.find('.prdt_tile_btn').data('view', 'selected');
        const attributeSelectCallback = function () {
          const $selectedSizeSwatch = $productTile.find('a[data-attr-value="' + attrValue + '"]');
          if (!$selectedSizeSwatch) {
              return;
          }
          const slickCounter = Number($selectedSizeSwatch.attr('data-slick-counter'));
          $selectedSizeSwatch.addClass('selected');
          $productTile.find('.size').slick('slickGoTo', slickCounter - 1, true);
          $productTile.find('.prdt_tile_btn').data('view', 'selected');
        };
        attributeSelect($(this).data('valueurl'), $productTile, null, attributeSelectCallback);
    });

    $body.on('click', '.color-swatches .swatches .colorswatch', function (e) {
        e.preventDefault();
        if ($(this).hasClass('selected')) {
            return;
        }
        const $selectedSwatch = $(this);
        const $productTile = $selectedSwatch.closest('.product-tile');
        $productTile.find('.color-swatches .swatches .colorswatch.selected').each(function () {
            $(this).removeClass('selected');
        });
        $selectedSwatch.addClass('selected');

        const color = $selectedSwatch.data('attr-value');
        $productTile.find(".color-swatches .swatches .colorswatch[data-attr-value='" + color + "']").addClass('selected');
        $productTile.find('.image-container').removeClass('prevent-slick');
        $productTile.find('.thumb-link').attr('href', $selectedSwatch.attr('href'));
        $productTile.find('.link').attr('href', $selectedSwatch.attr('href'));

        var oldImgs = [];
        $productTile.find('.image-container .thumb-link img').each(function () {
            oldImgs.push($(this));
        });

        if ($selectedSwatch.data('qvurl')) {
            $productTile.find('.image-container .quickview').attr('href', $selectedSwatch.data('qvurl'));
        }

        // TODO: refactor this or remove entirely. Currently causing a loop
        attributeSelect($(this).data('valueurl'), $productTile, oldImgs);

        const data = $(this).find('.swatch-circle').data('imgsource');
        return (
            new Promise(resolve => {
                const $thumblink = $productTile.find('.image-container .thumb-link');
                if ($thumblink.hasClass('slick-initialized')) {
                  $thumblink.slick('unslick');
                }
                $thumblink[0].innerHTML = "";
                for (var i = 0; i < data.length; i++) {
                    // let additionalData = null;
                    // if (i === 0) { // TODO should be pushed to buildPictureTag but on prod it does not
                    //   additionalData = JSON.stringify(data);
                    // }
                    const setImg = data[i];
                    const imgEle = $(buildPictureTag(setImg ));

                    if (i === 0) {
                        // Only check load for first thumb (initial view)
                        imgEle.one('load', () => resolve());
                        imgEle.one('error', () => resolve());
                        // Fallback resolve to not hang tile spinner
                        setTimeout(() => resolve(), 3000);
                    }

                    imgEle.appendTo($thumblink); // Add the image to the div
                }

                // TODO we add bunch of imagese but not initialize carousel
                if (window.FABuild && (!/msie\s|trident\/|Edge\//i.test(navigator.userAgent))) {
                  $thumblink.slick({
                    dots: false,
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                  });
                  $thumblink.closest('.image-container').addClass('prevent-slick')
                }
            })
                // Remove spinner only once first image is finished loading
                .then($productTile.spinner().stop)
        );

        // $thumb.data('current', currentAttrs);
    });

    if (window.FABuild) {
        $('body').on('touchstart', '.thumb-link', function () {
            const $this = $(this);

            if ($this.closest('.image-container').hasClass('prevent-slick')) {
                return false;
            }

            if ($this.hasClass('slick-initialized')) {
              $this.slick('unslick');
            }

            const $firstImage = $this.find('.tile-image:first');
            const firstImageEl = $firstImage.get(0);
            const additionalImageData = firstImageEl.hasAttribute('data-additional-images') ? getAdditionalImageData($firstImage) : null;

            if (additionalImageData instanceof Array) {
              const additionalImageHTML = getElementImagesHTML(additionalImageData.slice(2));
              firstImageEl.insertAdjacentHTML('afterend', additionalImageHTML);
            }

            firstImageEl.removeAttribute('data-additional-images');

            $this.closest('.image-container').addClass('prevent-slick')

            if (!/msie\s|trident\/|Edge\//i.test(navigator.userAgent)) {
                $this.slick({
                    dots: false,
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                });
            }
        });
    }
    /* eslint-disable */
    if ($(window).width() > 1023.99) {
        $body.on('mouseenter', '.thumb-link', function () {
            let initialIndex = 0;
            let productId = '';
            const $this = $(this);
            const $firstSelectableIndex = $this.closest('.product-tile').find('.firstSelectableIndex');
            if ($firstSelectableIndex.length > 0) {
                initialIndex = Number($firstSelectableIndex.eq(0).attr('data-firstSelectableIndex'));
                productId = $firstSelectableIndex.eq(0).attr('data-adobelaunchsizeproductid');
            }
            const $size = $this.closest('.product-tile').find('.size');
            if (!$size.hasClass('slick-initialized')) {
                // Filter the unselectable slides
                $size.slick({
                    dots: false,
                    infinite: false,
                    speed: 300,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: true,
                    autoplay: false
                });

                if ($size.eq(0).find('.slick-slide').length - 1 === initialIndex) {
                    $size.slick('slickGoTo', initialIndex - 1, true);
                } else {
                    $size.slick('slickGoTo', initialIndex, true);
                }
            }

            $size.eq(0).find('.slick-next').addClass('adobelaunch__sizechangearrowlink');
            $size.eq(0).find('.slick-next').attr('data-adobelaunchsizeproductid', productId);

            if ($this.closest('.image-container').hasClass('prevent-slick')) {
                return false;
            }
            // Render the additional images if necessary.
            const $firstImage = $this.find('.tile-image:first');
            const firstImageEl = $firstImage.get(0);
            const additionalImageData = $firstImage.data('additional-images');
            if (additionalImageData instanceof Array) {
                const additionalImageHTML = additionalImageData.reduce((acc, image) => {
                    const el = buildPictureTag(image);
                    return acc + el;
                });
                firstImageEl.removeAttribute('data-additional-images');
                firstImageEl.insertAdjacentHTML('afterend', additionalImageHTML);
            }

            // Initialize the slick carousel on images.
            if ($this.hasClass('slick-initialized')) {
                $this.slick('unslick');
            }
            $this.find('img').each(function () {
                $this.attr('src', $this.attr('data-src'));
            });
            if (!/msie\s|trident\/|Edge\//i.test(navigator.userAgent)) {
                $this.slick({
                    dots: false,
                    infinite: true,
                    speed: 600,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 500
                });
            }
            if ($this.closest('.image-container').find('.tile-image').hasClass('guest-user')) {
                $this.slick('unslick');
            }
        })
        .on('mouseleave', '.thumb-link', function () {
            if ($(this).hasClass('slick-initialized')) {
                $(this).slick('unslick');
            }
        });
        /* eslint-enable */

        $body.on('mouseenter', '.product-tile:not(.size-loaded)', function () {
            const url = $('.hover-content', this).data('size-swatch-url');
            const sizeContainer = $('.tile-size-container', this);
            const tileContainer = $(this);
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'html',
                success: function(response) {
                    sizeContainer.html(response);
                    tileContainer.addClass('size-loaded');

                    // Handle
                    let initialIndex = 0;
                    if (tileContainer.find('.firstSelectableIndex').length > 0) {
                        initialIndex = Number(tileContainer.find('.firstSelectableIndex').eq(0).attr('data-firstSelectableIndex'));
                    }
                    const $size = tileContainer.find('.size');
                    if (!$size.hasClass('slick-initialized')) {
                        $size.slick({
                            dots: false,
                            infinite: false,
                            speed: 300,
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            arrows: true,
                            autoplay: false
                        });

                        if ($size.eq(0).find('.slick-slide').length - 1 === initialIndex) {
                            $size.slick('slickGoTo', initialIndex - 1, true);
                        } else {
                            $size.slick('slickGoTo', initialIndex, true);
                        }
                    }
                },
                error: function(response) {}
            })
        });
    }
};

var thumbWidth = function () {
    if ($(window).width() > 1023.99) {
        $('.search-page .product-tile .thumb-link').each(function () {
            $(this)
                .attr('style', '')
                .css('width', Math.floor($(this).outerWidth()));
        });
    }
};

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    const $updates = $results.find(selector);
    $(selector).html($updates.html());
    // Add reviews on update
    productGridTeasers.addReviews();
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        let $activeDiv = $results.find('.' + $(this)[0].className.replace('/', '').replace('?', '').replace('&', '').replace(/ /g, '.'));
        $activeDiv.addClass('active');
        $activeDiv.find('button').attr('aria-expanded', 'true');
    });

    updateDom($results, '.refinements');
}

function updatePreferencesData($results) {
    const $preferenceAjaxData = $results.find('.preference-ajax-data');

    if ($preferenceAjaxData.length) {
        $('.preference-ajax-data').replaceWith($preferenceAjaxData);
    }
}

function updatePreferenceRefinement($originalHtml, $container) {
    const $preferenceAjaxData = $('.preference-ajax-data');
    if ($preferenceAjaxData.length) {
        $.ajax({
            url: $preferenceAjaxData.data('url'),
            method: 'POST',
            data: { preferencesData: JSON.stringify($preferenceAjaxData.data('preferences')) },
            success: function (results) {
                var $results = $(results);
                var elements = ['.is-brand-preference', '.is-size-preference'];

                if ($originalHtml && $originalHtml.length) {
                    ['.filter-header', '.applied-filters-bar .filter-header'].forEach(function (selector) {
                        updateDom($originalHtml, selector);
                    });
                }

                if (!$('.my-sizes-bar').length && !$('.my-designers-bar').length) {
                    var $element = $results.find('.session-preference-wrapper');
                    $('.session-preference-wrapper').replaceWith($element.length ? $element.children() : '');
                } else {
                    var myBars = ['.my-designers-bar', '.my-sizes-bar'];
                    myBars.forEach(function (className) {
                        var $elementInResults = $results.find(className);
                        $(className).html($elementInResults.length ? $elementInResults.html() : '');
                    });
                }

                const $mobilePcDesigners = $('.mobile-pc-designers');

                if (!$('#updateCustomerPreferencesUrl').length) {
                    elements.unshift('.preference-scripts');
                }
                if (!$mobilePcDesigners.length) {
                    elements.push('.button-preference-wrapper');
                }

                elements.forEach(function (selector) {
                    var $element = $results.find(selector);
                    if ($element.length) {
                        $(selector).replaceWith($element);
                    } else {
                        $(selector).html('');
                    }
                });

                $('.mobile-pc-info-cta-save').toggleClass('sign-in', !window.customerLogin);
                $mobilePcDesigners.data('pref-selected-count', window.designerPrefSelectedCount);
                $('.mobile-pc-sizes').data('pref-selected-count', window.sizePrefSelectedCount);
                window.hbcTooltip.tooltipInit();
                if ($container && $container.length) {
                    $('body').trigger('adobeTagManager:productArrayUpdate', $container);
                }
            }
        });
    }
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @param {event} event - function event
 * @param {string} type - Type of filter event
 * @param {Object} $container
 * @returns {boolean}- return state if there were any search results
 */
function parseResults(response, event, type, $container) {
    const $results = $(response);
    const $noProductStore = $('.no-products-store');
    const $bopisCheck = $('input[id="bopisCheck"]');

    if ($results.find('.search-results-total-count').html() === '0') {
        if (
            event &&
            event.currentTarget &&
            event.currentTarget &&
            $(event.currentTarget).closest('input[id="bopisCheck"]').length > 0 &&
            $noProductStore.length &&
            $noProductStore.hasClass('d-none')
        ) {
            $noProductStore.removeClass('d-none');
            $bopisCheck.attr('disabled', true);
        }
        // For saks only
        if (
            event &&
            event.currentTarget &&
            event.currentTarget &&
            $(event.currentTarget).closest('input[id="bopisCheck"]').length > 0 &&
            $(event.currentTarget).closest('input[id="bopisCheck"]').hasClass('saks-only') &&
            $(event.currentTarget).closest('input[id="bopisCheck"]').attr('data-nostores-msg') !== undefined
        ) {
            $bopisCheck.attr('disabled', 'disabled');
            $bopisCheck.siblings('label').find('span').html($(event.currentTarget).closest('input[id="bopisCheck"]').attr('data-nostores-msg'));
        }

        return false;
    }
    var searchSource = $('.store-refine').attr('data-search-source');
    $('.refinement-price .no-results').addClass('d-none');
    var specialHandlers = {
        '.refinements': handleRefinements
    };
    var domElements = [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar',
        '.search-count',
        '.sort-results-container',
        '.filter-results-container',
        '.sort-selection-block',
        '.shop-item',
        '.search-results-count',
        '.store-bar',
        '.product-search-results .breadcrumb',
        '.product-search-results .search-results-header',
        '#refinement-category'
    ];

    // TODO Seems like this code never used
    // var $prefereceAjaxData = $('.preference-ajax-data');
    // if (!$prefereceAjaxData.length) {
    //     var domElements = domElements.concat(['.filter-header', '.applied-filters-bar .filter-header', '.my-designers-bar', '.my-sizes-bar']);
    // }

    if (type !== 'applied') {
        domElements.push('.applied-filters-bar .applied-filters-wrapper');
    }
    // For store refinement, the .filter-bar is not neccessary to be refreshed
    if (
        event &&
        event.currentTarget &&
        event.currentTarget &&
        $(event.currentTarget).closest('input[id="bopisCheck"]').length > 0 &&
        !$(event.currentTarget).closest('input[id="bopisCheck"]').hasClass('saks-only')
    ) {
        domElements.splice(5, 1);
    }
    // Update DOM elements that do not require special handling
    domElements.forEach(function (selector) {
        updateDom($results, selector);
    });
    $('body')
        .trigger('category:category:refinement:clone')
        .trigger('category:showhide:refinement');

    // Dup header elements
    var dupHeaders = $results.find('.product-search-results .search-results-header');
    Object.keys(dupHeaders).forEach(function (elm) {
        var headerElement = dupHeaders.eq(elm);
        if (headerElement.text().trim() !== '') {
            $('.product-search-results .search-results-header').html(headerElement.html());
        }
    });

    const $productSearchResultsHeader = $('.product-search-results').find('.search-results-header');
    if ($productSearchResultsHeader.length > 1) {
        $productSearchResultsHeader.eq(1).remove();
    }

    // append model images if it is enabled
    if ($results.find('.search-results').data('isrefinedby-store')) {
        $bopisCheck.attr('checked', true);
    } else {
        $bopisCheck.removeAttr('checked');
        // Do not disable the button and message when the search is from store select
        if (
            event &&
            event.currentTarget &&
            event.currentTarget &&
            $(event.currentTarget).closest('input[id="bopisCheck"]').length > 0 &&
            $noProductStore.length &&
            $noProductStore.hasClass('d-none') &&
            searchSource === 'search'
        ) {
            $noProductStore.removeClass('d-none');
            $bopisCheck.attr('disabled', true);
        } else {
            $(document).find('.store-refine').attr('data-search-source', 'search');
        }
    }
    // Update url if the trigger is form the store select from the store change modal
    if ($results.siblings('.search-results').data('storecheck-url') && $(event.currentTarget).closest('input[id="bopisCheck"]').length === 0) {
        $bopisCheck.attr('data-href', $results.siblings('.search-results').data('storecheck-url'));
    }
    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });

    // Update description and title meta tags for SEO
    if ($results) {
        // Description
        var descriptionMeta = $results.filter('[name="description"]');
        if (descriptionMeta.length) {
            $('meta[name="description"]').attr('content', descriptionMeta[0].content);
        }

        // Title
        var titleMeta = $results.filter('title:first');
        if (titleMeta.length) {
            $('title:first')[0].innerHTML = titleMeta[0].innerHTML;
        }

        // Canonical
        var canonicalMeta = $results.filter('[rel="canonical"]');
        if (canonicalMeta.length) {
            $('[rel="canonical"]').attr('href', canonicalMeta[0].href);
        }
    }

    updatePreferencesData($results);
    updatePreferenceRefinement($results, $container);

    window.formFields.updateSelect();
    if ($(window).width() >= BREAKPOINTS.tablet) {
        if (event !== undefined && !$(event.currentTarget).hasClass('bopis-refinement')) {
            $(window).scrollTop($('.search-result-wrapper').offset().top - $('.navigation-section .main-menu').outerHeight() - 18);
        }
    } else {
        $(window).scrollTop($('.product-tile-section').offset().top - document.querySelector('header').offsetHeight);
    }

    // Update PA Banner Content
    var paBannerContent = $results.find('.pa-banner').html();
    $('.pa-banner').html(paBannerContent);

    return true;
}

function prepActiveRefinementTrigger() {
    const activeRefinement = document.querySelector('.refinement.card.active');
    if (!activeRefinement) {
        return;
    }
    const trigger = activeRefinement.querySelector('.card-header button[aria-controls]');
    trigger.setAttribute('aria-expanded', 'true');
}

var viewmoreshow = function () {
    if ($(window).width() > 1023.99) {
        $('.catgories-values').each(function () {
            if ($(this).hasClass('category-hide')) {
                $(this).addClass('d-none');
            }
        });
    } else {
        $('.catgories-values').each(function () {
            if ($(this).hasClass('category-hide')) {
                $(this).removeClass('d-none');
            }
        });
    }
};

viewmoreshow();

/**
 * Update the links for applied filter in case of selection/deselection
 *
 */
function updateAppliedRefinement() {
    $('.applied-filters-wrapper .selected-filter button').each(function () {
        let refinementType = $(this).data('refinement-type');
        let refinementValue = $(this).data('refinement-value');
        let refinementEl = $('.refinements')
            .find('.' + refinementType)
            .find('[data-refinement-value="' + refinementValue + '"]');
        let href = refinementEl.attr('href') || refinementEl.data('href');
        $(this).attr('data-href', href);
    });
}

function updateGrid(url, type, e, $this) {
    const refinementBar = document.querySelector('.refinement-bar');
    const refinementWrapperScrollTop = refinementBar ? refinementBar.scrollTop : 0;

    if ($(window).width() <= BREAKPOINTS.tablet && $('.refinement-bar-mob:visible').length) {
        var scrollvalue = $this.closest('div.card-body ul.values').scrollTop();
    }

    // PrefCenter My Designer and My Sizes event block
    var MyDesignerSelected = false;
    var MySizesSelected = false;
    var haveBothFiltersSelected = false;
    var haveBrandSelected = false;
    var haveSizeSelected = false;

    if (sessionStorage.MyDesignerSelected === 'true' && sessionStorage.MySizesSelected === 'true') {
        // If both My Designer and My Sizes selected together
        haveBothFiltersSelected = true;
    }
    if (sessionStorage.MyDesignerSelected === 'true') {
        haveBrandSelected = true;
    }
    if (sessionStorage.MySizesSelected === 'true') {
        haveSizeSelected = true;
    }

    const elemId = $this.attr('id');
    const isMyDesigners = elemId === 'MyDesigners-mobile-url';
    const isMySizes = elemId === 'MySizes-mobile-url';
    // Mobile block
    if (isMyDesigners || isMySizes) {
        if ($this.hasClass('selected')) {
            // When My Designer or My Sizes were clicked and applied - Remove the check mark and clear the search
            $this.removeClass('selected');
            if (haveBothFiltersSelected) {
                // If both were selected and now removing one
                if (isMyDesigners) {
                    // If Unselcting My Designer - Get the MySizesURl from Cookie ad make My DesignerSelected = False - So checkmark wil also be removed from ISML
                    url = cookieUtil.getCookie('myDesignersDeselectUrl');
                    MyDesignerSelected = false;
                    MySizesSelected = true;
                } else {
                    url = cookieUtil.getCookie('mySizesDeselectUrl');
                    MyDesignerSelected = true;
                    MySizesSelected = false;
                }
            } else {
                if (isMyDesigners) {
                    url = cookieUtil.getCookie('myDesignersDeselectUrl');
                    MyDesignerSelected = false;
                } else {
                    url = cookieUtil.getCookie('mySizesDeselectUrl');
                    MySizesSelected = false;
                }
            }
        } else {
            $this.addClass('selected');

            if ((haveBrandSelected && elemId === 'MySizes-mobile-url') || (haveSizeSelected && elemId === 'MyDesigners-mobile-url')) {
                MyDesignerSelected = true;
                MySizesSelected = true;
            } else if (elemId === 'MyDesigners-mobile-url') {
                MyDesignerSelected = true;
            } else if (elemId === 'MySizes-mobile-url') {
                MySizesSelected = true;
                localStorage.setItem('prefSize', 'check');
            }
        }
    } else if (isMyDesigners || isMySizes) {
        var currentL = $this.find('.pc-li');
        if (currentL.hasClass('fa-check-square')) {
            // When My Designer or My Sizes were clicked and applied - Remove the check mark and clear the search
            currentL.removeClass('fa-check-square');
            if (haveBothFiltersSelected) {
                // If both were selected and now removing one
                if (isMyDesigners) {
                    // If Unselecting My Designer - Get the MySizesURl from Cookie ad make My DesignerSelected = False - So checkmark wil also be removed from ISML
                    url = cookieUtil.getCookie('myDesignersDeselectUrl');
                    MyDesignerSelected = false;
                    MySizesSelected = true;
                } else {
                    url = cookieUtil.getCookie('mySizesDeselectUrl');
                    MyDesignerSelected = true;
                    MySizesSelected = false;
                }
            } else {
                if (isMyDesigners) {
                    url = cookieUtil.getCookie('myDesignersDeselectUrl');
                    MyDesignerSelected = false;
                } else {
                    url = cookieUtil.getCookie('mySizesDeselectUrl');
                    MySizesSelected = false;
                }
            }
        } else {
            // When clicked first time - add the checkmark and set PrefCenterSelected = true
            currentL.addClass('fa-check-square');
            if ((haveBrandSelected && elemId === 'MySizes-url') || (haveSizeSelected && elemId === 'MyDesigners-url')) {
                MyDesignerSelected = true;
                MySizesSelected = true;
            } else if (elemId === 'MyDesigners-url') {
                MyDesignerSelected = true;
            } else if (elemId === 'MySizes-url') {
                MySizesSelected = true;
            }
        }
    }

    if (elemId !== 'MyDesigners-url' && elemId !== 'MySizes-url' && elemId !== 'MyDesigners-mobile-url' && elemId != 'MySizes-mobile-url') {
        // Check My Designer or MySize was selected - And now selecting regular filter
        var myDesignersElemSelection = $('#MyDesigners-url').find('.pc-li');
        var myMySizesElemSelection = $('#MySizes-url').find('.pc-li');

        if (myDesignersElemSelection.hasClass('fa-check-square')) {
            // Desginder was already selected - So dont remove the checkmark
            if (haveBrandSelected) {
                MyDesignerSelected = true;
            }
        }
        if (myMySizesElemSelection.hasClass('fa-check-square')) {
            // Desginder was already selected - So dont remove the checkmark
            if (haveSizeSelected) {
                MySizesSelected = true;
            }
        }

        // Mobile
        var myDesignersMobElem = $('#MyDesigners-mobile-url');
        var myMySizesMobElem = $('#MySizes-mobile-url');
        if (myDesignersMobElem.hasClass('selected')) {
            if (haveBrandSelected) {
                MyDesignerSelected = true;
            }
        }
        if (myMySizesMobElem.hasClass('selected')) {
            if (haveSizeSelected) {
                MySizesSelected = true;
            }
        }
    }

    if (MySizesSelected) {
        sessionStorage.setItem('MySizesSelected', true);
    } else {
        sessionStorage.setItem('MySizesSelected', false);
    }

    if (MyDesignerSelected) {
        sessionStorage.setItem('MyDesignerSelected', true);
    } else {
        sessionStorage.setItem('MyDesignerSelected', false);
    }

    let $storeInput = $('#bopisCheck');
    let defaultStore = {
        ID: $storeInput.data('storeId'),
        name: $storeInput.data('storeName')
    };

    //Hide the PA banners at the bottom of the page in case the grid gets updated
    $('#catBannerBottom').addClass('d-none');
    $('#bottomPABanner').addClass('d-none');

    $.ajax({
        url: url,
        data: {
            page: $('.grid-footer').data('page-number'),
            selectedUrl: $(this).data('href'),
            type: type,
            filter: true,
            MyDesignerSelected: MyDesignerSelected,
            MySizesSelected: MySizesSelected,
            ajax: true
        },
        method: 'GET',
        success: function (response) {
            $('.category-refinement-bar').removeClass('slick-initialized');
            const result = parseResults(response, e, type, $this);
            if (result) {
              window.lazyload.lazyLoadImages();

              if (type === 'applied') {
                  if ($this.find('i').length) {
                      $this.find('i').remove();
                  } else {
                      $this.append('<i class="fa fa-check-square"></i>');
                  }
                  updateAppliedRefinement();
              }
              // Remove the brand parameter appended on the refinement url, this will impact SEO url
              // Also impacts the clearAll link being set wrongly on the page load. This param is intended to be used only on ajax
              if (url && url.indexOf('doNotReset=true') > -1) {
                  url = url.replace('&doNotReset=true', '').replace('doNotReset=true&', '').replace('?doNotReset=true', '').replace('doNotReset=true', ''); // eslint-disable-line
              }
              history.pushState({}, '', url);

              var writeOnlyArray = $('.grid-header').data('write-only-array');
              if (!writeOnlyArray) {
                  persistentWishlist.markProductArrWishlisted();
              }

              $('.card-header button').removeClass('clicked');
              // eslint-disable-next-line newline-per-chained-call
              $('.card-body').find('.selected').parents('.refinement').find('.card-header button').addClass('clicked');
              $('.view-more-less').text('View More');
              $('.refinement-category').removeClass('viewmore');
              // customscrollbar();
              viewmoreshow();
              prepActiveRefinementTrigger();
              commonSortWidthHandler();
              // Fill default store name
              if ($this.parent().hasClass('all-refine')) {
                  filterStoreRefinement.restoreDefaultStore(null, defaultStore);
              }
              // Append model images if it is enabled
                $('body')
                    .trigger('category:category:refinement:clone')
                    .trigger('category:set:model:view');
            }
            var $catBannerBottom = $('#catBannerBottom');
            var $chanelBannerResponse = $(response).find('.chanel-logo-banner');
            var $chanelLogo = $('.container-chanel-logo').find('.chanel-logo-banner');
            if($chanelBannerResponse.length && $chanelLogo.length === 0) {
                $('.container-chanel-logo').append($chanelBannerResponse);
                if ($catBannerBottom.length) {
                    $catBannerBottom.find('.cat-container').addClass('d-none');
                }
            } else if($chanelBannerResponse.length === 0 && $chanelLogo.length > 0) {
                $chanelLogo.remove();
                if ($catBannerBottom.length) {
                    $catBannerBottom.find('.cat-container').removeClass('d-none');
                }
            }

            $.spinner().stop();
            const filterValueLength = $('.filter-bar').find('ul li.filter-value').length;
            $('.filter-results-container span.filter-count').text(
              filterValueLength ? ' ' + '(' + filterValueLength + ')' + ' ' : ''
            );

            if (refinementWrapperScrollTop && !$(window).width() <= 1024) {
                window.setTimeout(() => {
                    refinementBar.scrollTop = refinementWrapperScrollTop;
                }, 10);
            } else {
                window.setTimeout(() => {
                    $('.refinement.active').find('div.card-body ul.values').scrollTop(scrollvalue);
                }, 10);
            }

            if (refinementBar && !refinementBar.contains(document.activeElement)) {
                $('.refinement-bar').find(':visible:focusable').first().focus();
            }
            window.hbcTooltip.tooltipInit();
            if (window.SitePreferences.criteoEnabled && window.SitePreferences.criteoGetSponsoredProducts) {
                criteo.getCriteoSponsoredProducts($(response).closest('[data-querystring]').data('querystring'));
            }
            //Show bottom array banners when there is no more lazy load
            if (!$('.show-more').length) {
                $('#catBannerBottom').removeClass('d-none');
                $('#bottomPABanner').removeClass('d-none');
            }
        },
        error: function () {
            window.hbcTooltip.tooltipInit();
            $.spinner().stop();
        }
    });
}

function refineSruleURL(url, $this) {
    const sortOption = $('[name=sort-order]').find('option:selected').data('id');
    const sruleAttrStr = 'srule=' + sortOption;
    let refinedUrl = '';
    var currentURL = window.location.href;
    var type = $this.data('type') || '';

    if (currentURL.indexOf('srule') > -1 && type != 'category') {
        var currentURLObj = new URL(currentURL);
        var sortingRule = currentURLObj.searchParams.get('srule');
        var issruleExist = url.indexOf('srule') > -1;
        if (url.indexOf('?') > -1 && !issruleExist) {
            url = url + '&srule=' + sortingRule;
        } else if (!issruleExist) {
            url = url + '?srule=' + sortingRule;
        }
        refinedUrl = url;
    } else if (!sortOption || url.indexOf(sruleAttrStr) > -1 || url.indexOf('?') === -1 || url.indexOf('srule') === -1) {
        // Keep original url
        refinedUrl = url;
    } else {
        // Replace old srule value with currently selected
        var urlParams = url.substring(url.indexOf('?'));
        var urlRoot = url.substring(0, url.indexOf('?'));

        var regex = new RegExp('srule[^&;#]*[^&;#]?');
        urlParams = urlParams.replace(regex, sruleAttrStr);

        refinedUrl = urlRoot + urlParams;
    }

    return refinedUrl;
}

base.applyFilter = function () {
    // Handle refinement value selection and reset click
    $('.container').on(
        'click',
        '.refinements li button, .refinement-bar button.reset, .filter-value button, .swatch-filter button, .clear-refinement button.reset, .store-refine input, .refinements li a, .all-refine input, .applied-filters-wrapper li button, .applied-filters-wrapper li a',
        function (e) {
            const $this = $(this);
            var elemId = $this.attr('id');
            if ($this.hasClass('my-preferences-array')) {
                return;
            }
            if (elemId !== 'tooltip-info-btn' && elemId !== 'tooltip-pc-plp-signin') {
                e.preventDefault();
                e.stopPropagation();
                $.spinner().start();

                var url = $this.attr('data-href') || $this.attr('href');

                if ($this.parent().hasClass('clear-all')) {
                  window.designerPrefSelectedCount = 0;
                  window.sizePrefSelectedCount = 0;

                  $('.refinement-bar-mob').removeClass('with-save-pref-btn');
                  $('.mobile-pc-info-cta-save.mobile-pc-designers').first().addClass('d-none');
                  $('.mobile-pc-info-cta-save.mobile-pc-sizes').first().addClass('d-none');
                }

                url = refineSruleURL(url, $this);

                // If the page has hide-designer class, then we are passing an addition query parameter to avoid setting the clear All link to brand filter.
                if (!$this.closest('.search-results').hasClass('hide-designer')) {
                    url += url && url.indexOf('?') > -1 ? '&doNotReset=true' : '?doNotReset=true';
                }

                if (window.matchMedia('(max-width: 1023px)').matches) {
                    window.lastFilter = $this.data('href') || $this.attr('href');
                }
                if (!$this.hasClass('bopis-refinement')) {
                    window.params = url.split('?')[1];
                }

                // Logic added for clearing of selected category from refinement
                var type = $this.data('type') || '';

                if (type === 'category' && $(window).width() >= BREAKPOINTS.tablet) {
                    // Remove the brand parameter appended on the refinement url, this will impact SEO url
                    // Also impacts the clearAll link being set wrongly on the page load. This param is intended to be used only on ajax
                    if (url && url.indexOf('doNotReset=true') > -1) {
                        url = url.replace('&doNotReset=true', '').replace('doNotReset=true&', '').replace('?doNotReset=true', '').replace('doNotReset=true', ''); // eslint-disable-line
                    }
                    window.location = url;
                } else {
                    if (type === 'category') {
                        window.filterType = true;
                    }
                    $this.trigger('search:filter', e);
                    updateGrid(url, type, e, $this);
                }
            }
        }
    );
    window.hbcTooltip.tooltipInit();
};

thumbWidth();

// Add an event to be able to control the display of the categories refinement when all sub-categories are going to be hidden.
// SFSX-1936
base.categoryRefinementToggle = function () {
    const $body = $('body');

    $body.on('category:showhide:refinement', function () {
        const hasChildElements = $body.find('.show-category-refinement').length > 0;
        if (!hasChildElements) {
            $('body').find('fieldset.refinement-category').toggleClass('d-none');
        }
    });
    $body.trigger('category:showhide:refinement');
};

base.appliedDesigner = function () {
    $(document).on('click', '#my-preference-plp-designers-mobile a', function (e) {
        e.preventDefault();
        $('.refinement-bar-mob').addClass('hide-refine');
        $('.my-designers-bar').removeClass('d-none');
    });
    $(document).on('click', '.my-designers-bar .filter-header button', function (e) {
        e.preventDefault();
        $('.refinement-bar-mob').removeClass('hide-refine');
        $('.my-designers-bar').addClass('d-none');
    });
};

base.appliedSize = function () {
    $(document).on('click', '#my-preference-plp-sizes-mobile a', function (e) {
        e.preventDefault();
        $('.refinement-bar-mob').addClass('hide-refine');
        $('.my-sizes-bar').removeClass('d-none');
    });
    $(document).on('click', '.my-sizes-bar .filter-header button', function (e) {
        e.preventDefault();
        $('.refinement-bar-mob').removeClass('hide-refine');
        $('.my-sizes-bar').addClass('d-none');
    });
};

base.appliedFilter = function () {
    const $body = $('body');

    $body.on('click', '.applied-refinement button', function () {
        $('.refinement-bar-mob').addClass('hide-refine');
        $('.applied-filters-bar').removeClass('d-none');
    });
    $body.on('click', '.applied-filters-bar .filter-header button', function () {
        $('.refinement-bar-mob').removeClass('hide-refine');
        $('.applied-filters-bar').addClass('d-none');
    });
    $body.on('click', '.applied-filters-bar .update-all-btn button', function () {
        $('.refinement-bar-mob').removeClass('hide-refine');
        $('.applied-filters-bar').addClass('d-none');
        $('.refinement-wrapper .shop-item').click();
        $('.applied-filters-wrapper .selected-filter').each(function () {
            $(this)
                .find('li')
                .each(function () {
                    if (!$(this).find('i').length) {
                        $(this).remove();
                    }
                });
            if (!$(this).find('li').length) {
                $(this).remove();
            }
        });
    });
    $body.on('click', '.applied-filters-bar .clear-all button', function () {
        window.location = $('.container .clear-refinement button.reset').data('href');
    });
};

base.sort = function () {
    // Code changes for adding sort parameter to url when moving out of the page for scroll persistence
    $('.container').on('change', '[name=sort-order]', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var isMobileSelect = !!this.closest('.sort-results-container');
        if (isMobileSelect) {
            var desktopSelect = $('.sort-selection-block select[name="sort-order"]');
            desktopSelect.val(this.value);
        }
        window.params = this.value.split('?')[1];
        $.spinner().start();
        $(this).trigger('search:sort', this.value);
        var $this = $(this);
        $.ajax({
            url: this.value,
            data: {
                selectedUrl: this.value
            },
            method: 'GET',
            success: function (response) {
                $('.product-grid').html(response);
                $('.card-header button').removeClass('clicked');
                $('.card-body').find('.selected').parents('.refinement').find('.card-header button').addClass('clicked');
                const $viewLessButton = $('.view-more-less');
              $viewLessButton.text($viewLessButton.data('viewmore'));
                $('.refinement-category').removeClass('viewmore');
                // customscrollbar();
                viewmoreshow();
                const refinementsHeight = $('.refinement-wrapper .refinements').height();
                if ($(window).width() < 1023 && refinementsHeight > $('.refinement-wrapper').height()) {
                    $('.collapsible-xl.refinement .card-body').height(refinementsHeight);
                }
                $('body').trigger('adobeTagManager:productArrayUpdate', $this);
                commonSortWidthHandler();
                $('body').trigger('category:set:model:view');
                window.lazyload.lazyLoadImages();
                productGridTeasers.addReviews();
                $.spinner().stop();
                if (window.SitePreferences.criteoEnabled && window.SitePreferences.criteoGetSponsoredProducts) {
                    let $parentContainer = $('.product-grid');
                    let data = JSON.parse($('#footercontent .criteo-banner-backup-data') && $('#footercontent .criteo-banner-backup-data').attr('data-banner'));
                    if ($('#footercontent .criteo-banner-backup-data').length > 0) {
                        criteo.renderBannersInSearchPage(data, $parentContainer);
                    }
                }

                var selectedSortOption = $this.find('option:selected').data('id');
                var urlObj = new URL(window.location.href);
                urlObj.searchParams.set('srule', selectedSortOption);
                history.pushState({}, '', urlObj);
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

base.sortWidthHandler = function () {
    commonSortWidthHandler();

    $('.sort-selection-block .select-sort-order').change(function () {
        var text = $(this).find('option:selected').text();
        var $aux = $('<select/>').append($('<option/>').text(text));
        $(this).after($aux);
        $(this).width($aux.width() + 10);
        $aux.remove();
    });
};

/**
 * Clears the designer search
 *
 * @param {Object} element - Current element
 */
function clearSearch(element) {
    var allRefValues = element.parent().siblings('ul').find('li');
    allRefValues.removeClass('d-none');
    $('.refinement-search-bar').siblings('.no-results').addClass('d-none');
}

base.filterBrands = function () {
    $('body').on('keyup', '.refinement-search .refinement-search-bar input', function () {
        let $this = $(this);
        var searchKeyWord = $this.val();
        if (searchKeyWord) {
            $this.siblings('button.search-button').addClass('d-none');
            $this.siblings('button.close-button').removeClass('d-none');
            var allRefValues = $this.parent().siblings('ul').find('li');
            var searchFound = false;
            $.each(allRefValues, function () {
                var html = $(this).find('span.value').html();
                if (html.trim().toLowerCase().indexOf(searchKeyWord.toLowerCase()) !== -1) {
                    searchFound = true;
                    $(this).removeClass('d-none');
                } else {
                    $(this).addClass('d-none');
                }
            });
            $this.parent().siblings('.no-results').toggleClass('d-none', searchFound);
        } else {
            $this.siblings('button.search-button').removeClass('d-none');
            $this.siblings('button.close-button').addClass('d-none');
            clearSearch($this);
        }
    });
};
base.designerPage = function () {
    // Designer index
    try {
        $('.all-designs a').each(function () {
            $(this).removeClass('selected');
            var querystring = $('.page').data('querystring');
            var categoryID = $(this).data('category');
            if (categoryID && querystring && querystring.indexOf(categoryID) > -1) {
                $(this).addClass('selected');
            }
        });
    } catch (e) {
        console.log('Could not flag selected category');
    }
};
function addCustomerPreferences(preferenceType, value) {
    var customerNo = document.getElementById('CustomerPreferencesCustomerNo').innerHTML;
    var updateCustomerPreferencesUrl = document.getElementById('updateCustomerPreferencesUrl').href;
    $.ajax({
        url: updateCustomerPreferencesUrl,
        type: 'post',
        dataType: 'json',
        data: {
            customerNo: customerNo,
            preferenceType: preferenceType,
            value: JSON.stringify(value),
            updatingFromPA: true,
            action: 'ADD'
        },
        success: function (data) { },
        error: function (err) { }
    });

    // Ajax call to get the latest My Deisgner and My Sizes URL after adding new data from PA
    // Add a check and remove disbale from "My Sizes and My Designers"
    var url = window.location.href;
    $.ajax({
        url: url,
        type: 'GET',
        data: {
            addToMyPef: true
        },
        success: function (data) {
            var myDesignersUrl = cookieUtil.getCookie('myDesignersUrl');
            var mySizesUrl = cookieUtil.getCookie('mySizesUrl');
            var allMyDesignerSelected = cookieUtil.getCookie('allMyDesignerSelected');
            var allMySizesSelected = cookieUtil.getCookie('allMySizesSelected');

            var myDesignersUrlA = document.getElementById('MyDesigners-url'); //or grab it by tagname etc
            myDesignersUrlA.href = myDesignersUrl;

            var myDesignersUrlMobileA = document.getElementById('MyDesigners-mobile-url'); //or grab it by tagname etc
            myDesignersUrlMobileA.href = myDesignersUrl;

            var mySizesUrlA = document.getElementById('MySizes-url'); //or grab it by tagname etc
            mySizesUrlA.href = mySizesUrl;

            var mySizesUrlMobileA = document.getElementById('MySizes-mobile-url'); //or grab it by tagname etc
            mySizesUrlMobileA.href = mySizesUrl;

            // Adding checkmark after adding "My Deisgners" and "My SIzes" from PA "Save to .."
            if (preferenceType === 'DesignerPreferences' && allMyDesignerSelected === 'true') {
                $('#MyDesigners-url')
                    .removeClass('disabled-pc-plp disabled')
                    .find('.pc-li').addClass('fa-check-square');

                // For Mobile Screen
                $('#MyDesigners-mobile-url')
                    .removeClass('disabled-pc-plp disabled')
                    .addClass('selected')
                    .find('u').text('Deselect');
                $('#my-preference-plp-designers-mobile a').removeClass('disabled-pc-plp disabled');
            } else if (preferenceType === 'SizePreferences' && allMySizesSelected === 'true') {
                $('#MySizes-url')
                    .removeClass('disabled-pc-plp disabled')
                    .find('.pc-li').addClass('fa-check-square');

                // For Mobile Screen
                $('#MySizes-mobile-url')
                    .removeClass('disabled-pc-plp disabled')
                    .addClass('selected')
                    .find('u').text('Deselect');
                $('#my-preference-plp-sizes-mobile a').removeClass('disabled-pc-plp disabled');
            }
        },
        error: function (err) { }
    });
}

function getPCId(element, preferenceType) {
    let pcId = '' + element.dataset.pcValue;

    if (preferenceType === 'SizePreferences') {
        pcId = window.categoryToAddInto + '#' + element.dataset.pcValue + '|' + pcId;
    }

    return pcId;
}

base.saveRefinementsToMyPreferences = function () {
    $('body').on('click', '.pc-info-cta-save:not(.sign-in)', function (event) {
        let value = [];
        const pcSection = event.currentTarget.closest(['li.pc-designers', 'li.pc-sizes']);
        const $pcSection = $(pcSection);
        const preferenceType = $pcSection.hasClass('pc-designers') ? 'DesignerPreferences' : 'SizePreferences';
        const selectedRefinements = $pcSection.siblings('li').find('span.selected');
        const saveCta = $pcSection.find('.pc-info-cta-save');
        const savedMsg = $pcSection.find('.pc-info-cta-saved');
        const saveCount = $pcSection.find('.pc-info-count');
        window.designerPreferences = window.designerPreferences || {};
        window.sizePreferences = window.sizePreferences || {};
        saveCount.innerHTML = '0 Selected';
        saveCta.fadeOut(1);
        savedMsg.css('display', 'flex');
        savedMsg.fadeIn(1);
        setTimeout(function () {
            savedMsg.fadeOut(400);
        }, 3000);
        selectedRefinements.each(function (i, el) {
            const pcId = getPCId(el, preferenceType);

            if (preferenceType === 'DesignerPreferences') {
                value.push(pcId);
                window.designerPreferences[el.dataset.pcValue] = el.dataset.pcValue;
            } else if (preferenceType === 'SizePreferences' && !~pcId.toLowerCase().indexOf('intimate')) {
                value.push(pcId);
                window.sizePreferences[window.categoryToAddInto] = window.sizePreferences[window.categoryToAddInto] || {};
                window.sizePreferences[window.categoryToAddInto][el.dataset.pcValue] = el.dataset.pcValue;
            }
        });
        window.designerPrefSelectedCount = 0;
        window.sizePrefSelectedCount = 0;
        addCustomerPreferences(preferenceType, value);
    });
};

base.mobileSaveRefinementsToMyPreferences = function () {
    $('body').on('click', '.mobile-pc-info-cta-save:not(.sign-in)', function (event) {
        let value = [];
        const preferenceType = $(event.currentTarget).hasClass('mobile-pc-designers') ? 'DesignerPreferences' : 'SizePreferences';
        const pcSection = preferenceType === 'DesignerPreferences' ? $('li.pc-designers') : $('li.pc-sizes');
        const selectedRefinements = $(pcSection).siblings('li').find('span.selected');
        const saveCta = $('.mobile-pc-info-cta-save');
        const savedMsg = $('.mobile-pc-info-cta-saved');
        window.designerPreferences = window.designerPreferences || {};
        window.sizePreferences = window.sizePreferences || {};
        saveCta.fadeOut(1, function () {
            saveCta.addClass('d-none');
            saveCta.css('display', '');
        });
        savedMsg.css('display', 'flex');
        savedMsg.fadeIn(1);
        setTimeout(function () {
            savedMsg.fadeOut(400);
        }, 3000);

        selectedRefinements.each(function (i, el) {
            var pcId = getPCId(el, preferenceType);
            if (preferenceType === 'DesignerPreferences') {
                value.push(pcId);
                window.designerPreferences[el.dataset.pcValue] = el.dataset.pcValue;
            } else if (preferenceType === 'SizePreferences' && !~pcId.toLowerCase().indexOf('intimate')) {
                value.push(pcId);
                window.sizePreferences[window.categoryToAddInto] = window.sizePreferences[window.categoryToAddInto] || {};
                window.sizePreferences[window.categoryToAddInto][el.dataset.pcValue] = el.dataset.pcValue;
            }
        });
        window.designerPrefSelectedCount = 0;
        window.sizePrefSelectedCount = 0;
        addCustomerPreferences(preferenceType, value);
    });
};

base.toolkitOpenedPageLoad = function () {
    $('#refinement-brand').on('click', function () {
        if (
            document.querySelector('.tooltip-info.tooltip-pc-brands-plp') &&
            (sessionStorage.PCBrandTooltipOpened === undefined || sessionStorage.PCBrandTooltipOpened === null)
        ) {
            document.querySelector('.tooltip-info.tooltip-pc-brands-plp').click();
            sessionStorage.setItem('PCBrandTooltipOpened', true);
        }
    });

    $('#refinement-sizeRefinement').on('click', function () {
        if (
            (sessionStorage.PCSizeTooltipOpened === undefined || sessionStorage.PCSizeTooltipOpened === null) &&
            document.querySelector('.tooltip-info.tooltip-pc-sizes-plp')
        ) {
            document.querySelector('.tooltip-info.tooltip-pc-sizes-plp').click();
            sessionStorage.setItem('PCSizeTooltipOpened', true);
        }
    });
};

base.mobileSaveToMyPreferencesButtonDisplay = function () {
    const $container = $('.container');
    const $refinementBarMob = $('.refinement-bar-mob');

    $container.on('click', 'button.filter-results', function () {
        const $mobileSaveToMyDesignersBtn = $('.mobile-pc-info-cta-save.mobile-pc-designers').first();
        const $mobileSaveToMySizesBtn = $('.mobile-pc-info-cta-save.mobile-pc-sizes').first();
        const $mobileSizeTab = $('.refinement-bar-mob .refinements .refinement-id-sizerefinement').first();
        const $mobileDesignerTab = $('.refinement-bar-mob .refinements .refinement-id-brand').first();

        if ($mobileDesignerTab.hasClass('active') && window.designerPrefSelectedCount) {
            $refinementBarMob.addClass('with-save-pref-btn');
            $mobileSaveToMySizesBtn.addClass('d-none');
            $mobileSaveToMyDesignersBtn.removeClass('d-none');
        } else if ($mobileSizeTab.hasClass('active') && window.sizePrefSelectedCount) {
            $refinementBarMob.addClass('with-save-pref-btn');
            $mobileSaveToMySizesBtn.removeClass('d-none');
            $mobileSaveToMyDesignersBtn.addClass('d-none');
        } else {
            $refinementBarMob.removeClass('with-save-pref-btn');
            $mobileSaveToMySizesBtn.addClass('d-none');
            $mobileSaveToMyDesignersBtn.addClass('d-none');
        }
    });

    $container.on(
        'click',
        '.refinement-bar-mob .refinements .refinement-id-brand li:not(.pc-designers) a, .refinement-bar-mob .refinements .refinement-id-sizerefinement li:not(.pc-sizes) button',
        function (event) {
            const $mobileSaveToMyDesignersBtn = $('.mobile-pc-info-cta-save.mobile-pc-designers').first();
            const $mobileSaveToMySizesBtn = $('.mobile-pc-info-cta-save.mobile-pc-sizes').first();
            const $mobileDesignerTab = $('.refinement-bar-mob .refinements .refinement-id-brand').first();
            const $mobileSizeTab = $('.refinement-bar-mob .refinements .refinement-id-sizerefinement').first();
            window.designerPreferences = window.designerPreferences || {};
            window.sizePreferences = window.sizePreferences || {};

            const preferenceType = $(this).closest('ul').attr('aria-label') === 'designer' ? 'DesignerPreferences' : 'SizePreferences';
            const pcId = getPCId(event.currentTarget, preferenceType);

            if ($(event.currentTarget).children('span.value').hasClass('selected')) {
                if ($mobileDesignerTab.hasClass('active') && !(event.currentTarget.dataset.pcValue in window.designerPreferences)) {
                    window.designerPrefSelectedCount--;
                } else if (
                    pcId &&
                    !~pcId.toLowerCase().indexOf('intimate') &&
                    $mobileSizeTab.hasClass('active') &&
                    (!window.sizePreferences[window.categoryToAddInto] ||
                        (window.sizePreferences[window.categoryToAddInto] && !(event.currentTarget.dataset.pcValue in window.sizePreferences[window.categoryToAddInto])))
                ) {
                    window.sizePrefSelectedCount--;
                }
            } else {
                if ($mobileDesignerTab.hasClass('active') && !(event.currentTarget.dataset.pcValue in window.designerPreferences)) {
                    window.designerPrefSelectedCount++;
                } else if (
                    pcId &&
                    !~pcId.toLowerCase().indexOf('intimate') &&
                    $mobileSizeTab.hasClass('active') &&
                    (!window.sizePreferences[window.categoryToAddInto] ||
                        (window.sizePreferences[window.categoryToAddInto] && !(event.currentTarget.dataset.pcValue in window.sizePreferences[window.categoryToAddInto])))
                ) {
                    window.sizePrefSelectedCount++;
                }
            }

            if (window.designerPrefSelectedCount && $mobileDesignerTab.hasClass('active')) {
                $refinementBarMob.addClass('with-save-pref-btn');
                $mobileSaveToMyDesignersBtn.removeClass('d-none');
            } else {
                $refinementBarMob.removeClass('with-save-pref-btn');
                $mobileSaveToMyDesignersBtn.addClass('d-none');
            }

            if (window.sizePrefSelectedCount && $mobileSizeTab.hasClass('active')) {
                $refinementBarMob.addClass('with-save-pref-btn');
                $mobileSaveToMySizesBtn.removeClass('d-none');
            } else {
                $refinementBarMob.removeClass('with-save-pref-btn');
                $mobileSaveToMySizesBtn.addClass('d-none');
            }
        }
    );

    $('body').on('click', '.refinement-bar-mob .refinements .refinement .card-header button', function (event) {
        const $mobileSaveToMyDesignersBtn = $('.mobile-pc-info-cta-save.mobile-pc-designers').first();
        const $mobileSaveToMySizesBtn = $('.mobile-pc-info-cta-save.mobile-pc-sizes').first();

        if (event.currentTarget.id === 'refinement-sizeRefinement' && window.sizePrefSelectedCount) {
            $refinementBarMob.addClass('with-save-pref-btn');
            $mobileSaveToMySizesBtn.removeClass('d-none');
            $mobileSaveToMyDesignersBtn.addClass('d-none');
        } else if (event.currentTarget.id === 'refinement-brand' && window.designerPrefSelectedCount) {
            $refinementBarMob.addClass('with-save-pref-btn');
            $mobileSaveToMySizesBtn.addClass('d-none');
            $mobileSaveToMyDesignersBtn.removeClass('d-none');
        } else {
            $refinementBarMob.removeClass('with-save-pref-btn');
            $mobileSaveToMySizesBtn.addClass('d-none');
            $mobileSaveToMyDesignersBtn.addClass('d-none');
        }
    });

    $('body').on('click', '#refinement-promotion-button', function (event) {
        const $priceRefinementButton = $(event.currentTarget);

        if (!$priceRefinementButton.hasClass('js-ajax-loaded')) {
            const $refinementContent = $('.refinement-wrapper').find('#refinement-promotion');
            $refinementContent.spinner().start();
            $.ajax({
                url: $priceRefinementButton.data('url'),
                type: 'get',
                dataType: 'html',
                success: function (template) {
                    $refinementContent.empty();
                    $refinementContent.spinner().stop();
                    $refinementContent.append(template);
                    $priceRefinementButton.addClass('js-ajax-loaded');
                },
                error: function () {
                    $refinementContent.spinner().stop();
                }
            });
        }
    });
};

function appendModalHtmlElement(data) {
    const $signInModal = $('#signInModal');
    const htmlString =
        `<!-- Modal -->
<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-body">
            <div class="cancel-icon">
                <button type="button" class="close svg-svg-22-cross svg-svg-22-cross-dims" data-dismiss="modal" aria-label="Close Modal Box"></button>
            </div>
            <div class="no-gutters modal-row align-items-start modal-contents">
                <div class="modal-column">${data.template}</div>
            </div>
        </div>
    </div>
</div>`;

  if ($signInModal) {
    $signInModal.empty().append(htmlString);
  }
}

function openLoginPopup(url) {
    $('body').spinner().start();
    $.ajax({
        url: url,
        dataType: 'json',
        success: function (data) {
            $('body').spinner().stop();
            if (data.redirect) {
                window.location.href = data.redirect;
            } else {
                appendModalHtmlElement(data);
                login.login();
                cookieUtil.setCookie('paurl', '', 1);
                cookieUtil.setCookie('paurl', window.location, 1);
                $('#signInModal').modal();
                setTimeout(function () {
                    window.formFields.adjustForAutofill();
                }, 300);
            }
        },
        error: function () {
            $('body').spinner().stop();
        }
    });
}

base.prefCenterInitSignIn = function () {
    $('body').on('click', '.tooltip-pc-plp-sign-in, .pc-info-cta-save.sign-in, .mobile-pc-info-cta-save.sign-in', function (event) {
        let prefsToSave = [];
        const preferenceType =
            $(event.currentTarget).hasClass('mobile-pc-designers') || $(event.currentTarget).hasClass('pc-info-cta-save-designers')
                ? 'DesignerPreferences'
                : 'SizePreferences';

        const pcSection = preferenceType === 'DesignerPreferences' ? $('li.pc-designers') : $('li.pc-sizes');
        const allRefinements = $(pcSection).siblings('li');
        const selectedRefinements = $(allRefinements).find('span.selected');

        selectedRefinements.each(function (i, el) {
            var pcId = getPCId(el, preferenceType);
            if (preferenceType === 'DesignerPreferences' || (preferenceType === 'SizePreferences' && !~pcId.toLowerCase().indexOf('intimate'))) {
                prefsToSave.push(pcId);
            }
        });
        const prefsToSaveString = btoa(JSON.stringify(prefsToSave));
        cookieUtil.setCookie('prefstosave', '', 1);
        cookieUtil.setCookie('prefstosave', prefsToSaveString, 1);
        if (window.FABuild) {
            window.location.href = $(this).data('appUrl');
        } else {
            openLoginPopup($(this).data('url'));
        }
    });

    $(document).ready(function () {
        const $noresultRecommendationsWrapper = $('.noresult-recommendations-wrapper');

        if ($noresultRecommendationsWrapper.length) {
            $.ajax({
                url: $noresultRecommendationsWrapper.data('url'),
                type: 'get',
                success: function (data) {
                    $noresultRecommendationsWrapper.html(data);
                }
            });
        }

        // For mobile web and app, we need to adjust positioning of the filter bar conditionally, so add classes here to target .product-search-results via CSS
        const categoryRefinementsMobile = $('.category-refinment-mobile').children().length;
        const headerTitle = $('h1.search-results-header').length;

        if (!categoryRefinementsMobile && !headerTitle) {
          $('#product-search-results').addClass('no-category-refinments-or-title');
        } else if (!categoryRefinementsMobile && headerTitle) {
          $('#product-search-results').addClass('no-category-refinments');
        } else if (categoryRefinementsMobile && !headerTitle) {
          $('#product-search-results').addClass('no-title');
        }
    });
};

function getItemsWithMultipleImages() {
    return document.querySelectorAll('.js-product-model-img');
}

// Image data is retrieved from the data-attribute "additional-images" located on the first image element.
function getAdditionalImageData($firstImage) {
  return $firstImage.data('additional-images');
}

function getElementImagesHTML(imageArray) {
  return imageArray.reduce((acc, image) => {
      const el = buildPictureTag(image);
      return acc + el;
    }, '');
}

function initiateSlickOntoItemsWithMultipleImages(itemsWithMultipleImages) {
    for (let i = 0; i < itemsWithMultipleImages.length; i++) {
        const item = itemsWithMultipleImages[i];
        const $item = $(item);

        if ($item.closest('.image-container').hasClass('prevent-slick')) {
            return false;
        }

        const $firstImage = $item.find('.tile-image:first');
        const firstImageEl = $firstImage.get(0);
        const additionalImageData = getAdditionalImageData($firstImage);

        // We are only creating two image nodes because we want to render two images only for performance. When the user interacts, it will load the rest of the images.
        const twoImageArray = additionalImageData.slice(1, 2);
        const twoImagesHTML = getElementImagesHTML(twoImageArray);
        firstImageEl.insertAdjacentHTML('afterend', twoImagesHTML);

        $item.find('img').each(function () {
            $item.attr('src', $item.attr('data-src'));
        });

        if (!/msie\s|trident\/|Edge\//i.test(navigator.userAgent) && $firstImage.attr('data-additional-images')) {
            $item.slick({
                infinite: true,
                swipeToSlide: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                autoplay: false,
                autoplaySpeed: 4500,
                speed: 1000,
                touchThreshold: 3
            });

            let xCoordStart;
            let yCoordStart;
            const xSlideTrigger = 10;
            const slickElements = $item.closest('.product-tile').find('.image-container a');
            const slickElement = slickElements[0];

            $(slickElement).on('touchstart', function (e) {
                xCoordStart = e.originalEvent.touches[0].clientX;
                yCoordStart = e.originalEvent.touches[0].clientY;
            });

            $(slickElement).on('touchend', function (e) {
                const xCoordEnd = e.originalEvent.changedTouches[0].clientX;
                const yCoordEnd = e.originalEvent.changedTouches[0].clientY;
                const deltaX = Math.abs(xCoordEnd - xCoordStart);
                const deltaY = Math.abs(yCoordEnd - yCoordStart);

                if (deltaX > deltaY) {
                    $(slickElement).slick('slickNext');
                } else if (xCoordStart < xCoordEnd + xSlideTrigger) {
                    $(slickElement).slick('slickPrev');
                }
            });
        }
    }
}

/* Gucci left nav in desktop to be not collapsed on page load */
$(document).ready(function () {
    if (window.FABuild) {
        const itemsWithMultipleImages = getItemsWithMultipleImages();
        initiateSlickOntoItemsWithMultipleImages(itemsWithMultipleImages);
    }

    if ($(window).width() > 1023) {
        $('.gucci-left-nav .nav-item .collapse').addClass('show');
        $('.gucci-left-nav .nav-item .cat-link').removeClass('collapsed');
    }
    // To prevent product have null price or null image displayed as blank tile in product array
    var productIdElements = document.getElementsByClassName('displayProductTile');
    for (let el of productIdElements) {
        document.querySelectorAll('.wishlist-prod-tile[data-id="' + el.dataset.pid + '"]')[0].classList.add('d-none');
    }
});

$(window).on('resize', function () {
    if ($(window).width() > 1023) {
        $('.gucci-left-nav .nav-item .collapse').addClass('show');
        $('.gucci-left-nav .nav-item .cat-link').removeClass('collapsed');
    } else {
        $('.gucci-left-nav .nav-item .collapse').removeClass('show');
        $('.gucci-left-nav .nav-item .cat-link').addClass('collapsed');
    }
});

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response) {
  const $tempDom = $('<div>').append($(response));
  const sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
  sortOptions.forEach(function (option) {
    $('option.' + option.id).val(option.url);
  });
}

function updateSortOptionsUpLzayLoad(response) {
    const $tempDom = $('<div>').append($(response));
    const sortOptions = $tempDom.find('.grid-header').data('sort-options').options;
    sortOptions.forEach(function (option) {
        $('option.' + option.id).val(option.url);
    });
}

$(window).scroll(function () {
    setTimeout(function () {
        if ($('.show-less button').length) {
            const $showLess = $('.show-less');
            const elementTop = $showLess.offset().top;
            const elementBottom = elementTop + $showLess.outerHeight();
            const viewportTop = $(window).scrollTop();
            const viewportBottom = viewportTop + $(window).height();
            if (
                ($(this).scrollTop() < currentPos && elementBottom > viewportTop && elementTop < viewportBottom) ||
                window.innerHeight + $(window).scrollTop() === $(document).height()
            ) {
                // eslint-disable-next-line no-undef
                if (isLoading === 0) {
                    isLoading = 1;
                    $('body .show-less button').click();
                }
            }
            currentPos = $(this).scrollTop();
        }
    }, 1500);
});

/**
 * Show a spinner inside a given element
 */
function addGridSpinner() {
  const $gridFooter = $('.grid-footer');
  const $placement = $gridFooter.eq($gridFooter.length - 1);
  $placement.append('<div class="spinner"><div class="dot1"></div><div class="dot2"></div></div>');
}

function addGridSpinnerUpLazyLoad() {
  const $placement = $('.grid-header').eq(0);
  $placement.append('<div class="spinner2"><div class="dot3"></div><div class="dot4"></div></div>');
}

/**
 * Remove existing spinner
 */
function removeGridSpinner() {
  const $gridFooter = $('.grid-footer');

  $gridFooter
    .eq($gridFooter.length - 1)
    .find('.spinner').remove();
}

function removeGridSpinnerUpLazyLoad() {
  const $gridHeader = $('.grid-header');

  $gridHeader
    .eq($gridHeader.length - 1)
    .find('.spinner2').remove();
}

base.showMore = function () {
  // Show more products
  $('.container').on('click', '.show-more button', function (e) {
    isLoading = 1;
    e.stopPropagation();
    var showMoreUrl = $(this).data('url');
    var data = {
      selectedUrl: showMoreUrl,
      showMore: true
    };
    e.preventDefault();
    addGridSpinner();
    $('body').css('overflow', 'auto');
    $(this).trigger('search:showMore', e);
    const $this = $(this);
    $.ajax({
      url: showMoreUrl,
      data: data,
      method: 'GET',
      success: function (response) {
        const $gridFooter = $('.grid-footer');
        $gridFooter
          .eq($gridFooter.length - 1)
          .replaceWith(response);
        // eslint-disable-next-line newline-per-chained-call
        updateSortOptions(response);
        window.lazyload.lazyLoadImages();
        // update review teasers
        productGridTeasers.addReviews();
        removeGridSpinner();

        //Show bottom array banners when there is no more lazy load
        if (!$('.show-more').length) {
            $('#catBannerBottom').removeClass('d-none');
            $('#bottomPABanner').removeClass('d-none');
            $('.criteo-banner.criteo-footer-banner').length && $('.criteo-banner.criteo-footer-banner').removeClass('d-none');
        }

        const writeOnlyArray = $('.grid-header').data('write-only-array');
        if (!writeOnlyArray) {
          persistentWishlist.markProductArrWishlisted();
        }

        isLoading = 0;
        $('body')
          .css('overflow', 'auto')
          .trigger('category:set:model:view')
          .trigger('adobeTagManager:productArrayUpdate', $this);

          if (window.SitePreferences.criteoEnabled &&
            window.SitePreferences.criteoGetSponsoredProducts && 
            $('body.search-page').length &&
            parseInt($('.grid-header').data('pagination-pagenumber')) === 1) {
            criteo = require('criteo/criteo/criteoProducts');
            //After organic result lazy load, render remaining criteo sponsored products
            criteo.renderProductsAndBannersInSearchPage(window.remainingSponsoredProductsToBeRendered, true)
        }
      },
      error: function () {
        removeGridSpinner();
        $('body').css('overflow', 'auto');
      }
    });
  });
};

base.showLess = function () {
    // Show less products
    $('.container').on('click', '.show-less button', function (e) {
        isLoading = 1;
        e.stopPropagation();
        const showLessUrl = $(this).data('url');
        const data = {
            selectedUrl: showLessUrl,
            showMore: true
        };
        e.preventDefault();
        // $.spinner().start();
        addGridSpinnerUpLazyLoad();
        $('body').css('overflow', 'auto');
        const $this = $(this);
        $this.trigger('search:showLess', e);
        $.ajax({
            url: showLessUrl,
            data: data,
            method: 'GET',
            success: function (response) {
                $this.closest('.grid-header').remove();
                $('.product-grid').prepend(response);
                // eslint-disable-next-line newline-per-chained-call
                updateSortOptionsUpLzayLoad(response);
                window.lazyload.lazyLoadImages();
                // Update review teasers
                productGridTeasers.addReviews();
                // $.spinner().stop();
                removeGridSpinnerUpLazyLoad();
                isLoading = 0;
                $('body')
                    .css('overflow', 'auto')
                    .trigger('category:set:model:view')
                    .trigger('adobeTagManager:productArrayUpdate', $this);
                $this.closest('.grid-header').remove();
                const $showLess = $('.show-less');
                const elementTop = $showLess.offset().top;
                const elementBottom = elementTop + $showLess.outerHeight();
                const viewportTop = $(window).scrollTop();
                if (elementBottom > viewportTop) {
                    // eslint-disable-next-line no-undef
                    if (isLoading === 0) {
                        isLoading = 1;
                        $('body .show-less button').click();
                    }
                }
            },
            error: function () {
                // $.spinner().stop();
                removeGridSpinnerUpLazyLoad();
                $('body').css('overflow', 'auto');
            }
        });
    });
};

/**
 * Function to close the refinement button
 */
function closeButton() {
  const $refinementBar = $('.refinement-bar');
  $refinementBar.removeClass('d-block');
  $refinementBar.siblings().attr('aria-hidden', false);
  $refinementBar.closest('.row').siblings().attr('aria-hidden', false);
  $refinementBar.closest('.tab-pane.active').siblings().attr('aria-hidden', false);
  $refinementBar.closest('.container.search-results').siblings().attr('aria-hidden', false);
  $('.btn.filter-results').focus();
  $('body').removeClass('fixed filters-visible modal-open');
}

base.closeRefinementModal = function () {
    $('.refinement-bar').on('click', '.header-bar button.button-close', function (e) {
        const url = $('.product-refinement-no-search').data('href');
        if (!url) {
            closeButton();
            return;
        }
        window.params = url.split('?')[1];
        if (window.matchMedia('(max-width: 1023px)').matches) {
            window.filterType = '';
            e.preventDefault();
            if (!!window.lastFilter && url !== window.lastFilter) {
                $.spinner().start();
                $.ajax({
                    url: url,
                    data: {
                        page: $('.grid-footer').data('page-number'),
                        selectedUrl: url
                    },
                    method: 'GET',
                    success: function (response) {
                        let result = parseResults(response, e);
                        history.pushState({}, '', url);
                        if (result) {
                            $('.card-header button').removeClass('clicked');
                            $('.card-body').find('.selected').parents('.refinement').find('.card-header button').addClass('clicked');
                            $('.view-more-less').text('View More');
                            $('.refinement-category').removeClass('viewmore');
                            // customscrollbar();
                            viewmoreshow();
                            const $refinements = $('.refinement-wrapper .refinements');
                            if ($(window).width() < 1023 && $refinements.height() > $('.refinement-wrapper').height()) {
                                $('.collapsible-xl.refinement .card-body').height($refinements.height());
                            }
                            closeButton();
                        }
                        $.spinner().stop();

                        window.lazyload.lazyLoadImages();
                    },
                    error: function () {
                        $.spinner().stop();

                        window.lazyload.lazyLoadImages();
                        closeButton();
                    }
                });
            } else {
                closeButton();
            }
        } else {
            closeButton();
        }
    });
};

base.updatePreferenceRefinement = function () {
    $('#refinement-brand, #refinement-sizeRefinement').on('click', function () {
      const hasLoadedPreferencesMarkup = $('.preference-scripts').children().length > 0;
      if (!hasLoadedPreferencesMarkup) {
        updatePreferenceRefinement();
      }
    });
};

base.updateTileImageURL = function () {
    $('.search-result-wrapper').on('mousedown touchstart', '.is-bopis a.thumb-link', function() {
        const imageURL = $(this).attr('href') || '';

        if (imageURL.length && !imageURL.includes('bopis')) {
          const isBopisParameter = imageURL.indexOf('?') === -1 ? '?bopis=true' : '&bopis=true';
          $(this).attr('href', imageURL + isBopisParameter);
        }
    });
}

// if we go back to a "next pagination" of products, scroll the product into view
function scrollToLastViewed() {
  const url = new URL(document.location);
  const isNPage = Number(url.searchParams.get('start')) > 0;
  const itemID = cookieUtil.getCookie('itemID');
  const element = $('div[data-tile-pid=' + itemID + ']')[0];
  let intersectionObserver = null;

  if (!isNPage) return Promise.resolve();

  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
  }

  const scrollIntoViewHandler = () => {
    if (document.readyState === 'complete') {
      element.scrollIntoView({ behavior: 'smooth' });
      document.removeEventListener('readystatechange', scrollIntoViewHandler);
    }
  }

  return new Promise(function(res) {
    if (!element) res();

    intersectionObserver = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        // mitigate scroll 'ease-in'
        setTimeout(() => {
          res();
        }, 200);
      }
      // fallback resolve in weird situations, like no-scroll
      setTimeout(() => {
        res();
      }, 500);
    });

    // start observing to capture scroll 'end'
    intersectionObserver.observe(element);

    document.addEventListener('readystatechange', scrollIntoViewHandler);
  }).then(() => {
    if (element) {
      intersectionObserver.unobserve(element);
    }
  });
}

/**
 * updates the store info on select of store from the modal.
 */
base.selectStoreFromSearch = function () {
  $('body').on('store:selectedSearch', function (e, data) {
    $.spinner().start();
    updateStoreContent(data); // if event invoke is from modal, updates store html
    removeSelectStoreModal(); // close modal on successful update
    $.spinner().stop();
  });
};

module.exports = base;
