'use strict';

// eslint-disable-next-line require-jsdoc
function addWishlistedClass(tile) {
  $('.product-tile', tile).find('.image-container').addClass('wishlist_checked');
  $('.wishlistTile', tile).find('span').addClass('svg-svg-96-heart-gray svg-svg-96-heart-gray-dims');
  $('.wishlistTile', tile).removeClass('select-wishlist').addClass('deselect-wishlist');
}

// eslint-disable-next-line require-jsdoc
function removeWishlistedClass(tile) {
  $('.product-tile', tile).find('.image-container').removeClass('wishlist_checked').addClass('select-wishlist');
  $('.wishlistTile', tile).find('span').removeClass('svg-svg-96-heart-gray svg-svg-96-heart-gray-dims');
  $('.wishlistTile', tile).find('span').addClass('svg-svg-105-heart-o-22-19-dims svg-svg-105-heart-o-22-19');
  $('.wishlistTile', tile).removeClass('deselect-wishlist').addClass('select-wishlist');
  $('.wishlistTile', tile).find('span').show();
  $('.customer-content', tile).addClass('d-none');
  $('.image-container', tile).removeClass('wishlist_checked');
}

function updateWishlistContent($productContainer, response){
  // if qv
  if ($productContainer.hasClass('product-quickview') || $productContainer.hasClass('product-detail')) {
    $('.wishlistTile', $productContainer).removeClass('pdpdeselect-wishlist').addClass('pdpselect-wishlist');
    // saks only change
    if ($('.wishlist-pdp-button', $productContainer).find('span.button-text').length > 0) {
      $('.wishlist-pdp-button', $productContainer).find('span.button-text').html(response.wishListMsg);
    }

    // saks only change
    if ($('.wishlist-pdp-button').length > 0) {
      $('.wishlist-pdp-button').removeClass('pdp-wishlist-clicked');
    }

    if (response.wishlisted) {
      $('.wishlistTile', $productContainer).removeClass('pdpselect-wishlist').addClass('pdpdeselect-wishlist');
      // saks only change
      if ($('.wishlist-pdp-button', $productContainer).find('span.button-text').length > 0) {
        $('.wishlist-pdp-button', $productContainer).removeClass('pdpselect-wishlist').addClass('pdpdeselect-wishlist');
        $('.wishlist-pdp-button', $productContainer).find('span.button-text').html(response.wishListedMsg);
      }
    } else {
      // saks only change
      if ($('.wishlist-pdp-button', $productContainer).find('span.button-text').length > 0) {
        $('.wishlist-pdp-button', $productContainer).removeClass('pdpdeselect-wishlist').addClass('pdpselect-wishlist');
      }
    }
  } else if ($productContainer.hasClass('product-tile') || $productContainer.hasClass('product')) {
    removeWishlistedClass($productContainer);
    if (response.wishlisted) {
      addWishlistedClass($productContainer);
    }
  }
}

/**
 * cache wishlist ids in window
 *
 * @param {Object[]} products - wishlist structs
 */
function storeWishlistInWindow(products) {
  window.wishlistIds = products.map(each => each.id);
}

module.exports = {
  updateWishlistContent: updateWishlistContent,
  markProductArrWishlisted: function () {
    var wishlistURL = $('body').data('wishlisturl');
    var applePayButtonDiv = $('.pdp-apple-pay-button');

    if (window.SitePreferences &&
      window.SitePreferences.applePayPDPToggle &&
      window.dw &&
      window.dw.applepay &&
      window.ApplePaySession &&
      window.ApplePaySession.canMakePayments() &&
      applePayButtonDiv.length) {
      wishlistURL += '?isApplePay=true';
    }
    if (wishlistURL) {
      $.ajax({
        url: wishlistURL,
        success: function (response) {
          if (response) {
            storeWishlistInWindow(response.wishlistProducts);
            response.wishlistProducts.forEach(function (product) {
              var tile = $('div[data-tile-pid=' + product.id + ']');
              if (tile.length > 0) {
                addWishlistedClass(tile);
              }
              var productDetail = $('.product-detail');
              productDetail.each(function () {
                var item = $(this);
                if (item.length > 0 && item.data('pid') == product.id) {
                  // eslint-disable-line
                  $('.wishlistTile', item.find('.primary-images-container'))
                    .removeClass('pdpdeselect-wishlist')
                    .removeClass('pdpselect-wishlist')
                    .addClass('pdpdeselect-wishlist');
                  // saks only change
                  if ($('.wishlist-pdp-button', item).find('span.button-text').length > 0 && !item.hasClass('product-set-detail')) {
                    $('.wishlist-pdp-button', item).removeClass('pdpdeselect-wishlist').removeClass('pdpselect-wishlist').addClass('pdpdeselect-wishlist');
                    $('.wishlist-pdp-button', item).find('span.button-text').html(response.wishListedMsg);
                  }
                }
              });
            });
          }
        },
        error: function (err) {
          // eslint-disable-line
          // do nothing
        }
      });
    }
  },
  makrSingleProductWishlisted: function (pid, $productContainer) {
    var singlewishlisturl = $('body').data('singlewishlisturl');
    if (pid && singlewishlisturl && $productContainer) {
      singlewishlisturl += '?pid=' + pid;
      var applePayButtonDiv = $('.pdp-apple-pay-button');
      if (window.SitePreferences &&
        window.SitePreferences.applePayPDPToggle &&
        window.dw &&
        window.dw.applepay &&
        window.ApplePaySession &&
        window.ApplePaySession.canMakePayments() &&
        applePayButtonDiv.length) {
          singlewishlisturl += '&isApplePay=true';
      }
      $.ajax({
        url: singlewishlisturl,
        success: function (response) {
          updateWishlistContent($productContainer, response);
        },
        error: function (err) {
          // eslint-disable-line
          // do nothing
        }
      });
    }
  }
};
